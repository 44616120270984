const styles = (theme) => ({
    root: {
        height: 150,
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 30,
        fontWeight: 400,
    },

    item: {
        margin: '0 0 15px',
    },
});

export default styles;
