import jsPDF from 'jspdf';
import { formatDate, titleCase } from 'utils/string-utils';

const ExportPDF = (eventGuests, eventDetails) => {
    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
    let fontSize = 12;
    let fontFamily = 'helvetica';

    doc.setFontSize(fontSize);
    doc.setProperties({
        title: 'Title',
        subject: 'This is the subject',
        author: 'James Hall',
        keywords: 'generated, javascript, web 2.0, ajax',
        creator: 'MEEE',
    });
    // Event Name
    doc.setFont(fontFamily, 'bold').text('Event Name:', 10, 10);
    doc.setFont(fontFamily, 'normal').text(eventDetails.title, 40, 10);

    // Event Date
    doc.setFont(fontFamily, 'bold').text('Event Date:', 10, 18);
    doc.setFont(fontFamily, 'normal').text(formatDate(eventDetails.startDate, 'DD/MM/YYYY'), 40, 18);

    // Start Time
    doc.setFont(fontFamily, 'bold').text('Start Time', 10, 26);
    doc.setFont(fontFamily, 'normal').text(formatDate(eventDetails.startDate, 'h:mm A'), 40, 26);

    // End Time
    doc.setFont(fontFamily, 'bold').text('End Time', 10, 34);
    doc.setFont(fontFamily, 'normal').text(formatDate(eventDetails.endDate, 'h:mm A'), 40, 34);

    var table = [];
    const rsvpMap = {
        invited: 0,
        no: 1,
        yes: 2,
    };

    const sorted = eventGuests.sort(compareFunc);
    sorted.forEach(addToTable);

    // Adding ID to entire datatable Array
    table.forEach((item, index) => {
        item.id = (index + 1).toString();
    });

    function addToTable(attendee) {
        var attendeeTableRow = {};
        attendeeTableRow.name = (attendee.User.firstName ?? '') + ' ' + (attendee.User.lastName ?? '');
        attendeeTableRow.invitedBy = 'self';
        attendeeTableRow.dob =
            attendee.User.birthDate === null ? 'N/A' : formatDate(attendee.User.birthDate, 'MM/DD/YYYY');
        attendeeTableRow.phone = attendee.User.phone ?? 'N/A';
        attendeeTableRow.rsvp = attendee.rsvp === 'invited' ? 'Pending' : titleCase(attendee.rsvp);
        attendeeTableRow.checkinDate =
            attendee.checkinDate !== null ? formatDate(attendee.checkinDate, 'MM/DD h:mm A') : ' ';
        attendeeTableRow.checkoutDate =
            attendee.checkoutDate !== null ? formatDate(attendee.checkoutDate, 'MM/DD h:mm A') : ' ';
        table.push(Object.assign({}, attendeeTableRow));

        const sorted = attendee.guests.sort(compareFunc);
        sorted.forEach(addGuestsToTable);

        function addGuestsToTable(guest) {
            var guestTableRow = {};
            guestTableRow.name = guest.name ?? '';
            guestTableRow.invitedBy = (attendee.User.firstName ?? '') + ' ' + (attendee.User.lastName ?? '');
            guestTableRow.dob = guest.birthdate !== null ? formatDate(guest.User.birthDate, 'MM/DD/YYYY') : 'N/A';
            guestTableRow.phone = guest.User.phone ?? 'N/A';
            guestTableRow.rsvp = guest.rsvp === 'invited' ? 'Pending' : titleCase(attendee.rsvp);
            guestTableRow.checkinDate = guest.checkinDate !== null ? formatDate(guest.checkinDate, 'MM/DD h:mm A') : '';
            guestTableRow.checkoutDate =
                guest.checkoutDate !== null ? formatDate(guest.checkoutDate, 'MM/DD h:mm A') : '';
            table.push(Object.assign({}, guestTableRow));
        }
    }

    function compareFunc(a, b) {
        if (rsvpMap[a.rsvp] < rsvpMap[b.rsvp]) return 1;
        if (rsvpMap[a.rsvp] > rsvpMap[b.rsvp]) return -1;
        if (rsvpMap[a.rsvp] === rsvpMap[b.rsvp]) {
            if (new Date(a.checkinDate) > new Date(b.checkinDate)) return 1;
        }
        return 0;
    }

    function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
            result.push({
                name: keys[i].id,
                prompt: keys[i].prompt,
                width: keys[i]?.width ?? 53,
                padding: 0,
            });
        }
        return result;
    }

    var headers = createHeaders([
        { id: 'id', prompt: '#', width: 20 },
        { id: 'name', prompt: 'Name' },
        { id: 'invitedBy', prompt: 'Guest of' },
        { id: 'dob', prompt: 'DOB' },
        { id: 'phone', prompt: 'Phone Number' },
        { id: 'rsvp', prompt: 'RSVP', width: 40 },
        { id: 'checkinDate', prompt: 'Checkin Time' },
        { id: 'checkoutDate', prompt: 'Checkout Time' },
    ]);
    doc.table(10, 40, table, headers, {});
    doc.output('dataurlnewwindow');
    doc.save('Guest-List.pdf');
};

export default ExportPDF;
