import React, { useEffect } from 'react';

// Modules
import checkError from 'utils/check-error';
import Mixpanel from 'mixpanel-browser';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { get as getAlerts } from 'redux/actions/safetyAlerts';
import { setSnackbar } from 'redux/actions/snackbar';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';
import Alert from 'components/Alert';

// styles
import styles from './styles';

const SafetyAlerts = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { safetyAlerts } = useSelector(({ safetyAlerts }) => ({
        safetyAlerts,
    }));

    useEffect(() => {
        async function _getAlerts() {
            try {
                await dispatch(getAlerts());
                Mixpanel.track('Safety alerts viewed');
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }
        _getAlerts();
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <PageHeader title={'Safety Alerts'} />
            <MaxWidthContainer padding>
                <Button
                    onClick={() => props.history.push('/safety-alerts/create')}
                    variantType="containedOuterSpace"
                    fullWidth={true}
                    text="Create Safety Alert"
                    startIcon={<ErrorOutline />}
                />
                {safetyAlerts.data.map((alert) => (
                    <Alert
                        alert={alert}
                        key={alert.id}
                        onClick={() =>
                            props.history.push({
                                pathname: `/safety-alerts/${alert.id}`,
                                state: {
                                    ...alert,
                                },
                            })
                        }
                    />
                ))}
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(SafetyAlerts);
