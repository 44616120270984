const styles = (theme) => ({
    root: {},
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.4,
    },
    left: {
        justifyContent: 'flex-start',
    },
    right: {
        justifyContent: 'flex-end',
    },
    startIcon: {
        left: 0,
        position: 'relative',
    },
    home: {
        padding: 10,
        borderRadius: '50%',
        border: '1px solid rgba(0,0,0,0.4)',
    },
    progress: {
        height: 1,
        overflow: 'visible',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    progressBar: {
        height: 5,
        top: -2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        backgroundColor: '#89d924',
    },
    text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
});

export default styles;
