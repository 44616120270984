const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    list: {
        padding: 20,
    },
    listItem: {
        border: '1px solid #979797',
        borderRadius: 6,
        padding: 20,
        marginBottom: 10,
    },
    icon: {
        fontSize: 16,
    },
    title: {
        color: '#4a4a4a',
        fontSize: 16,
    },
});

export default styles;
