const styles = (theme) => ({
    root: {
        height: 24,
        color: '#6e6e6e',
        fontSize: 18,
        fontWeight: 500,
    },
});

export default styles;
