import React, { useState } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
// Material UI Core
import { withStyles } from '@material-ui/core/styles';
import { ExitToApp, People, ExpandMore, ExpandLess, HelpOutline } from '@material-ui/icons';
import {
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    Divider,
} from '@material-ui/core';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkPolicy from 'utils/check-policy';
import { policyIdentifiers } from 'global-constants';
// Images
import MenuItems from './routes';
// styles
import styles from './styles';

const Drawer = ({
    classes,
    open,
    onClose,
    onOpen,
    onOrganizationClicked,
    activeOrganization,
    onLogout,
    user,
    ...props
}) => {
    const [orgsOpened, setOrgsOpened] = useState(false);
    function handleMenuItemClicked(path) {
        Mixpanel.track(`Menu Drawer item clicked ${path}`);
        props.history.push(path);
        onClose();
    }

    return (
        <SwipeableDrawer anchor={'right'} open={open} onClose={() => onClose()} onOpen={() => onOpen()}>
            <div className={classes.drawer} role="presentation" onKeyDown={() => onClose()}>
                <div className={classes.heading}>
                    <Typography className={classes.headingTitle}>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography className={classes.headingSubtitle}>{user?.email?.value}</Typography>
                    <Typography className={classes.headingSubtitle}>{activeOrganization?.name}</Typography>
                </div>
                <List classes={{ root: classes.list }}>
                    {/* START ORGS */}
                    <ListItem classes={{ root: classes.listItem }} button onClick={() => setOrgsOpened(!orgsOpened)}>
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary="Organizations" />
                        {orgsOpened ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={orgsOpened} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            {user.Organizations.map((organization) => {
                                const textClass = classNames({
                                    [classes.orgName]: true,
                                    [classes.orgActive]: activeOrganization.id === organization.id,
                                });

                                return checkPolicy({
                                    policies: user.policies,
                                    policy: policyIdentifiers.MEMBER_APP_LOGIN,
                                    ParentId: organization.ParentId,
                                    OrganizationId: organization.id,
                                }) ? (
                                    <ListItem
                                        key={`organization-${organization.id}`}
                                        button
                                        classes={{ root: classes.dropdownListItem }}
                                        onClick={() => onOrganizationClicked(organization)}>
                                        <ListItemIcon classes={{ root: classes.listIcon }}></ListItemIcon>
                                        <ListItemText classes={{ primary: textClass }} primary={organization.name} />
                                    </ListItem>
                                ) : null;
                            })}
                        </List>
                    </Collapse>
                    <Divider className={classes.divider} />
                    {/* END ORGS */}
                    {MenuItems.map(({ title, path, policy, Icon }, index) =>
                        checkPolicy({
                            policies: user.policies,
                            policy,
                            ParentId: user.Organization.ParentId,
                            OrganizationId: user.Organization.id,
                        }) ? (
                            <ListItem
                                classes={{ root: classes.listItem }}
                                button
                                onClick={() => handleMenuItemClicked(path)}
                                key={`item-${index}`}>
                                <ListItemIcon classes={{ root: classes.listIcon }}>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText primary={title} />
                            </ListItem>
                        ) : null
                    )}
                    <Divider className={classes.divider} />
                    <ListItem
                        button
                        component="a"
                        classes={{ root: classes.listItem }}
                        href="mailto:support@clutch-partners.com">
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                            <HelpOutline />
                        </ListItemIcon>
                        <ListItemText primary={'Support'} />
                    </ListItem>
                    <ListItem classes={{ root: classes.listItem }} button onClick={() => onLogout()}>
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary={'Log out'} />
                    </ListItem>
                </List>
            </div>
        </SwipeableDrawer>
    );
};

export default withRouter(withStyles(styles)(Drawer));
