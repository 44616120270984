import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// Components
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import style from './styles';

const SkeletonInfoPage = ({ classes }) => {
    return (
        <div className={classes.root}>
            <Skeleton animation="wave" height={153} width={173} variant="rect" className={classes.center} />
            <div className={classes.margin} />
            <Skeleton animation="wave" height={20} className={classNames(classes.center, classes.threeFourthsWidth)} />
            <div className={classes.pagination}>
                <Skeleton animation="wave" height={20} className={classNames(classes.center, classes.halfWidth)} />
                <Skeleton animation="wave" height={20} className={classNames(classes.center, classes.halfWidth)} />
            </div>
        </div>
    );
};

export default withStyles(style)(SkeletonInfoPage);
