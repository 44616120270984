const styles = (theme) => ({
    skeleton: {
        marginTop: 20,
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    menuSquare1: {
        margin: '0px 0px 15px 0px',
        flexBasis: '49%',
    },
    menuSquare2: {
        margin: '0px 0px 15px 0px',
        flexBasis: '49%',
    },
});

export default styles;
