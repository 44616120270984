import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import style from './styles';

const SkeletonInfoPage = ({ classes }) => {
    return (
        <div>
            <Skeleton animation="wave" height={150} variant="rect" className={classes.margin} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} className={classes.halfWidth} />
            <div className={classes.margin} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} className={classes.halfWidth} />
            <div className={classes.margin} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} className={classes.halfWidth} />
        </div>
    );
};

export default withStyles(style)(SkeletonInfoPage);
