// React
import React, { useState, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
// Mui-Core
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { get as getEventInfo } from 'redux/actions/eventInfo';

// Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';

// Styles
import styles from './styles';

const ChangeEventInfo = ({ classes, match, ...props }) => {
    const dispatch = useDispatch();

    const { cognitoUser, eventInfo } = useSelector(({ cognitoUser, eventInfo }) => ({
        cognitoUser,
        eventInfo,
    }));

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getEvent() {
            mounted && setLoading(true);
            try {
                await dispatch(getEventInfo(match.params.id));
                mounted && setLoading(false);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.push('/');
            }
        }
        _getEvent();
    }, [dispatch, match.params.id, props.history]);

    const [loading, setLoading] = useState(false);
    const [organizationId, setOrganizationId] = useState('');
    const [parentId, setParentId] = useState('');
    const editAllowed = (eventInfo?.event?.information?.startDate ?? 0) > Date.now();

    useEffect(() => {
        if (!loading && cognitoUser) {
            setOrganizationId(cognitoUser.Organization.id);
            setParentId(cognitoUser.Organization.ParentId);
        }
    }, [cognitoUser, loading]);

    async function resubmitEvent() {
        setLoading(true);

        try {
            await API.patch(
                'ClutchAPI',
                `/events/${props.match.params.id}/status/editing?OrganizationId=${organizationId}&ParentId=${parentId}`
            );
            Mixpanel.track('Event resubmitted for editing');
            dispatch(setSnackbar(`Event successfully resubmitted.`));
            props.history.replace(`/event/edit/${props.match.params.id}`);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
            setLoading(false);
        }
    }

    return editAllowed ? (
        <div className={classes.root}>
            <PageHeader title={'Change Event Information'} />

            <MaxWidthContainer padding classes={{ padding: classes.container }}>
                <Typography>
                    If you need to change the information for your event, it will need to go through the approval
                    process again. By clicking the button below your event will become unapproved, so you may update
                    information and then resubmit the event for approval. While the event is unapproved, guests who have
                    been invited will not be able to RSVP.
                </Typography>

                <Button
                    fullWidth
                    text={'Change Event and Resubmit'}
                    variant="contained"
                    variantType={'containedOuterSpace'}
                    onClick={resubmitEvent}
                />
            </MaxWidthContainer>
        </div>
    ) : (
        <div className={classes.root}>
            <MaxWidthContainer padding classes={{ padding: classes.container }}>
                <Typography
                    style={{
                        textAlign: 'center',
                    }}>
                    Unable to edit an event that has already occured.
                </Typography>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(ChangeEventInfo);
