import React from 'react';

// Modules
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import Upload from 'utils/Upload';
import moment from 'moment';
import Mixpanel from 'mixpanel-browser';
import FormValidator from 'utils/FormValidator';
import validator from 'utils/validator';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';

// Components
import BottomButtons from 'components/BottomButtons';
import DateInput from 'components/Input/Date';
import FileInput from 'components/Input/FileInput';
import Input from 'components/Input';
import Select from 'components/Input/Select';
import PageHeader from 'components/PageHeader';
import TimeInput from 'components/Input/Time';

// Constants
import { TIMEZONE_MAP } from 'global-constants';

// Styles
import styles from './styles';

import FORM_INIT from './init';

class BasicInformation extends React.Component {
    INIT = FORM_INIT;

    constructor(props) {
        super(props);

        const state = cloneDeep(this.INIT);

        // Create mindate at beginning of day because we have to compare date and time field separately
        this.minDate = moment().add(1, 'day').hour(0).minute(0).second(0).millisecond(0);
        if (!props.event.EventType?.bypassApproval) this.minDate.add(props.organization.daysBeforeEventCreate, 'day');

        state.start.value = this.minDate;
        state.end.value = this.minDate;

        this.state = {
            ...state,
            submitting: false,
        };
    }

    componentDidMount() {
        if (!this.props.event.EventType) this.props.history.push('/event/type');
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleSubmit(e) {
        e.preventDefault();

        const { cognitoUser, event, setSnackbar } = this.props;
        const {
            title,
            theme,
            maxGuestAllowed,
            file,
            start,
            timezone,
            end,
            description,
            venue,
            venuePhone,
            location,
            url,
        } = this.state;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            return setSnackbar(invalidFields[0].message);
        }

        if (end.value.isSameOrBefore(start.value)) {
            this.updateField({ field: 'end', valid: false });
            return setSnackbar('End date/time can not be before start date/time');
        }

        const formData = new FormData();
        formData.append('EventTypeId', event.EventType.id);
        formData.append('title', title.value);
        formData.append('theme', theme.value);
        formData.append('startDate', start.value.toISOString());
        formData.append('timezone', timezone.value);
        formData.append('endDate', end.value.toISOString());
        formData.append('venue', venue.value);
        formData.append('venuePhone', venuePhone.value);
        formData.append('location', location.value);
        formData.append('maxGuestAllowed', `${maxGuestAllowed.value}`);
        formData.append('url', url.value);
        formData.append('description', description.value);
        if (file.value) formData.append('file', file.value);

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        this.mounted && this.setState({ submitting: true });
        try {
            const response = await Upload.post(
                `/events?OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`,
                formData
            );
            Mixpanel.track('Event created');
            this.props.history.push(`/event/edit/${response.data.id}`);
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    render() {
        const { classes, organization, event } = this.props;
        const {
            description,
            file,
            location,
            start,
            timezone,
            end,
            submitting,
            theme,
            title,
            venue,
            venuePhone,
            maxGuestAllowed,
            url,
        } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title={'Basic Information'} />
                <form className={classes.container} onSubmit={this.handleSubmit.bind(this)}>
                    <Input
                        ref="title"
                        value={title.value}
                        error={!title.valid}
                        errorMessage="Event title is required"
                        validator={() => !!title.value}
                        onChange={(e) => this.updateField({ field: 'title', value: e.target.value })}
                        label="Event Title*"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="theme"
                        value={theme.value}
                        error={!theme.valid}
                        errorMessage="Event theme is required"
                        validator={() => !!theme.value}
                        onChange={(e) => this.updateField({ field: 'theme', value: e.target.value })}
                        label="Party or Gathering Theme*"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <FileInput
                        id="Main Event Photo"
                        ref="file"
                        value={file.value}
                        file={file.value}
                        marginBottom={18}
                        label="Photo to show on Event Page"
                        onChange={(file) =>
                            this.updateField({
                                field: 'file',
                                value: file,
                                expectedType: 'object',
                            })
                        }
                    />
                    <DateInput
                        ref="startDate"
                        margin="normal"
                        id="start-date-picker-dialog"
                        label="Start Date*"
                        format="MM/DD/yyyy"
                        minDate={this.minDate}
                        error={!start.valid}
                        errorMessage={
                            start.value < this.minDate
                                ? `Start date must be at least ${organization.daysBeforeEventCreate} days after today's date`
                                : 'Start date is required'
                        }
                        validator={() => start.value.isSameOrAfter(this.minDate)}
                        value={start.value}
                        onChange={(date) => {
                            this.updateField({ field: 'start', value: date });
                            // Automatically update the end date if it's not set or has been set to before the start date
                            if (!end.value || end.value < date) {
                                this.updateField({ field: 'end', value: date });
                            }
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change start date',
                        }}
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Select
                        ref="timezone"
                        className={classes.input}
                        marginBottom={18}
                        fullWidth
                        label="Timezone*"
                        margin="normal"
                        error={!timezone.valid}
                        errorMessage="Timezone is required"
                        validator={() => !!timezone.value}
                        value={timezone.value}
                        onChange={(e) => this.updateField({ field: 'timezone', value: e.target.value })}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}>
                        {Object.keys(TIMEZONE_MAP).map((key) => (
                            <MenuItem key={key} value={key}>
                                {TIMEZONE_MAP[key].label}
                            </MenuItem>
                        ))}
                    </Select>
                    <TimeInput
                        ref="startTime"
                        margin="normal"
                        id="start-time-picker"
                        label="Start Time*"
                        value={start.value}
                        onChange={(date) => {
                            this.updateField({
                                field: 'start',
                                value: date,
                            });
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change start time',
                        }}
                        error={!start.valid}
                        fullWidth
                        validator={() => {
                            if (!start.value.diff(this.minDate) && !event.EventType?.bypassApproval) {
                                const now = moment();
                                if (now > start.value) return false;
                            }
                            return true;
                        }}
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <DateInput
                        ref="endDate"
                        margin="normal"
                        id="end-date-picker-dialog"
                        label="End Date*"
                        format="MM/DD/yyyy"
                        minDate={start.value}
                        error={!end.valid}
                        errorMessage="End date is required"
                        validator={() => !!end.value}
                        value={end.value}
                        onChange={(date) => this.updateField({ field: 'end', value: date })}
                        KeyboardButtonProps={{
                            'aria-label': 'change start date',
                        }}
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <TimeInput
                        ref="endTime"
                        margin="normal"
                        id="end-time-picker"
                        label="End Time*"
                        value={end.value}
                        onChange={(date) => {
                            this.updateField({
                                field: 'end',
                                value: date,
                                // make sure time is after start time if it's the same day
                                valid: start.value.isSameOrBefore(end.value, 'date'),
                            });
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change end time',
                        }}
                        error={!end.valid}
                        fullWidth
                        validator={() => !!end.value}
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="description"
                        value={description.value}
                        error={!description.valid}
                        errorMessage="Description is required"
                        validator={() => !!description.value}
                        onChange={(e) => this.updateField({ field: 'description', value: e.target.value })}
                        label="Descripton of Event*"
                        multiline
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="venue"
                        value={venue.value}
                        error={!venue.valid}
                        errorMessage="Venue is required"
                        validator={() => !!venue.value}
                        onChange={(e) => this.updateField({ field: 'venue', value: e.target.value })}
                        label="Venue Name*"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="venuePhone"
                        value={venuePhone.value}
                        // CL3-I442 Make venu phone not required
                        // error={!venuePhone.valid}
                        // errorMessage="Event venuePhone is required"
                        // validator={() => !!venuePhone.value}
                        onChange={(e) => this.updateField({ field: 'venuePhone', value: e.target.value })}
                        label="Venue Phone"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="location"
                        value={location.value}
                        error={!location.valid}
                        // errorMessage="Venue street address is required"
                        // validator={() => !!location.value}
                        onChange={(e) => this.updateField({ field: 'location', value: e.target.value })}
                        label="Venue Street Address"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="url"
                        value={url.value}
                        error={!url.valid}
                        errorMessage="Event url is required"
                        validator={() => (url.value ? validator.isUrl(url.value) : true)}
                        onChange={(e) => this.updateField({ field: 'url', value: e.target.value })}
                        label="URL"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Input
                        ref="maxGuestAllowed"
                        value={maxGuestAllowed.value}
                        error={!maxGuestAllowed.valid}
                        type="number"
                        errorMessage={() => {
                            if (!maxGuestAllowed.value) return 'Event max guest allowed is required';
                            if (maxGuestAllowed.value < 0) return 'Max guest allowed cannot be less than 0';
                            return `Maximum guests allowed is ${organization.maxGuestAllowed}`;
                        }}
                        validator={() =>
                            maxGuestAllowed.value >= 0 && maxGuestAllowed.value <= organization.maxGuestAllowed
                        }
                        onChange={(e) =>
                            this.updateField({
                                field: 'maxGuestAllowed',
                                value: parseInt(e.target.value),
                            })
                        }
                        label="Max Guest Allowed"
                        variant="standard"
                        fullWidth
                        marginBottom={18}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                        inputProps={{
                            max: organization.maxGuestAllowed,
                        }}
                    />
                    <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                    <BottomButtons
                        classes={{ root: classes.bottom }}
                        leftButton={{ disabled: submitting }}
                        rightButton={{
                            onClick: this.handleSubmit.bind(this),
                            loading: submitting,
                        }}
                    />
                </form>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser, event, organization }) => ({
    cognitoUser,
    event,
    organization,
});
export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(BasicInformation));
