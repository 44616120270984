import React, { useState, useEffect } from 'react';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';
import Input from 'components/Input';
// styles
import styles from './styles';

const EnterBirthday = ({ classes, location, ...props }) => {
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const attendee = location.state.attendee;
    useEffect(() => {
        if (!attendee) props.history.goBack();
    }, [attendee, props.history]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!input) return;

        setLoading(true);
        try {
            await API.patch('ClutchAPI', `/users/${location.state.attendee.UserId}`, {
                body: {
                    birthDate: input,
                },
            });
            Mixpanel.track('Birthday entered starting RSVP');

            dispatch(setSnackbar('Birth date successfully submitted'));
            props.history.push({
                pathname: `/event-view/${props.match.params.id}/house-rules`,
                state: { attendee },
            });
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader title="Enter Birthday" />
            <MaxWidthContainer padding>
                <form>
                    <Input
                        type="date"
                        onChange={(e) => setInput(e.target.value)}
                        InputProps={{
                            classes: {
                                input: classes.inputRoot,
                            },
                        }}
                        required
                        fullWidth
                        autoFocus
                        className={classes.input}
                    />
                    <Button
                        variant="contained"
                        variantType="containedOuterSpace"
                        color="primary"
                        type="submit"
                        fullWidth
                        text="Submit"
                        classes={{ root: classes.button }}
                        loading={loading}
                        onClick={onSubmit}
                    />
                </form>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(EnterBirthday);
