import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

/**
 * !! PLEASE NOTE !!
 * This component does not work with FormValidator due to a bug in the material-ui/pickers library.
 * They did not implement a React.forwardRef on their KeyboardDatePicker component and that was not fixed
 * until the next major version of material-ui (v5), which we cannot change to currently
 */

const DateInput = React.forwardRef(
    (
        {
            valid = true,
            errorMessage,
            validator,
            classes,
            marginBottom,
            maxLength,
            inputProps = {},
            variant = 'outlined',
            ...rest
        },
        ref
    ) => {
        let bottomMargin = marginBottom ? { marginBottom } : {};

        if (maxLength) inputProps.maxLength = maxLength;

        return (
            <KeyboardDatePicker
                inputRef={ref}
                variant={variant}
                error={!valid}
                style={bottomMargin}
                helperText={!valid && errorMessage}
                inputProps={inputProps}
                {...rest}
            />
        );
    }
);

export default withStyles({})(DateInput);
