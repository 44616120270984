const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    inputRoot: {
        padding: 11,
        borderRadius: 18,
        background: '#f0f2f5',
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
        },
        '&.Mui-focused': {
            borderColor: 'red',

            '& fieldset': {
                borderWidth: '1px !important',
                borderColor: 'rgba(0, 0, 0, 0.23) !important',
            },
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
    },
    buttonRoot: {
        height: 'unset',
        padding: '6px 18px',
        backgroundColor: '#303542',
        color: 'white',
        marginLeft: 10,
    },
});

export default styles;
