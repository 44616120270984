import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Close, Description } from '@material-ui/icons';

// Modules
import S3MediaOperations from 'utils/S3MediaOperations';
import isEqual from 'lodash/isEqual';
import Dropzone from 'react-dropzone';
import clsx from 'classnames';
import { AllowedFileTypes, ImageFileTypes, bytesToSize } from 'utils/file-types';
// Styles
import styles from './styles';

class FileInput extends React.Component {
    constructor(props) {
        super(props);

        this.inputField = React.createRef();
        this.state = {
            src: '',
        };
    }

    componentDidMount() {
        this.setSource();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.file, this.props.file)) {
            this.setSource();
        }
    }

    async setSource() {
        const { file } = this.props;
        if (typeof file === 'object' && ImageFileTypes.includes(file.type)) {
            if (file.id) {
                if (file.url) this.setState({ src: file.url });
                else this.setState({ src: await S3MediaOperations.getDocumentUrl(file) });
            } else {
                const url = URL.createObjectURL(file);
                this.setState({ src: url });
            }
        } else {
            this.setState({ src: '' });
        }
    }

    handleDrop(files) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(files[0]);
        }
    }
    handleRemove() {
        this.props.onChange('');
    }

    render() {
        const {
            classes,
            label,
            valid = true,
            errorMessage,
            marginBottom = 0,
            accept = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
            file,
        } = this.props;

        const supportedTypes = accept.map((value) => AllowedFileTypes[value]).join(', ');

        return (
            // set defaults for text fields app-wide
            <div style={{ marginBottom }}>
                {label && <Typography className={classes.label}>{label}</Typography>}
                <Dropzone onDrop={this.handleDrop.bind(this)} accept={accept.join(',')}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            {!file ? (
                                <div
                                    className={clsx(classes.uploadRoot, {
                                        [classes.error]: !valid,
                                    })}
                                    {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <span className={classes.title}>
                                        Drop your file here, or tap to <span className={classes.browse}>browse</span>
                                    </span>
                                    <span className={classes.subtitle}>Supports {supportedTypes}</span>
                                </div>
                            ) : (
                                <div
                                    className={clsx(classes.uploadedRoot, {
                                        [classes.error]: !valid,
                                    })}
                                    {...getRootProps({
                                        onClick: (e) => {
                                            if (this.remove.contains(e.target)) {
                                                e.stopPropagation();
                                            }
                                        },
                                    })}>
                                    <input {...getInputProps()} />
                                    {!ImageFileTypes.includes(file.type) ? (
                                        <Description className={classes.document} />
                                    ) : (
                                        <div
                                            style={{ backgroundImage: `url(${this.state.src})` }}
                                            alt="dropped-file"
                                            className={classes.image}></div>
                                    )}
                                    <div className={classes.information}>
                                        <span className={classes.title}>{file.name}</span>
                                        <span className={classes.subtitle}>{bytesToSize(file.size)}</span>
                                    </div>
                                    <div
                                        ref={(el) => (this.remove = el)}
                                        className={classes.removeButton}
                                        onClick={this.handleRemove.bind(this)}>
                                        <Close />
                                        <span>Remove</span>
                                    </div>
                                </div>
                            )}
                        </section>
                    )}
                </Dropzone>
                {!valid && errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
            </div>
        );
    }
}

export default withStyles(styles)(FileInput);
