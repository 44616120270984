const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 0px',
    },
    iconContainer: {
        position: 'relative',
        marginTop: 30, // The date text is 30px in height, so we offset it by margining top 30
    },
    iconBubble: {
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        height: 28,
        width: 28,
        padding: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 16px',
    },
    divider: {
        position: 'absolute',
        top: 65,
        left: 0,
        right: 0,
        height: '130%',
        width: 3,
        margin: 'auto',
        background: 'rgba(0, 0, 0, 0.25)',
    },

    // Event Content
    eventContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        cursor: 'pointer',
    },
    date: {
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        paddingBottom: 12,
    },
    bubble: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        background: 'white',
        padding: 20,
        fontSize: 16,
    },
    time: {
        paddingTop: 12,
    },
    bubbleContent: {
        maxWidth: 200,
    },
});

export default styles;
