const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    inputLabel: {
        textDecoration: 'underline',
        color: '#4a4a4a',
    },
    mb15: {
        marginBottom: 15,
    },
    mb20: {
        marginBottom: 20,
    },
    menuSquare: {
        minHeight: 121,
    },
});

export default styles;
