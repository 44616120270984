import { get } from 'lodash';
import { USER_ELIGIBILITY_MAP } from 'global-constants';
import { checkEligibility } from 'utils/eligibility-utils';

/**
 * @name filterMenuTiles - Filters out menu tiles based on current user event state
 * @param {Object}   eligibilityData  Data from Redux eligibility store
 * @returns {Array}
/**
 * @requires mapUserEligibility response as an argument
 */
export const filterMenuTiles = (eligibilityData) => {
    const menus = Object.keys(USER_ELIGIBILITY_MAP);
    /*
     * Filter MenuSquares key object pair in the USER_ELIGIBILITY_MAP
     */

    return menus.filter((name) =>
        checkEligibility(
            eligibilityData,
            get(USER_ELIGIBILITY_MAP, `${name}.MenuSquares.required`),
            get(USER_ELIGIBILITY_MAP, `${name}.MenuSquares.excluded`),
            get(USER_ELIGIBILITY_MAP, `${name}.MenuSquares.overrides`)
        )
    );
};
