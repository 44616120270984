const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    input: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    inputLabel: {
        textDecoration: 'underline',
        color: '#4a4a4a',
    },
    formHelperText: {
        color: '#4a4a4a',
        marginTop: 10,
    },
    note: {
        marginTop: 10,
        color: theme.palette.error.main,
        opacity: 1.0
    }
});

export default styles;
