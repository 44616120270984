const styles = (theme) => ({
    selectOrgRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    loginButton: {
        marginBottom: 16,
        marginTop: 16,
    },
    bottomLinks: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    bottomLink: {
        textAlign: 'center',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    paper: {
        alignItems: 'center',
        background: '#fff',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        paddingLeft: 32,
        paddingRight: 32,
        transition: '1s all',
        width: '100%',
        zIndex: 999,
        [theme.breakpoints.up('sm')]: {
            borderRadius: 13,
            height: 'unset',
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 48,
            paddingBottom: 48,
            maxWidth: 400,
        },
    },
});

export default styles;
