import { Reply, AccessTime, Done, Close, HelpOutline } from '@material-ui/icons';

export const eventStatus = {
    Submitted: 'submitted',
    Editing: 'editing',
    Approved: 'approved',
    Denied: 'rejected',
    Update_Required: 'update-required',
};

export const reviewStatusIcons = {
    [eventStatus.Editing]: { Icon: Reply, styles: { transform: 'scaleX(-1)' } },
    [eventStatus.Submitted]: { Icon: AccessTime },
    [eventStatus.Approved]: { Icon: Done },
    [eventStatus.Denied]: { Icon: Close },
    [eventStatus.Update_Required]: { Icon: HelpOutline },
};

export const editingStatuses = [eventStatus.Submitted, eventStatus.Update_Required, eventStatus.Editing];
export const templateTypes = {
    TITLE: 'title',
    HEADING: 'heading',
    BODY: 'bodyText',
    CHECKBOX: 'checkBox',
    FILE_UPLOAD: 'fileUpload',
    FILE_DOWNLOAD: 'fileDownload',
    SHORT_TEXT_INPUT: 'shortTextInput',
    TEXTAREA_INPUT: 'textAreaInput',
    DATE_TIME_INPUT: 'dateTimeInput',
    VOLUNTEER_SELECT: 'volunteer',
    WHITE_SPACE: 'whiteSpace',
    HORIZONTAL_RULER: 'horizontalRuler',
    SELECTS: 'selects',
};

export const markupInputTypes = [
    templateTypes.TEXTAREA_INPUT,
    templateTypes.SHORT_TEXT_INPUT,
    templateTypes.CHECKBOX,
    templateTypes.FILE_UPLOAD,
    templateTypes.VOLUNTEER_SELECT,
    templateTypes.DATE_TIME_INPUT,
];
export const OnChangeTimer = 1000;

export const USER_ELIGIBILITY_MAP = {
    CheckIn: {
        MenuSquares: {
            required: ['auth.isCheckedOut', 'auth.isRsvp'],
            excluded: [],
            overrides: [],
        },
    },
    CheckOut: {
        MenuSquares: {
            required: ['auth.isCheckedIn'],
            excluded: [],
            overrides: [],
        },
    },
    GuestList: {
        MenuSquares: {
            required: ['auth.isCheckInStaff'],
            excluded: [],
            overrides: [],
        },
    },
    ManageVolunteers: {
        MenuSquares: {
            required: ['auth.isCreator'],
            excluded: [],
            overrides: [],
        },
    },
    SendInvites: {
        MenuSquares: {
            required: ['auth.isCreator'],
            excluded: [],
            overrides: [],
        },
    },
    EditEvent: {
        MenuSquares: {
            required: ['auth.isCreator'],
            excluded: [],
            overrides: [],
        },
    },

    SendEventNotification: {
        MenuSquares: {
            required: ['auth.isCreator'],
            excluded: [],
            overrides: [],
        },
    },
    InviteGuests: {
        MenuSquares: {
            required: ['auth.isRsvp'],
            excluded: [],
            overrides: [],
        },
    },
    CheckInGuests: {
        MenuSquares: {
            required: ['auth.isCheckInStaff', 'auth.isRsvp'],
            excluded: [],
            overrides: [],
        },
    },
    SafetyAlerts: {
        MenuSquares: {
            required: ['auth.isCheckedIn'],
            excluded: [],
            overrides: [],
        },
    },

    IncidentReport: {
        MenuSquares: {
            required: ['auth.isCheckedIn'],
            excluded: [],
            overrides: [],
        },
    },
    BeverageEducation: {
        MenuSquares: {
            required: ['auth.isCheckedIn'],
            excluded: [],
            overrides: [],
        },
    },

    CancelEvent: {
        MenuSquares: {
            required: ['auth.isCreator'],
            excluded: [],
            overrides: [],
        },
    },
};

export const policyIdentifiers = {
    SITE_ADMIN_POLICY: 'site-admin-policy',
    BASIC_POLICIES: 'basic-policies',
    ADMIN_APP_LOGIN: 'admin-app-login',
    MANAGE_ORGANIZATIONS: 'manage-organizations',
    MEMBER_APP_LOGIN: 'member-app-login',
    CHECK_IN_ATTENDEE_PERMISSIONS: 'check-in-attendee-permissions',
    EVENT_ATTENDEE_PERMISSIONS: 'event-attendee-permissions',
    EVENT_CREATOR: 'event-creator',
    REMOVE_EVENTS: 'remove-events',
    VIEW_EVENTS: 'view-events',
    COMMENT_ON_EVENTS: 'comment-on-events',
    EVENT_APPROVAL_REQUIRED: 'event-approval-required',
    FINAL_EVENT_APPROVER: 'final-event-approver',
    MANAGE_EVENT_TYPES: 'manage-event-types',
    MANAGE_EVENT_STEPS: 'manage-event-steps',
    MANAGE_CHAPTERS: 'manage-chapters',
    VIEW_PERSONAL_SAFETY_ALERTS: 'view-personal-safety-alerts',
    VIEW_CHAPTER_SAFETY_ALERTS: 'view-chapter-safety-alerts',
    SEND_CHAPTER_SAFETY_ALERT_ADMIN: 'send-chapter-safety-alert-admin',
    MANAGE_HOUSE_RULES: 'manage-house-rules',
    MANAGE_EDUCATION: 'manage-education',
    VIEW_INCIDENT_REPORTS: 'view-incident-reports',
    MANAGE_SYSTEM_SETUP: 'manage-system-setup',
    MANAGE_ORGANIZATION_USERS: 'manage-organization-users',
    MANAGE_ROLES_AND_PERMISSIONS: 'manage-roles-&-permissions',
    MANAGE_APPROVAL_PROCESS: 'manage-approval-process',
    VIEW_PRE_APPROVAL_EVENTS: 'view-pre-approval-events',
    PERSONAL_SAFETY_ALERTS: 'personal-safety-alerts',
    INCIDENT_REPORTS: 'incident-reports',
    CHAPTER_SAFETY_ALERTS: 'chapter-safety-alerts',
    SEND_CHAPTER_SAFETY_ALERT_MEMBER: 'send-chapter-safety-alert-member',
    HOUSE_RULES: 'house-rules',
    EDUCATION_RESOURCES: 'education-resources',
};

export const TIMEZONE_MAP = {
    Eastern: {
        label: 'Eastern Standard Time',
        abbv: 'EST',
    },
    Central: {
        label: 'Central Standard Time',
        abbv: 'CST',
    },
    Mountain: {
        label: 'Mountain Standard Time',
        abbv: 'MST',
    },
    Arizona: {
        label: 'Arizona Time',
        abbv: 'Arizona Time',
    },
    Pacific: {
        label: 'Pacific Standard Time',
        abbv: 'PST',
    },
    Alaska: {
        label: 'Alaska Standard Time',
        abbv: 'AST',
    },
    Hawaii: {
        label: 'Hawaii Standard Time',
        abbv: 'HST',
    },
};
