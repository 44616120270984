import React, { useState, useEffect, useRef } from 'react';
// Modules
import { API } from 'aws-amplify';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { setToast } from 'redux/actions/toast';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import List from 'components/List';
import BasicDialog from 'components/Dialogs/BasicDialog';
// Utils
import checkError from 'utils/check-error';
// styles
import styles from './styles';

const ManageInvitedGuests = ({ match, classes, ...props }) => {
    const eventId = match.params.id;
    const [guests, setGuests] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({
        cognitoUser,
    }));
    const targetGuest = useRef({});

    useEffect(() => {
        (async function () {
            const attendee = await API.get(
                'ClutchAPI',
                `/events/${eventId}/attendees?UserId=${cognitoUser.id}&OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`
            );
            setGuests(attendee.data[0].guests);
        })();
    }, []);

    function onDelete(guest) {
        targetGuest.current = guest;
        setOpen(true);
    }

    async function handleDeleteGuest() {
        try {
            const res = await API.del(
                'ClutchAPI',
                `/events/${eventId}/attendees/${targetGuest.current.id}?&OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`
            );
            setGuests(guests.filter((guest) => guest.id !== targetGuest.current.id));
            setOpen(false);
            dispatch(
                setToast({
                    message: `${targetGuest.current.User.firstName} ${targetGuest.current.User.lastName} was removed`,
                })
            );
        } catch (err) {
            dispatch(setSnackbar(checkError(err)));
        }
    }

    return (
        <div className={classes.root}>
            <PageHeader title="Manage Invited Guests" />
            <BasicDialog
                open={open}
                subtitle="Are you sure you want to remove from Guest List"
                onClose={() => setOpen(false)}
                onSubmit={handleDeleteGuest}
                rightButton={{ text: 'Confirm' }}
            />
            <MaxWidthContainer padding classes={{ root: classes.container }}>
                <List
                    className={classes.list}
                    listTitle={`Invited Guests (${guests.length})`}
                    listItemData={guests}
                    onDelete={onDelete}
                    muiListItemProps={{
                        divider: true,
                        disableGutters: true,
                    }}
                />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(ManageInvitedGuests);
