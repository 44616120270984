import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import EventType from './EventType';
import BasicInformation from './BasicInformation';
import EventHome from './EventHome';
import EventStep from './EventStep';

const Event = (props) => {
    useEffect(() => {}, []); // update only on page change

    return (
        <Switch>
            <Route exact path="/event/type" component={EventType} />
            <Route exact path="/event/create" component={BasicInformation} />
            <Route exact path="/event/edit/:id" component={EventHome} />
            <Route exact path="/event/edit/:id/step/:step" component={EventStep} />
        </Switch>
    );
};

export default Event;
