import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const HorizontalRuler = ({ classes, children, ...props }) => {
    return <div className={classes.root} />;
};

export default withStyles(styles)(HorizontalRuler);
