import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import {
    INIT,
    SET_SAFETY_ALERTS,
    GET_SAFETY_ALERTS_SUCCESS,
    GET_SAFETY_ALERTS_ERROR,
} from 'redux/reducers/safetyAlerts';
import checkError from 'utils/check-error';

export const get = () => async (dispatch, getState) => {
    const { safetyAlerts, cognitoUser } = cloneDeep(getState());
    dispatch(set({ ...safetyAlerts, fetching: true }));
    try {
        const response = await API.get(
            'ClutchAPI',
            `/organizations/${cognitoUser.Organization.id}/safety-alerts?ParentId=${cognitoUser.Organization.ParentId}`
        );
        dispatch({
            payload: { ...response, fetching: false },
            type: GET_SAFETY_ALERTS_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...safetyAlerts,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_SAFETY_ALERTS_ERROR,
        });
        throw error;
    }
};

export const set = (type) => {
    if (type) {
        return {
            type: SET_SAFETY_ALERTS,
            payload: type,
        };
    }
    // Empty type in store if no type is sent
    return {
        type: SET_SAFETY_ALERTS,
        payload: cloneDeep(INIT),
    };
};
