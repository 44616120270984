import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import { SECTION_MARKUP_MAP } from './MarkupMap';

// styles
import styles from './styles';

class EventStepViewer extends React.Component {
    getMarkupValue(id) {
        const value = this.props.section.values ? this.props.section.values[id] : '';
        if (typeof value === 'boolean') return value;
        return value || '';
    }

    render() {
        const { classes, section } = this.props;

        return (
            <div className={classes.root}>
                {section.markup.map((markup, index) => {
                    // Fallsback to ErrorMessage.js if markup.type is unknown.
                    const MarkupComponent =
                        SECTION_MARKUP_MAP[markup.type] === undefined
                            ? SECTION_MARKUP_MAP['default']
                            : SECTION_MARKUP_MAP[markup.type];
                    markup = { ...markup, value: this.getMarkupValue(markup.id) };
                    return (
                        <MarkupComponent
                            key={`section-${index}`}
                            {...markup}
                            module={this.props.module}
                            onChange={(value) => {
                                this.props.onChangeItem(markup, value);
                            }}
                        />
                    );
                })}
            </div>
        );
    }
}
export default withStyles(styles)(EventStepViewer);
