export const SET_PAST_EVENTS = 'SET_PAST_EVENTS';
export const GET_PAST_EVENTS_ERROR = 'GET_PAST_EVENTS_ERROR';
export const GET_PAST_EVENTS_SUCCESS = 'GET_PAST_EVENTS_SUCCESS';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_PAST_EVENTS_SUCCESS:
        case SET_PAST_EVENTS:
            return payload;
        default:
            return state;
    }
}
