import React from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { set } from 'redux/actions/beverageEducation';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// styles
import styles from './styles';

const PreAcceptedHome = ({ classes, ...props }) => {
    const dispatch = useDispatch();

    const onAccept = () => {
        dispatch(set({ accepted: true }));
        props.history.push('/beverage-education/drink-type');
    };

    return (
        <>
            <PageHeader title="Beverage Education" classes={{ header: classes.pageHeader }} />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.content}>
                    <ProgressBar classes={{ root: classes.progressBar }} variant="determinate" />
                    <Typography align="center" className={classes.title}>
                        Estimated Blood Alcohol Level
                    </Typography>
                    <Typography className={classes.disclaimer}>
                        DISCLAIMER: Use of this application is strictly for information purposes only. You hereby agree
                        and recognize that the application has no way of independently verifying the accuracy of your
                        reported alcohol consumption or verifying your sex or weight – which factors can significantly
                        alter results. By using this application, you agree to defend, hold harmless and indemnify
                        Clutch Partners LLC and its affiliates from and against any and all claims, suits, or damages of
                        any kind related to your use of this application, your consumption of alcohol or any other
                        events in which you are involved, before, during or after your use of the application.
                    </Typography>
                </div>
                <Button
                    classes={{ root: classes.button }}
                    variantType="containedOuterSpace"
                    color="primary"
                    type="submit"
                    fullWidth={true}
                    text="Accept and Continue"
                    onClick={onAccept}
                />
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(PreAcceptedHome);
