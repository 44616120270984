import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
import dayjs from 'dayjs';
import checkPolicy from 'utils/check-policy';
import { policyIdentifiers } from 'global-constants';
// redux
import { connect } from 'react-redux';
import { get as getEvent, set as setEvent } from 'redux/actions/event';
import { setSnackbar } from 'redux/actions/snackbar';
import Upload from 'utils/Upload';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import EventStepper from 'components/EventStepper';
import Skeleton from 'components/Skeleton';
import BottomButtons from 'components/BottomButtons';
import EventStepViewer from 'components/EventStepViewer';
import Button from 'components/Button';
// styles
import styles from './styles';
import { API } from 'aws-amplify';

class EventStep extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            changingSteps: false,
            submitting: false,
            finishingLater: false,
            step: {},
            sections: {},
        };
        this.sections = this.state.sections;
        this.timeout = null;
        this.componentValues = {};
    }

    componentDidMount() {
        this.mounted = true;
        this._getEvent();
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.step !== this.props.match.params.step) {
            const step = this.props.match.params.step;
            this.setState(
                {
                    changingSteps: true,
                },
                () => {
                    if (step === 'submit') {
                        this.sections = {};
                        this.setState({
                            step,
                            sections: this.sections,
                            changingSteps: false,
                        });
                    } else {
                        const sections = {};

                        this.props.event.EventModules[parseInt(this.props.match.params.step)].sections.forEach(
                            (section) => {
                                sections[section.id] = section;
                            }
                        );
                        this.setState({
                            sections,
                            changingSteps: false,

                            step: this.props.event.EventModules[parseInt(this.props.match.params.step)],
                        });
                        this.sections = sections;
                    }
                }
            );
        }
    }

    async _getEvent() {
        const { getEvent, setSnackbar, match, event } = this.props;
        const step = this.props.match.params.step;

        this.setState({ loading: true });
        try {
            let response;
            if (parseInt(event.id) === parseInt(match.params.id)) {
                response = event;
            } else {
                response = await getEvent(match.params.id);
            }

            if (step === 'submit') {
                this.sections = {};
                this.setState({ step, sections: this.sections });
            } else {
                const sections = {};

                response.EventModules[parseInt(match.params.step)].sections.forEach((section) => {
                    sections[section.id] = section;
                });
                this.setState({
                    sections,
                    step: response.EventModules[parseInt(match.params.step)],
                });
                this.sections = sections;
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ loading: false });
    }

    handleSubmit(e) {
        e.preventDefault();

        // loop through section
        //  document.findelementbyid(section.markup[x].id).value
    }

    handleChangeItem = (section, markup, updatedValue) => {
        if (section.id) {
            this.componentValues[section.id] = {
                ...this.componentValues[section.id],
                [markup.id]: updatedValue,
            };
        } else {
            this.componentValues[section.id] = {
                ...this.componentValues[section.id],
                [markup.id]: updatedValue,
            };
        }
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const sectionsClone = cloneDeep(this.state.sections);
            Object.keys(this.componentValues).forEach((sectionId) => {
                if (sectionsClone[sectionId]) sectionsClone[sectionId].values = this.componentValues[sectionId];
                else {
                    sectionsClone[sectionId] = {
                        ...this.section,
                        values: this.componentValues[sectionId],
                    };
                }
            });
            this.setState({ sections: sectionsClone });
        }, 1000);
    };

    async handleSubmitForApproval() {
        const { setSnackbar, event, match } = this.props;

        this.setState({ submitting: true });
        try {
            await API.patch(
                'ClutchAPI',
                `/events/${match.params.id}/status/submitted?OrganizationId=${event.OrganizationId}&ParentId=${event.ParentId}`
            );
            Mixpanel.track('Event submitted for approval');

            if (event.EventType.bypassApproval) {
                setSnackbar('Event was approved.');
                this.props.history.push(`/event-view/${event.id}`);
            } else {
                setSnackbar('This event has been submitted for approval.');
                this.props.history.push('/');
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    }

    async handleContinue(continueForward) {
        const { step, sections } = this.state;
        const { setSnackbar, event, match, setEvent } = this.props;

        if (sections.dates) {
            const updatedValues = {
                ...this.sections.dates.values,
                ...sections.dates.values,
            };

            const start = dayjs(`${updatedValues.startDate}`);
            const end = dayjs(`${updatedValues.endDate}`);

            if (start.isAfter(end)) return setSnackbar('Start date can not be after end date');
        }

        const formData = new FormData();
        step.sections.forEach((section) => {
            if (!this.componentValues[section.id]) return; // No values for this section
            section.markup.forEach((markup) => {
                if (!this.componentValues[section.id][markup.id]) return; // No values for this markup

                // If fileupload, append the file to formData
                if (markup.type === 'fileUpload') {
                    formData.append(`${markup.id}`, this.componentValues[section.id][markup.id]);
                }
            });
        });
        formData.append('sections', JSON.stringify(this.componentValues));

        this.setState({
            submitting: continueForward,
            finishingLater: !continueForward,
        });
        try {
            const response = await Upload.patch(
                `/events/${match.params.id}/modules/${step.id}?ParentId=${event.ParentId}&OrganizationId=${event.OrganizationId}`,
                formData
            );
            Mixpanel.track('Event step updated');

            setEvent({ ...event, ...response.data }); // Dont override just merge.
            if (step.stepNumber + 1 !== event.EventModules.length) {
                if (continueForward) {
                    this.props.history.push(`/event/edit/${match.params.id}/step/${parseInt(match.params.step) + 1}`);
                } else {
                    this.props.history.push('/');
                    setSnackbar('Event state has been saved');
                }
            } else {
                this.props.history.push(`/event/edit/${match.params.id}/step/submit`);
            }
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false, finishingLater: false });
    }

    render() {
        const { classes, event, match, cognitoUser } = this.props;
        const { step } = this.state;

        if (this.state.loading || !event.id || (!step.id && step !== 'submit')) return <Skeleton type="eventStep" />;

        let leftButton = false;
        let rightButton = false;
        if (step === 'submit') {
            leftButton = {
                text: event.EventModules[event.EventModules.length - 1].name,
                onClick: () =>
                    this.props.history.push(
                        `/event/edit/${match.params.id}/step/${
                            event.EventModules[event.EventModules.length - 1].stepNumber
                        }`
                    ),
            };
        } else if (step.stepNumber !== 0) {
            leftButton = {
                text: event.EventModules[step.stepNumber - 1].name,
                onClick: () =>
                    this.props.history.push(`/event/edit/${match.params.id}/step/${parseInt(match.params.step) - 1}`),
            };
        }

        if (step === 'submit') {
            rightButton = false;
        } else if (step.stepNumber + 1 !== event.EventModules.length) {
            rightButton = {
                text: event.EventModules[step.stepNumber + 1].name,
                onClick: () =>
                    this.props.history.push(`/event/edit/${match.params.id}/step/${parseInt(match.params.step) + 1}`),
            };
        } else {
            rightButton = {
                text: 'Submit',
                onClick: () => this.props.history.push(`/event/edit/${match.params.id}/step/submit`),
            };
        }

        return (
            <div className={classes.root}>
                <EventStepper
                    progress={step === 'submit' ? 100 : (step.stepNumber / event.EventModules.length) * 100}
                    leftButton={leftButton}
                    rightButton={rightButton}
                />
                {step === 'submit' ? (
                    <div className={classes.container}>
                        <div>
                            <Typography gutterBottom className={classes.heading}>
                                Woo! Looks like we’re ready!
                            </Typography>
                            <Typography className={classes.body}>
                                Push the submit button to send your event off for approval!
                            </Typography>
                        </div>
                        <Button
                            fullWidth
                            text="Submit for Approval"
                            variantType="containedOuterSpace"
                            onClick={this.handleSubmitForApproval.bind(this)}
                            loading={this.state.submitting}
                            disabled={
                                !checkPolicy({
                                    ParentId: cognitoUser.Organization.ParentId,
                                    OrganizationId: cognitoUser.Organization.id,
                                    policies: cognitoUser.policies,
                                    policy: policyIdentifiers.EVENT_CREATOR,
                                })
                            }
                        />
                    </div>
                ) : (
                    <div className={classes.container}>
                        <div className={classes.step}>
                            {!this.state.changingSteps &&
                                step.sections.map((section, index) => {
                                    return (
                                        <div key={`section-${index}`} className={classes.section}>
                                            <EventStepViewer
                                                module={step}
                                                onChangeItem={(markup, values) =>
                                                    this.handleChangeItem(section, markup, values)
                                                }
                                                section={section}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                        <BottomButtons
                            leftButton={{
                                onClick: this.handleContinue.bind(this, false),
                                loading: this.state.finishingLater,
                                disabled:
                                    this.state.submitting ||
                                    !checkPolicy({
                                        ParentId: cognitoUser.Organization.ParentId,
                                        OrganizationId: cognitoUser.Organization.id,
                                        policies: cognitoUser.policies,
                                        policy: policyIdentifiers.EVENT_CREATOR,
                                    }),
                            }}
                            rightButton={{
                                onClick: this.handleContinue.bind(this, true),
                                loading: this.state.submitting,
                                disabled:
                                    this.state.finishingLater ||
                                    !checkPolicy({
                                        ParentId: cognitoUser.Organization.ParentId,
                                        OrganizationId: cognitoUser.Organization.id,
                                        policies: cognitoUser.policies,
                                        policy: policyIdentifiers.EVENT_CREATOR,
                                    }),
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser, event }) => ({ cognitoUser, event });

export default connect(mapStateToProps, { getEvent, setEvent, setSnackbar })(withStyles(styles)(EventStep));
