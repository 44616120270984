import React, { useEffect, useState } from 'react';
// Redux
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
import { bytesToSize } from 'utils/file-types';
import clsx from 'clsx';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Skeleton from 'components/Skeleton';
import BottomButtons from 'components/BottomButtons';

// styles
import styles from './styles';

const Rule = ({ classes, match, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const [rule, setRule] = useState({});
    const [loading, setLoading] = useState(true);

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getRule() {
            mounted && setLoading(true);
            try {
                const response = await API.get(
                    'ClutchAPI',
                    `/organizations/${cognitoUser.Organization.ParentId}/rules/by-order/${match.params.order}`
                );
                Mixpanel.track('House Rule viewed');
                mounted && setRule(response);
            } catch (error) {
                props.history.goBack();
                dispatch(setSnackbar(checkError(error)));
            }
            mounted && setLoading(false);
        }

        _getRule();
    }, [match.params.order, props.history, dispatch, cognitoUser.Organization.ParentId]);

    return (
        <div className={classes.root}>
            <PageHeader title={rule.title} loading={loading} />
            <MaxWidthContainer padding classes={{ padding: classes.container }}>
                {loading ? (
                    <Skeleton type="infoPage" />
                ) : (
                    <>
                        <div className={classes.rule}>
                            {rule.media && (
                                <img
                                    alt={'rule'}
                                    src={rule.media.url}
                                    className={clsx(classes.img, {
                                        [classes.imageCover]: rule.media.height > 300 && rule.media.width > 600,
                                        [classes.imageContain]: rule.media.height < 299 && rule.media.width < 599,
                                    })}
                                />
                            )}
                            {rule.videoUrl && (
                                <div className={classes.video}>
                                    <ReactPlayer url={rule.videoUrl} width="100%" />
                                </div>
                            )}
                            <div dangerouslySetInnerHTML={{ __html: rule.information }} />
                            {rule.file && (
                                <div className={classes.file}>
                                    <a href={rule.file.url}>
                                        {rule.file.name} ({bytesToSize(rule.file.size)})
                                    </a>
                                </div>
                            )}
                        </div>
                        <BottomButtons
                            classes={{ root: classes.btm }}
                            leftButton={{
                                text: 'Previous',
                                disabled: !rule.hasPrevious,
                                onClick: () => props.history.push(`/rules/${parseInt(rule.order) - 1}`),
                            }}
                            rightButton={{
                                text: 'Next',
                                disabled: !rule.hasNext,
                                onClick: () => props.history.push(`/rules/${parseInt(rule.order) + 1}`),
                            }}
                        />
                    </>
                )}
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(Rule);
