const styles = (theme) => ({
    information: {
        padding: '10px 0px',
        '&> h4': {
            marginBottom: 5,
        },
    },
});

export default styles;
