export default {
    firstName: {
        value: '',
        valid: true,
    },
    lastName: {
        value: '',
        valid: true,
    },
    phone: {
        value: '',
        valid: true,
    },
};
