import React, { useState, useEffect } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

// Components
import Button from 'components/Button';

// Utils
import S3MediaOperations from 'utils/S3MediaOperations';
import { bytesToSize, AllowedFileTypes } from 'utils/file-types';
// styles
import styles from './styles';

const FileDownload = ({ classes, file: { bucket, key, size, type }, ...props }) => {
    const [url, setUrl] = useState();

    useEffect(() => {
        if (bucket && key) {
            S3MediaOperations.getDocumentUrl({ bucket, key }).then((res) => {
                setUrl(res);
            });
        }
    }, [bucket, key]);

    return (
        <div className={classes.root}>
            {props.title && <Typography className={classes.title}>{props.title}</Typography>}
            <Button
                classes={{ root: classes.button }}
                component="a"
                href={url}
                variant="contained"
                variantType="containedOuterSpace"
                color="primary"
                startIcon={<GetApp />}
                loading={!url}
                fullWidth>
                <div className={classes.buttonContent}>
                    <Typography>Download File</Typography>
                    <Typography className={classes.fileSize}>
                        {bytesToSize(size)} {AllowedFileTypes[type]}
                    </Typography>
                </div>
            </Button>
            {props.helperText && <Typography className={classes.helperText}>{props.helperText}</Typography>}
        </div>
    );
};

export default withStyles(styles)(FileDownload);
