// icons
import BeverageSvg from 'assets/icons/Beverage.svg';
import IncidentReportSvg from 'assets/icons/IncidentReport.svg';
import CheckOutSvg from 'assets/icons/CheckOut.svg';
import SafetyAlertSvg from 'assets/icons/Alerts.svg';
import CheckInSvg from 'assets/icons/CheckIn.svg';
import GuestListSvg from 'assets/icons/GuestList.svg';
import InviteGuestsSvg from 'assets/icons/InviteGuests.svg';
import ManageVolunteersSvg from 'assets/icons/ManageVolunteers.svg';
import SendInvitesSvg from 'assets/icons/SendInvites.svg';
import PencilSvg from 'assets/icons/Pencil.svg';
import CheckInGuests from 'assets/icons/CheckInGuests.svg';
import MegaphoneSvg from 'assets/icons/Megaphone.svg';
import CancelSvg from 'assets/icons/CancelCircle.svg';

export const MENU_SQUARE_MAP = {
    CheckIn: {
        text: 'Check In',
        icon: CheckInSvg,
        path: '/codes',
    },
    SendInvites: {
        text: 'Send Invites',
        icon: SendInvitesSvg,
        path: '/send-invites',
    },
    InviteGuests: {
        text: 'Invite Guests',
        icon: InviteGuestsSvg,
        path: '/invite-guests',
    },
    GuestList: {
        text: 'Guest List',
        icon: GuestListSvg,
        path: '/guest-list',
    },
    ManageVolunteers: {
        text: 'Manage Volunteers',
        icon: ManageVolunteersSvg,
        path: '/volunteers/edit',
        basepath: 'true',
        params: 'true',
    },
    SafetyAlerts: {
        text: 'Safety Alerts',
        icon: SafetyAlertSvg,
        path: '/safety-alerts',
        basepath: 'true',
    },
    SendEventNotification: {
        text: 'Send Event Notification',
        icon: MegaphoneSvg,
        path: '/send-notification',
    },
    EditEvent: {
        text: 'Edit Event',
        icon: PencilSvg,
        path: '/edit',
    },

    IncidentReport: {
        text: 'Incident Report',
        icon: IncidentReportSvg,
        path: '/report',
    },
    BeverageEducation: {
        text: 'Beverage Education',
        icon: BeverageSvg,
        path: '/beverage-education/set-info',
        basepath: 'true',
    },
    CheckInGuests: {
        text: 'Check In Guests',
        icon: CheckInGuests,
        path: '/check-in-guests',
    },
    CheckOut: {
        text: 'Check Out',
        icon: CheckOutSvg,
        action: 'checkout',
    },
    CancelEvent: {
        text: 'Cancel Event',
        icon: CancelSvg,
        path: '/cancel',
    },
};
