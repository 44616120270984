import { AppBarHeight } from 'global-styles';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },

    /** App Bar */
    appBar: {
        position: 'absolute',
        height: AppBarHeight,
    },
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 80,
    },

    logo: {
        display: 'flex',
        justifyContent: 'center',
        flexBasis: '34%',
        cursor: 'pointer',
        '& img': { height: 30 },
        [theme.breakpoints.down('sm')]: {
            '& img': { height: 20 },
        },
    },
    large: {
        '& img': {
            height: 50,
        },
        [theme.breakpoints.down('sm')]: {
            '& img': { height: 40 },
        },
    },
    left: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexBasis: '33%',
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexBasis: '33%',
    },

    /** Extra */
    contrastText: {
        color: theme.palette.primary.contrastText,
    },

    /** Content */
    content: {
        paddingTop: AppBarHeight,
        flexGrow: 1,
        backgroundColor: 'white',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
    },
});

export default styles;
