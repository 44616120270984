const styles = (theme) => ({
    root: {},
    paper: {
        padding: '25px 18px',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: 6,
    },
    percentage: {
        fontSize: 40,
        fontWeight: 700,
    },
    smallText: {
        opacity: 0.5,
        fontSize: 14,
    },
    progressRoot: {
        height: 30,
        borderRadius: 15,
        backgroundColor: '#e8e8e8',
        marginTop: 25,
    },
    bar1Buffer: {
        backgroundColor: '#d35656',
    },
    bar2Buffer: {
        backgroundColor: '#d35656',
        opacity: 0.6,
    },
    dashed: {
        display: 'none',
    },
});

export default styles;
