import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const PageHeader = ({ classes, title, subTitle, loading }) => {
    return (
        <div className={classes.header}>
            {loading ? (
                <Skeleton variant="rect" height={20} width={200} />
            ) : (
                <>
                    {title}
                    {subTitle && (
                        <Typography align="center" className={classes.subHeader}>
                            {subTitle}
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
};

export default withStyles(styles)(PageHeader);
