import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// Components
import Input from 'components/Input';
import Button from 'components/Button';

import styles from './styles';

const EventCommentInput = ({ classes, value, onChange, onSubmit, ...props }) => {
    return (
        <div component="form" className={classes.root}>
            <Input
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                        focused: classes.multilineInputFocused,
                    },
                }}
                multiline
                fullWidth
                placeholder="Add a comment..."
                value={value}
                disabled={props.loading}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) onSubmit();
                }}
            />
            <Button
                classes={{
                    root: classes.buttonRoot,
                }}
                text="comment"
                variant="contained"
                onClick={onSubmit}
                disabled={!value}
                loading={props.loading}
            />
        </div>
    );
};

export default withStyles(styles)(EventCommentInput);
