const styles = (theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
    title: {
        color: '#6e6e6e',
        fontSize: 18,
        fontWeight: 500,
    },
    ddd: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    list: {
        padding: 20,
    },
    listItem: {
        border: '1px solid #979797',
        borderRadius: 6,
        padding: 20,
        marginBottom: 10,
    },
    listIcon: {
        fontSize: 36,
    },
    listIconActive: {
        fontSize: 36,
        color: theme.palette.success.main,
    },
});

export default styles;
