const styles = (theme) => ({
    root: {
        flexBasis: '46%',
        margin: '1% 1%',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0 7px 25px rgba(0, 0, 0, 0.12)',
    },
    icon: {
        padding: '20px 20px 0 20px',
        maxHeight: 50,
    },
    title: {
        fontSize: 16,
    },
});

export default styles;
