import React from 'react';
// API / Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import Upload from 'utils/Upload';
// Modules
import Mixpanel from 'mixpanel-browser';
import { geolocated } from 'react-geolocated';
import { reverseGeocodeCoordinates } from 'utils/geocode';
import cloneDeep from 'lodash/cloneDeep';
import FormValidator from 'utils/FormValidator';
import checkError from 'utils/check-error';
import { ImageFileTypes, VideoFileTypes } from 'utils/file-types';
import moment from 'moment';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { Room, Schedule } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Input from 'components/Input';
import FileInput from 'components/Input/FileInput';
import Checkbox from 'components/Input/Checkbox';
import Button from 'components/Button';
// INIT
import FORM_INIT from './init';
// styles
import styles from './styles';

class ReportIncident extends React.Component {
    INIT = FORM_INIT;
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            date: {
                value: `${moment(new Date()).format('yyyy-MM-DD')}T${moment(new Date()).format('HH:mm')}`,
                valid: true,
            }, // Default date to current time
            loading: false,
            autoLocating: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar, cognitoUser } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            const invalidField = invalidFields.shift();
            return setSnackbar(checkError(invalidField.message));
        }

        this.mounted && this.setState({ loading: true });

        const formData = new FormData();
        formData.append('date', new Date(this.state.date.value).toISOString());
        formData.append('location', this.state.location.value);
        formData.append('information', this.state.information.value);
        formData.append('witnesses', this.state.witnesses.value);
        formData.append('witnessed', this.state.witnessed.value);
        if (this.state.file.value) formData.append('file', this.state.file.value);

        try {
            await Upload.post(
                `/events/incident-reports?OrganizationId=${cognitoUser.Organization.id}&EventId=${this.props.match.params.id}`,
                formData
            );
            Mixpanel.track('Incident report submitted');
            this.mounted && this.setState({ ...cloneDeep(this.INIT) });
            setSnackbar('Incident Report has been sent');
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ loading: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    async handleAutoLocation() {
        const { setSnackbar } = this.props;
        if (!this.props.isGeolocationAvailable) {
            return setSnackbar('Your browser does not support Geolocation');
        } else if (!this.props.isGeolocationEnabled) {
            return setSnackbar('Geolocation is not enabled');
        } else if (!this.props.coords) {
            return setSnackbar('Location not found');
        }

        this.setState({ autoLocating: true });
        try {
            const loc = await reverseGeocodeCoordinates(this.props.coords);
            this.updateField({ value: loc, field: 'location' });
        } catch (error) {
            console.log(error);
            setSnackbar('Location not found');
        }
        this.setState({ autoLocating: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <PageHeader title="Report an Incident" />
                <MaxWidthContainer padding>
                    <Typography variant="h5">
                        If an incident has occured at your event, please describe the incident that has occured in the
                        box below.
                        <span className={classes.alert}>
                            If this is an emergency, call 911 immediately before reporting the incident
                        </span>
                    </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
                        <Input
                            ref="date"
                            label="Date of Incident"
                            error={!this.state.date.valid}
                            errorMessage={'A date of the incident is required'}
                            validator={() => !!this.state.date.value}
                            value={this.state.date.value}
                            onChange={(e) => this.updateField({ field: 'date', value: e.target.value })}
                            variant="standard"
                            type="datetime-local"
                            fullWidth
                            marginBottom={20}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <Schedule style={{ opacity: 0.6 }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Input
                            ref="location"
                            label="Location of Incident"
                            error={!this.state.location.valid}
                            errorMessage={'Location of incident is required'}
                            validator={() => !!this.state.location.value}
                            value={this.state.location.value}
                            onChange={(e) => this.updateField({ field: 'location', value: e.target.value })}
                            variant="standard"
                            fullWidth
                            marginBottom={20}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        {this.state.autoLocating ? (
                                            <CircularProgress style={{ height: 24, width: 24 }} />
                                        ) : (
                                            <IconButton onClick={this.handleAutoLocation.bind(this)}>
                                                <Room style={{ opacity: 0.6 }} />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Input
                            ref="information"
                            label="What happened? (Please be specific)"
                            error={!this.state.information.valid}
                            errorMessage={'Please provide a description of the incident'}
                            validator={() => !!this.state.information.value}
                            value={this.state.information.value}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'information',
                                    value: e.target.value,
                                })
                            }
                            variant="standard"
                            multiline
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Checkbox
                            ref="witnessed"
                            onChange={(e) =>
                                this.updateField({
                                    field: 'witnessed',
                                    value: e.target.checked,
                                })
                            }
                            marginBottom={20}
                            checked={this.state.witnessed.value}
                            label="Did you witness the incident?"
                        />
                        <Input
                            ref="witnesses"
                            label="Who else witnessed this incident?"
                            value={this.state.witnesses.value}
                            onChange={(e) => this.updateField({ field: 'witnesses', value: e.target.value })}
                            variant="standard"
                            multiline
                            fullWidth
                            marginBottom={20}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <FileInput
                            ref="file"
                            marginBottom={18}
                            accept={[...ImageFileTypes, ...VideoFileTypes]}
                            file={this.state.file.value}
                            label="Photo of Incident (optional)"
                            onChange={(file) =>
                                this.updateField({
                                    field: 'file',
                                    value: file,
                                    expectedType: 'object',
                                })
                            }
                        />

                        <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                        <Button
                            variant={'contained'}
                            fullWidth={true}
                            variantType="containedOuterSpace"
                            text="Submit Report"
                            onClick={this.handleSubmit.bind(this)}
                            loading={this.state.loading}
                        />
                    </form>
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, { setSnackbar })(withStyles(styles)(ReportIncident)));
