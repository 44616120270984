import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Components
import CheckBox from 'components/Input/Checkbox';
// styles
import styles from './styles';

class CheckboxItem extends React.Component {
    constructor(props) {
        super(props);
        this.defaultChecked = {
            required: this.props.required,
            initialChecked: typeof this.props.value === 'boolean' ? this.props.value : this.props.initialChecked,
        };
    }

    onChange(value) {
        if (typeof this.props.onChange === 'function') this.props.onChange(value);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.title}>{this.props.title}</Typography>
                <Typography className={classes.body}>{this.props.body}</Typography>
                <CheckBox
                    id={this.props.id}
                    required={this.props.required}
                    label={this.props.helperText}
                    defaultChecked={this.defaultChecked.initialChecked || false}
                    classes={{ label: classes.label }}
                    onChange={(e) => {
                        this.onChange(e.target.checked);
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(CheckboxItem);
