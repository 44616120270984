const styles = (theme) => ({
    root: {},
    title: {
        fontSize: 18,
    },
    body: {
        opacity: 0.5,
        color: '#000000',
        fontSize: 14,
    },
    membersSearch: {
        marginTop: 20,
    },
    list: {
        height: 320,
        overflowY: 'auto',
    },
});

export default styles;
