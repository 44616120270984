import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import { INIT, SET_EVENT_INFO, GET_EVENT_INFO_SUCCESS, GET_EVENT_INFO_ERROR } from 'redux/reducers/eventInfo';

export const get = (id) => async (dispatch, getState) => {
    const user = cloneDeep(getState().cognitoUser);
    try {
        // RESPONSE { event, attendee, invites, roles }
        const response = await API.get('ClutchAPI', `/users/current/event/${id}/info`);

        if (user.id === response.event.OwnerId) response.isCheckInStaff = true;
        else
            response.roles.forEach((role) => {
                if (role.specialized === 'check-in') {
                    // User is check in volunteer
                    response.isCheckInStaff = true;
                }
            });

        dispatch({
            type: GET_EVENT_INFO_SUCCESS,
            payload: response,
        });

        return response;
    } catch (error) {
        dispatch({
            type: GET_EVENT_INFO_ERROR,
        });
        throw error;
    }
};

export const set = (payload) => {
    if (payload) {
        return {
            type: SET_EVENT_INFO,
            payload: payload,
        };
    }
    // Empty payload in store if no payload is sent
    return {
        type: SET_EVENT_INFO,
        payload: cloneDeep(INIT),
    };
};
