import React, { useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import translateAwsError from '@fyresite/translate-aws-error';

import Paper from '@material-ui/core/Paper';

// Pages
import GuestRsvp from './GuestRsvp';

import { setSnackbar } from 'redux/actions/snackbar';

import styles from './styles';

const Auth = (props) => {
    const { classes, setSnackbar } = props;

    const [challengeUser, setChallengeUser] = useState(null);

    function handleError(error) {
        let message = error.message;

        if (translateAwsError(error)) {
            message = translateAwsError(error).message;
        }

        setSnackbar(message);
    }

    const functions = { setChallengeUser, handleError };
    const variables = { challengeUser };

    // TODO: On all routes change current form with shlorm
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={5}>
                <Switch>
                    <Route
                        exact
                        path="/public/guest-rsvp/:token"
                        render={(props) => <GuestRsvp {...props} {...variables} {...functions} />}
                    />
                    <Route path="/public/*" render={() => <Redirect to="/" />} />
                </Switch>
            </Paper>
        </div>
    );
};

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(Auth));
