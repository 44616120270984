import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, Typography, Paper } from '@material-ui/core';

// styles
import styles from './styles';

const ProgressBar = ({ classes, value = 0, valueBuffer, ...props }) => {
    let isPastLimit = false;
    if (props.variant === 'buffer' && valueBuffer > 0.055) isPastLimit = true;
    else if (value > 0.055) isPastLimit = true;

    let DENOMINATOR = Math.floor(value) + 1;
    if (valueBuffer && Math.floor(value) !== Math.floor(valueBuffer)) DENOMINATOR = Math.floor(valueBuffer) + 1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography
                    align="center"
                    className={classes.percentage}
                    style={{ color: isPastLimit ? '#d35656' : 'initial' }}>
                    {props.variant === 'buffer' ? valueBuffer.toFixed(3) : value.toFixed(3)}%
                </Typography>
                <Typography align="center" className={classes.smallText}>
                    Estimated Blood Alcohol Level
                </Typography>
                <LinearProgress
                    classes={{
                        root: classes.progressRoot,
                        bar: classes.bar1Buffer,
                        bar1Buffer: classes.bar1Buffer,
                        bar2Buffer: classes.bar2Buffer,
                        dashed: classes.dashed,
                    }}
                    value={(value / DENOMINATOR) * 100}
                    valueBuffer={(valueBuffer / DENOMINATOR) * 100}
                    {...props}
                />
            </Paper>
        </div>
    );
};

export default withStyles(styles)(ProgressBar);
