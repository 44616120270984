import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';

const TimeInput = React.forwardRef(
    (
        {
            valid = true,
            errorMessage,
            validator,
            classes,
            marginBottom,
            maxLength,
            inputProps = {},
            variant = 'outlined',
            ...rest
        },
        ref
    ) => {
        let bottomMargin = marginBottom ? { marginBottom } : {};

        if (maxLength) inputProps.maxLength = maxLength;

        return (
            <KeyboardTimePicker
                inputRef={ref}
                variant={variant}
                error={!valid}
                style={bottomMargin}
                helperText={!valid && errorMessage}
                inputProps={inputProps}
                orientation="portrait"
                {...rest}
            />
        );
    }
);

export default withStyles({})(TimeInput);
