import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import { geolocated } from 'react-geolocated';
import { reverseGeocodeCoordinates } from 'utils/geocode';
import FormValidator from 'utils/FormValidator';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Contacts, UnfoldMore } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Autocomplete from 'components/Input/Autocomplete';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
import MaxWidthContainer from 'components/MaxWidthContainer';
// styles
import styles from './styles';

// TODO: Change form to Shlorm
class PersonalSafety extends React.Component {
    INIT = {
        information: { value: '', valid: true },
        requestCall: { value: true, valid: true },
        sendLocation: { value: true, valid: true },
        location: { value: '', valid: true },
    };
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            submitting: false,
        };
    }

    async handleSubmit(e) {
        const { cognitoUser, setSnackbar } = this.props;

        if (!this.state.location.value) {
            if (!this.props.isGeolocationAvailable) {
                return setSnackbar('Your browser does not support Geolocation');
            } else if (!this.props.isGeolocationEnabled) {
                return setSnackbar('Geolocation is not enabled');
            } else if (!this.props.coords) {
                return setSnackbar('Location not found');
            }
        }

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));
        if (invalidFields.length > 0) {
            const _message = invalidFields[0].message;
            return setSnackbar(_message);
        }

        this.setState({ submitting: true });
        try {
            const location = this.state.location.value || (await reverseGeocodeCoordinates(this.props.coords));
            await API.post(
                'ClutchAPI',
                `/personal/safety-alerts?OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`,
                {
                    body: {
                        information: this.state.information.value,
                        sendLocation: this.state.sendLocation.value,
                        requestCall: this.state.requestCall.value,
                        location,
                    },
                }
            );
            Mixpanel.track('Personal safety alert submitted');
            setSnackbar('Alert has been sent to your safety contacts.');
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <PageHeader title={'My Personal Safety'} />
                <MaxWidthContainer padding>
                    <Button
                        onClick={() => this.props.history.push('/safety-contacts')}
                        variant={'contained'}
                        fullWidth={true}
                        variantType="containedOuterSpace"
                        text="Safety contacts"
                        startIcon={<Contacts />}
                    />
                    <Typography className={classes.text}>
                        Please enter your emergency and then hit the submit button. Your safety contacts will be texted
                        immediately
                    </Typography>
                    <Autocomplete
                        ref="information"
                        options={['I need help', 'I feel unsafe']}
                        getOptionLabel={(option) => option}
                        popupIcon={<UnfoldMore />}
                        valid={this.state.information.valid}
                        errorMessage="Please add a description"
                        validator={() => this.state.information.value.length > 0}
                        value={this.state.information.value}
                        onChange={(e) => this.updateField({ field: 'information', value: e.target.value })}
                        inputProps={{
                            label: 'Enter Description',
                            variant: 'standard',
                            multiline: true,
                            fullWidth: true,
                            InputLabelProps: {
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            },
                        }}
                    />
                    <Checkbox
                        ref="requestCall"
                        onChange={(e) =>
                            this.updateField({
                                field: 'requestCall',
                                value: e.target.checked,
                            })
                        }
                        checked={this.state.requestCall.value}
                        label="Request contacts to call me"
                    />
                    <Checkbox
                        ref="sendLocation"
                        onChange={(e) =>
                            this.updateField({
                                field: 'sendLocation',
                                value: e.target.checked,
                            })
                        }
                        checked={this.state.sendLocation.value}
                        label="Request contacts to come and get me (we'll send your location)"
                    />
                    <Typography className={classes.subtext}>
                        (you must have location sharing turned on to use this feature)
                    </Typography>
                    <Input
                        id={this.props.id}
                        label={'or type your current location here'}
                        variant="standard"
                        fullWidth
                        marginBottom={20}
                        onChange={(e) => this.updateField({ field: 'location', value: e.target.value })}
                        value={this.state.location.value}
                        InputProps={{
                            classes: { root: classes.input },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                    <Button
                        variant={'contained'}
                        fullWidth={true}
                        variantType="containedOuterSpace"
                        text="Submit Alert"
                        onClick={this.handleSubmit.bind(this)}
                        loading={this.state.submitting}
                    />
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, { setSnackbar })(withStyles(styles)(PersonalSafety)));
