const styles = (theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
    title: {
        color: '#6e6e6e',
        fontSize: 18,
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
        opacity: 0.5,
        color: '#000000',
    },
    label: {
        fontSize: 16,
    },
});

export default styles;
