import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import { INIT, SET_RULES, GET_RULES_SUCCESS, GET_RULES_ERROR } from 'redux/reducers/rules';
import checkError from 'utils/check-error';

export const get = () => async (dispatch, getState) => {
    const { rules, cognitoUser } = cloneDeep(getState());
    dispatch(set({ ...rules, fetching: true }));
    try {
        const response = await API.get(
            'ClutchAPI',
            `/organizations/${cognitoUser.Organization.ParentId}/rules?include=media,file`
        );

        dispatch({
            payload: { ...response, fetching: false },
            type: GET_RULES_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...rules,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_RULES_ERROR,
        });
        throw error;
    }
};

export const set = (rules) => {
    if (rules) {
        return {
            type: SET_RULES,
            payload: rules,
        };
    }
    // Empty type in store if no type is sent
    return {
        type: SET_RULES,
        payload: cloneDeep(INIT),
    };
};
