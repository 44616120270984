import React from 'react';

import Mixpanel from 'mixpanel-browser';
// Mui-Core
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
// Mui-Icons
import { ArrowForwardIos } from '@material-ui/icons';

// Custom Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';

// Styles
import styles from './styles';

const EDIT_OPTIONS = [
    {
        name: 'Change Event Date',
        path: '/date',
    },
    {
        name: 'Change Event Information',
        path: '/info',
    },
];

const EditEvent = ({ classes, ...props }) => {
    return (
        <div className={classes.root}>
            <PageHeader title={'Edit Event'} />

            <MaxWidthContainer>
                <List dense className={classes.list}>
                    {EDIT_OPTIONS.map((option, index) => (
                        <ListItem
                            key={`option-${index}`}
                            button
                            className={classes.listItem}
                            onClick={(e) => {
                                Mixpanel.track(`${option.name} clicked`);
                                props.history.push(`${props.location.pathname}${option.path || ''}`);
                            }}>
                            <ListItemText primary={option.name} classes={{ primary: classes.title }} />
                            <ListItemSecondaryAction>
                                <ArrowForwardIos className={classes.icon} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(EditEvent);
