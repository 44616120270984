const styles = (theme) => ({
    root: {},
    list: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    listTitle: {
        fontSize: 18,
        fontWeight: 600,
    },
    secondaryAction: {
        right: 0,
        minWidth: 'unset',
        [theme.breakpoints.down(376)]: {
            right: -15,
        },
    },
    green: {
        color: '#fff',
        backgroundColor: theme.palette.success.main,
    },
    red: {
        color: '#fff',
        backgroundColor: theme.palette.error.main,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'blue',
        alignSelf: 'flex-end',
        marginRight: 25,
        marginBottom: 8,
        [theme.breakpoints.down(376)]: {
            marginRight: 0,
        },
    },
});

export default styles;
