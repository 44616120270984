import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// styles
import styles from './styles';

const Title = ({ classes, ...props }) => {
    return <Typography className={classes.root}>{props.title}</Typography>;
};

export default withStyles(styles)(Title);
