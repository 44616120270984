const styles = (theme) => ({
    root: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    pageHeader: {
        paddingBottom: 135,
    },
    progressBar: {
        marginTop: -125,
    },
    description: {
        margin: '20px 0 11px 0',
    },
    boldText: {
        fontWeight: 'bold',
    },
    recommendations: {
        marginBottom: 31,
    },
});

export default styles;
