import React, { useState, useEffect } from 'react';

// Modules
import Mixpanel from 'mixpanel-browser';
import { isBrowser } from 'react-device-detect';

// Redux
import { get as getGuestList } from 'redux/actions/attendees';
import { get as getEventInfo } from 'redux/actions/eventInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Components
import ExportPDF from 'components/ExportPDF';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import List from 'components/List';
import Button from 'components/Button';

// styles
import styles from './styles';

const GuestList = ({ match, classes, ...props }) => {
    const [, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { attendees, eventInfo } = useSelector(({ attendees, eventInfo }) => ({
        attendees,
        eventInfo,
    }));

    useEffect(() => {
        _getGuestList(match.params.id);

        if (!Object.keys(eventInfo.event).length) {
            dispatch(getEventInfo(match.params.id));
        }
    }, []);

    const _getGuestList = async (eventId) => {
        setLoading(true);
        try {
            await dispatch(getGuestList(eventId));
        } catch (error) {
            dispatch(setSnackbar('There was an error obtaining the user information'));
        }
        setLoading(false);
    };

    const getRsvpCount = (rsvp) => {
        let count = rsvp.length;

        rsvp.forEach((r) => {
            r.guests.forEach((g) => {
                if (g.rsvp === 'yes') count++;
            });
        });

        return count;
    };

    function handleClick() {
        ExportPDF(attendees.data, eventInfo.event.information);
        Mixpanel.track('PDF Exported', { EventId: match.params.id });
    }

    const rsvp = [];
    const notRsvp = [];

    if (attendees.data) {
        attendees.data
            .filter((a) => !a.InviterId)
            .forEach((a) => {
                if (a.rsvp === 'yes') rsvp.push(a);
                else notRsvp.push(a);
            });
    }

    return attendees.data ? (
        <div className={classes.root}>
            <PageHeader
                title={`Guest List (${getRsvpCount(rsvp)} of ${attendees.data ? attendees.data.length : 0} RSVP)`}
            />
            <MaxWidthContainer padding classes={{ root: classes.container }}>
                <>
                    {isBrowser && (
                        <Button
                            classes={{ root: classes.exportButtonRoot }}
                            onClick={handleClick}
                            variant={'outlined'}
                            variantType="outlinedOuterSpace"
                            fullWidth>
                            Export
                        </Button>
                    )}
                    <Typography variant="h3">RSVP'd</Typography>
                    <div className={classes.list}>
                        {attendees
                            ? rsvp.map((attendee, index) => (
                                  <List
                                      key={`guestList-${index}`}
                                      listItemCount={attendee.guests.length}
                                      listTitle={attendee.User.lastName + ', ' + attendee.User.firstName}
                                      listItemData={attendee.guests}
                                      muiListItemProps={{
                                          divider: true,
                                          disableGutters: true,
                                      }}
                                  />
                              ))
                            : null}
                    </div>
                    <Typography variant="h3">Not RSVP'd</Typography>
                    <div className={classes.notRsvpd}>
                        {attendees
                            ? notRsvp.map((attendee, index) => (
                                  <List
                                      key={`guestList-not-${index}`}
                                      listItemCount={attendee.guests.length}
                                      listTitle={attendee.User.lastName + ', ' + attendee.User.firstName}
                                      listItemData={attendee.guests}
                                      muiListItemProps={{
                                          divider: true,
                                          disableGutters: true,
                                      }}
                                  />
                              ))
                            : null}
                    </div>
                </>
            </MaxWidthContainer>
        </div>
    ) : null;
};

export default withStyles(styles)(GuestList);
