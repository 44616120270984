import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Styles
import styles from './styles';

const MenuSquare = ({ active, text, icon, style, classes, ...props }) => {
    /** Legacy left behind from Andrew */
    const styles = Object.assign({}, style, {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        backgroundImage: active ? 'linear-gradient(-186deg, #B4EC51 0%, #429321 100%)' : '',
    });

    return (
        <Paper style={styles} className={classes.root} {...props}>
            {icon ? <img className={classes.icon} src={icon} alt={text} /> : null}

            <div
                className={classes.title}
                style={{
                    color: active ? 'white' : 'var(--gray-text)',
                    padding: icon ? '12px 20px 20px 20px' : '20px',
                }}>
                {text}
            </div>
        </Paper>
    );
};

export default withStyles(styles)(MenuSquare);
