import moment from 'moment';

const init = {
    title: { value: '', valid: true },
    theme: { value: '', valid: true },
    maxGuestAllowed: { value: 0, valid: true },
    file: { value: '', valid: true },
    start: { value: moment().hour(12).minute(0).second(0), valid: true },
    timezone: { value: 'Eastern', valid: true },
    end: { value: moment().hour(12).minute(0).second(0), valid: true },
    description: { value: '', valid: true },
    venue: { value: '', valid: true },
    venuePhone: { value: '', valid: true },
    location: { value: '', valid: true },
    url: { value: '', valid: true },
};

export default init;
