import React from 'react';
import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Select from '../../../Input/Select';

import { TIMEZONE_MAP } from 'global-constants';

import styles from './styles';

class TimezoneInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        };
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChange(e.target.value);
    };

    render() {
        const { classes, error, errorMessage, validator, children, marginBottom, InputLabelProps } = this.props;

        return (
            <Select
                fullWidth
                label="Timezone*"
                errorMessage="Timezone is required"
                value={this.state.value}
                onChange={this.onChange}
                InputLabelProps={{
                    classes: { root: classes.inputLabel },
                }}>
                {Object.keys(TIMEZONE_MAP).map((key) => (
                    <MenuItem key={key} value={key}>
                        {TIMEZONE_MAP[key].label}
                    </MenuItem>
                ))}
            </Select>
        );
    }
}

export default withStyles(styles)(TimezoneInput);
