const styles = (theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0px 8px 18px',
    },
    title: {
        fontSize: 20,
        color: theme.palette.primary.contrastText,
        padding: '8px 0px 8px 18px',
    },

    createEvent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        padding: '12px 12px 12px 18px',
        boxShadow: '0 5px 24px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        background: 'white',
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        '& span': {
            paddingRight: 20,
        },
    },
});

export default styles;
