import { Home, CalendarToday, Contacts, Message, FolderSpecial, List } from '@material-ui/icons';
import { policyIdentifiers } from 'global-constants';

const MenuItems = [
    {
        title: 'Home',
        path: '/',
        policy: policyIdentifiers.BASIC_POLICIES,
        Icon: Home,
    },
    {
        title: 'Events',
        path: '/events',
        policy: policyIdentifiers.BASIC_POLICIES,
        Icon: CalendarToday,
    },
    {
        title: 'Safety Contacts',
        path: '/safety-contacts',
        policy: policyIdentifiers.PERSONAL_SAFETY_ALERTS,
        Icon: Contacts,
    },
    {
        title: 'Send Safety Alert',
        path: '/safety-alerts/create',
        policy: policyIdentifiers.SEND_CHAPTER_SAFETY_ALERT_MEMBER,
        Icon: Message,
    },
    {
        title: 'House Rules',
        path: '/rules',
        policy: policyIdentifiers.HOUSE_RULES,
        Icon: List,
    },
    {
        title: 'Education',
        path: '/education',
        policy: policyIdentifiers.EDUCATION_RESOURCES,
        Icon: FolderSpecial,
    },
];

export default MenuItems;
