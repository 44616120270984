function FormValidator(refs, updateField) {
    let invalid = [];

    Object.keys(refs).forEach((key) => {
        if (refs[key].props && refs[key].props.validator) {
            let valid = refs[key].props.validator();

            if (updateField) updateField({ field: key, valid });
            if (!valid)
                invalid.push({
                    field: key,
                    message:
                        typeof refs[key].props.errorMessage === 'function'
                            ? refs[key].props.errorMessage()
                            : refs[key].props.errorMessage,
                    ref: refs[key],
                });
        }
    });

    return invalid;
}

export default FormValidator;
