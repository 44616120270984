import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { history } from './redux/config';

import Snackbar from './components/Snackbar';
import Toast from './components/Toast';
import Authorizer from './Authorizer';
import Public from './containers/Public';

const _Router = (props) => {
    return (
        <React.Fragment>
            <Snackbar />
            <Toast />

            <Router history={history}>
                <Switch>
                    {/* TODO: Change with authorizer once we have authentication */}
                    <Route path="/public" component={Public} />
                    <Route path="/" component={Authorizer} />
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default _Router;
