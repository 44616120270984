const styles = (theme) => ({
    root: {
        flex: 1,
    },
    alertRoot: {
        position: 'absolute',
        top: -23,
        right: 0,
        left: 0,
        margin: '0 25px',
    },
    buttons: {
        display: 'flex',
        marginBottom: 20,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    inputLabel: {
        textDecoration: 'underline',
        color: '#4a4a4a',
    },
    formHelperText: {
        color: '#4a4a4a',
        marginTop: 10,
    },
    safeButton: {
        marginRight: 11,
    },
    submitButton: {
        marginBottom: 20,
    },
});

export default styles;
