import React from 'react';
import { withRouter } from 'react-router-dom';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, LinearProgress, useMediaQuery } from '@material-ui/core';
import { Home, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import clsx from 'classnames';
// Components
import Button from 'components/Button';
// Styles
import styles from './styles';

const EventStepper = ({ classes, leftButton, rightButton, progress, ...props }) => {
    const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            <Grid container className={classes.head}>
                <Grid
                    item
                    xs={5}
                    sm={4}
                    className={clsx(classes.center, {
                        [classes.left]: matches,
                    })}>
                    {leftButton && (
                        <Button
                            classes={{
                                startIcon: classes.startIcon,
                                text: classes.text,
                            }}
                            startIcon={<ArrowBackIos />}
                            {...leftButton}
                        />
                    )}
                </Grid>
                <Grid item xs={2} sm={4} className={classes.center}>
                    <IconButton onClick={() => props.history.push(`/event/edit/${props.match.params.id}`)}>
                        <Home className={classes.home} />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={5}
                    sm={4}
                    className={clsx(classes.center, {
                        [classes.right]: matches,
                    })}>
                    {rightButton && (
                        <Button classes={{ text: classes.text }} endIcon={<ArrowForwardIos />} {...rightButton} />
                    )}
                </Grid>
            </Grid>
            <LinearProgress
                classes={{ root: classes.progress, bar: classes.progressBar }}
                variant="determinate"
                value={progress}
            />
        </div>
    );
};

export default withRouter(withStyles(styles)(EventStepper));
