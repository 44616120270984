// Libraries
import queryString from 'query-string';

// AWS
import { API } from 'aws-amplify';

// Redux
import { store } from 'redux/config';
import { set as setOrg } from 'redux/actions/organization';
import { setTheme } from 'redux/actions/theme';

// Utils
import S3MediaOperations from 'utils/S3MediaOperations';

const getOrgSettings = async () => {
    const { cognitoUser } = store.getState();

    const organizationId = cognitoUser.Organization.ParentId;

    const slug = [
        'backgroundColor',
        'daysBeforeEventCreate',
        'eventDateChangeTimeLimit',
        'eventPhoto',
        'logo',
        'logoDisplaySize',
        'maxGuestAllowed',
        'textColor',
    ];
    const query = {
        where: JSON.stringify({ slug }),
        perPage: slug.length,
    };

    try {
        const settings = await API.get(
            'ClutchAPI',
            `/organizations/${organizationId}/settings?${queryString.stringify(query)}`
        );

        const theme = {
            palette: {
                primary: {
                    main: settings.data.backgroundColor.value,
                    contrastText: settings.data.textColor.value,
                },
            },
            custom: {},
        };

        if (settings?.data?.logo?.value.key && settings?.data?.logo?.value.bucket) {
            theme.custom.navigation = {
                logo: await S3MediaOperations.getDocumentUrl(settings.data.logo.value),
                logoSize: settings?.data?.logoDisplaySize?.value ?? 'default',
            };
        }
        if (settings?.data?.eventPhoto?.value.key && settings?.data?.eventPhoto?.value.bucket) {
            theme.custom.event = {
                photo: await S3MediaOperations.getDocumentUrl(settings.data.eventPhoto.value),
            };
        }

        store.dispatch(setTheme(theme));

        const organization = {
            daysBeforeEventCreate: parseInt(settings.data.daysBeforeEventCreate.value, 10),
            eventDateChangeTimeLimit: parseInt(settings.data.eventDateChangeTimeLimit.value, 10),
            maxGuestAllowed: parseInt(settings.data.maxGuestAllowed.value, 10),
        };

        store.dispatch(setOrg(organization));
    } catch (err) {
        console.log(err);
        throw new Error(err);
    }
};

if (process.env.NODE_ENV !== 'production') {
    window.getOrgSettings = () => getOrgSettings();
}

export default getOrgSettings;
