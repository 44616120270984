import React, { useState, useEffect } from 'react';
// Modules
import { API } from 'aws-amplify';
import clsx from 'classnames';
import Mixpanel from 'mixpanel-browser';
// Redux
import { get as getEventInfo } from 'redux/actions/eventInfo';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// components
import MenuSquare from 'components/MenuSquare';
import Input from 'components/Input';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';
// Icons
import CheckInSvg from 'assets/icons/CheckIn.svg';
import CheckInGoldSvg from 'assets/icons/CheckInGold.svg';
// styles
import styles from './styles';

const CancelEvent = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        async function _getEventInfo() {
            try {
                const response = await dispatch(getEventInfo(props.match.params.id));
                if (!response.isCheckInStaff) throw new Error('You are not a check in staff for this event.');
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.goBack();
            }
        }
        _getEventInfo();
    }, []);

    async function checkin(qrCode) {
        setLoading(true);
        try {
            if (!qrCode) throw new Error('Please fill in a guest code');
            const attendee = await API.patch(
                'ClutchAPI',
                `/events/${props.match.params.id}/attendees/${qrCode}/check-in`
            );
            Mixpanel.track('QR code filled in manually');
            dispatch(setSnackbar(`${attendee.User.firstName} ${attendee.User.lastName} has been checked in`));
            setCode('');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        setLoading(false);
    }

    async function postMessageToPhone(action, args) {
        if (window.Flutter && typeof window.Flutter.postMessage === 'function') {
            Mixpanel.track('QR Scanner started');
            let data = { action };
            data.args = args || {};
            window.Flutter.postMessage(JSON.stringify(data));
        }
    }

    return (
        <div className={classes.root}>
            <PageHeader title="Guest Check In" />
            <MaxWidthContainer padding classes={{ root: classes.container }}>
                <div className={classes.controls}>
                    <MenuSquare
                        classes={{ root: clsx(classes.menuSquare, classes.mb15) }}
                        icon={CheckInSvg}
                        text={
                            <span>
                                Scan QR Code & Add Another
                                <br />
                                (Must be on mobile device)
                            </span>
                        }
                        onClick={() => postMessageToPhone('scanner', { EventId: props.match.params.id })}
                    />
                    <Input
                        label="OR Enter Guest Code"
                        value={code}
                        fullWidth
                        variant="standard"
                        onChange={(e) => setCode(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                            classes: { root: classes.inputLabel },
                        }}
                    />
                </div>
                <BottomButtons
                    leftButton={{
                        text: 'Check-in + Add Another',
                        loading,
                        onClick: () => checkin(code),
                    }}
                    rightButton={{
                        style: { display: 'none' },
                    }}
                    classes={{ root: classes.bottom }}
                />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(CancelEvent);
