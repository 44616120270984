import React, { useEffect, useState } from 'react';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { remove as removeContact, get as getContacts } from 'redux/actions/contacts';
import { setSnackbar } from 'redux/actions/snackbar';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
// components
import Autocomplete from 'components/Input/Autocomplete';
import Skeleton from 'components/Skeleton';
// styles
import styles from './styles';

const Contacts = ({ classes, ...props }) => {
    const { contacts } = useSelector(({ contacts }) => ({ contacts }));
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchedContacts, setSearchedContacts] = useState([]);

    useEffect(() => {
        async function _getContacts() {
            try {
                await dispatch(getContacts());
            } catch (error) {
                setSnackbar(checkError(error));
            }
        }
        _getContacts();
    }, [dispatch]);

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        mounted.current &&
            setSearchedContacts(
                contacts.data
                    .map((contact) => {
                        if (contact.name.toLowerCase().includes(search.toLowerCase())) return contact;
                        return null;
                    })
                    .filter((value) => value)
            );
    }, [search, contacts.data]);

    async function handleRemove(contact) {
        const { onSuccess, onError } = props;
        try {
            await dispatch(removeContact(contact));
            onSuccess('Contact removed');
            Mixpanel.track('Contact removed');
        } catch (error) {
            onError(error);
        }
    }

    return (
        <div className={classes.contacts}>
            <Autocomplete
                options={contacts.data.map((contact) => contact.name)}
                getOptionLabel={(option) => option}
                classes={{ popupIndicatorOpen: classes.searchOpen }}
                popupIcon={<Search />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                inputProps={{
                    label: 'Search For A Contact',
                    variant: 'standard',
                    fullWidth: true,
                    InputLabelProps: {
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    },
                }}
            />
            <List dense className={classes.list}>
                {(contacts.fetching ? Array.from(new Array(4)) : search ? searchedContacts : contacts.data).map(
                    (contact, index) => {
                        return !contact ? (
                            <Skeleton classes={{ root: classes.listItem }} key={`skele-${index}`} type="listItem" />
                        ) : (
                            <React.Fragment key={`contact-${contact.id}`}>
                                <ListItem classes={{ root: classes.listItem }}>
                                    <ListItemText primary={contact.name} secondary={contact.phone} />
                                    <ListItemSecondaryAction classes={{ root: classes.listItemAction }}>
                                        <IconButton onClick={() => handleRemove(contact)}>
                                            <Close />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {index !== contacts.data.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    }
                )}
            </List>
        </div>
    );
};

export default withStyles(styles)(Contacts);
