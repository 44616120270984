import React, { useEffect, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import checkError from 'utils/check-error';

// Redux
import { clearTheme } from 'redux/actions/theme';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import Paper from '@material-ui/core/Paper';

// Pages
import SsoLogin from './SsoLogin';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';
import ResetPassword from './ResetPassword';
import ClaimAccount from './ClaimAccount';
import LoginHeader from 'components/LoginHeader';
import Logo from 'assets/ClutchWhite.svg';

import styles from './styles';
import SsoLauncher from './SsoLauncher/SsoLauncher';

const Auth = (props) => {
    const { classes, clearTheme, setSnackbar, history } = props;

    const [challengeUser, setChallengeUser] = useState(null);
    const pathName = history.location.pathname;
    useEffect(() => {
        clearTheme();
    }, []);
    function handleError(error) {
        setSnackbar(checkError(error));
    }

    const functions = { setChallengeUser, handleError, setSnackbar };
    const variables = { challengeUser };

    // TODO: On all routes change current form with shlorm
    return (
        <div className={classes.body}>
            <div className={classes.root}>
                <LoginHeader
                    classes={{ header: classes.topbar }}
                    logo={Logo}
                    onClick={(e) => history.push('/')}
                    title={pathName === '/' ? 'Select Your Organization' : ''}
                />
                <section className={classes.pageSection}>
                    <Paper className={classes.paper} elevation={5}>
                        <Switch>
                            <Route exact path="/sso/:integrationId" render={(props) => <SsoLauncher {...props} />} />
                            <Route
                                exact
                                path="/new-password"
                                render={(props) => <NewPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/forgot-password"
                                render={(props) => <ForgotPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/claim-account"
                                render={(props) => <ClaimAccount {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/reset-password"
                                render={(props) => <ResetPassword {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/"
                                render={(props) => <SsoLogin {...props} {...variables} {...functions} />}
                            />
                            <Route
                                exact
                                path="/login"
                                render={(props) => <Login {...props} {...variables} {...functions} />}
                            />
                            <Route path="/*" render={() => <Redirect to="/" />} />
                        </Switch>
                    </Paper>
                </section>
            </div>
        </div>
    );
};

const actions = { clearTheme, setSnackbar };

export default connect(null, actions)(withStyles(styles)(Auth));
