export const SET_BEVERAGE_EDUCATION = 'SET_BEVERAGE_EDUCATION';
export const INIT = {
    weight: 150.0,
    gender: 'male',
    totalBac: 0,
    drinks: [],
    accepted: false,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_BEVERAGE_EDUCATION:
            return { ...state, ...payload };
        default:
            return state;
    }
}
