const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    container: {
        margin: 'auto',
        padding: 20,
        maxWidth: 600,
    },
});

export default styles;
