import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { get as getCognitoUser } from 'redux/actions/cognitoUser';
// modules
import { Auth } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import FormValidator from 'utils/FormValidator';
import CustomValidators from 'utils/CustomValidators';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import AuthForm from 'components/AuthForm';
import AuthHeader from 'components/AuthHeader';
import Button from 'components/Button';
import Input from 'components/Input';

// Utils
import { updateLastLoginDate } from 'utils/updateLastLoginDate';

// Styles
import styles from './styles';

class NewPassword extends Component {
    INIT = {
        password: { value: '', valid: true },
        passwordMatch: { value: '', valid: true },
    };
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            loading: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleNewPassword(e) {
        e.preventDefault();
        const { handleError, challengeUser, getCognitoUser } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));

        if (invalidFields.length > 0) {
            return handleError(invalidFields[0].message);
        }

        this.mounted && this.setState({ loading: true });
        try {
            await Auth.cleanCachedItems();
            await Auth.completeNewPassword(challengeUser, this.state.password.value);

            const user = await getCognitoUser();
            updateLastLoginDate(user.id);
            this.props.history.push('');
        } catch (error) {
            handleError(error);
        }
        this.mounted && this.setState({ loading: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    render() {
        const { loading } = this.state;
        const { classes } = this.props;

        return (
            <AuthForm className={classes.authForm} onSubmit={this.handleNewPassword.bind(this)}>
                <Input
                    id="password"
                    label="Password"
                    type="password"
                    ref="password"
                    fullWidth={true}
                    marginBottom={12}
                    value={this.state.password.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'password',
                            value: e.target.value,
                        })
                    }
                    validator={() => CustomValidators.testPassword(this.state.password.value)}
                    error={!this.state.password.valid}
                    errorMessage="Please provide a valid password"
                />
                <Input
                    id="password-match"
                    label="Confirm Password"
                    type="password"
                    ref="passwordMatch"
                    fullWidth={true}
                    marginBottom={12}
                    value={this.state.passwordMatch.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'passwordMatch',
                            value: e.target.value,
                        })
                    }
                    validator={() => this.state.password.value === this.state.passwordMatch.value}
                    error={!this.state.passwordMatch.valid}
                    errorMessage="Passwords do not match"
                />

                <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

                <Button
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    fullWidth={true}
                    onClick={this.handleNewPassword.bind(this)}
                    size="large"
                    text={'Set Password'}
                    variant="contained"
                />
            </AuthForm>
        );
    }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {
    getCognitoUser,
})(withStyles(styles)(NewPassword));
