import React, { useEffect, useState } from 'react';

// Module
import { API } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';

// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';

// styles
import styles from './styles';

const CancelRsvp = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const attendee = props.location.state.attendee;
    useEffect(() => {
        if (!attendee) props.history.goBack();
    }, [attendee, props.history]);

    async function handleCancelRsvp() {
        setLoading(true);

        try {
            await API.del('ClutchAPI', `/events/${props.match.params.id}/attendees/${attendee.id}/rsvp`);
            dispatch(setSnackbar(`RSVP successfully cancelled.`));
            Mixpanel.track('RSVP Canceled');
            props.history.replace(`/event-view/${props.match.params.id}`);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
            setLoading(false);
        }
    }

    return (
        <div className={classes.root}>
            <PageHeader title="Cancel RSVP" />
            <MaxWidthContainer padding>
                <Typography
                    align="center"
                    classes={{
                        root: classes.text,
                        gutterBottom: classes.gutterBottom,
                    }}>
                    Are you sure you want to cancel your RSVP?
                </Typography>
                <BottomButtons
                    leftButton={{
                        text: 'No',
                        disabled: loading,
                        onClick: props.history.goBack,
                    }}
                    rightButton={{
                        text: 'Yes',
                        loading,
                        onClick: handleCancelRsvp,
                    }}
                    classes={{ root: classes.bottom }}
                />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(CancelRsvp);
