import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setToast } from 'redux/actions/toast';
// Material UI
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

const Toast = ({ message, severity, setToast }) => {
    function handleClose(event, reason) {
        // do not close on click away
        if (reason === 'clickaway') return;

        setToast({ message: '' });
    }

    let open = true;

    if (message === '') open = false;

    return (
        <Snackbar
            style={{ marginTop: 20 }}
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={AUTO_HIDE_TIME}
            onClose={handleClose}>
            <Alert
                style={{ borderRadius: 25, flex: 1, margin: '0 12px' }}
                elevation={6}
                variant="filled"
                severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

const mapStateToProps = ({ toast }) => ({
    ...toast,
});
export default connect(mapStateToProps, { setToast })(Toast);
