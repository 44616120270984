import dayjs from 'dayjs';

const ALCOHOL_DECAY_PER_HOUR = 0.015;
const ALCOHOL_TO_BAC_CONVERSION = 5.14;
const ALCOHOL_DIST_RATIO_MALE = 0.73;
const ALCOHOL_DIST_RATIO_FEMALE = 0.66;

function calculateBACLevel(weight, gender, drinks) {
    let totalHours = 0;

    // get the total hours drinking from the first drink
    if (drinks.length > 0) totalHours = dayjs().diff(dayjs(drinks[0].timestamp), 'hour', true);

    let totalAlchohol = 0;
    drinks.forEach((drink) => {
        totalAlchohol += (drink.abv / 100) * drink.vol;
    });

    const genderRatio = gender === 'male' ? ALCOHOL_DIST_RATIO_MALE : ALCOHOL_DIST_RATIO_FEMALE;

    const totalBAC =
        (totalAlchohol * ALCOHOL_TO_BAC_CONVERSION) / (weight * genderRatio) - ALCOHOL_DECAY_PER_HOUR * totalHours;

    // dont return a negative BAC level
    if (totalBAC < 0) return 0;

    return totalBAC;
}

function calculateBACLevelGreedy(weight, gender, drinks) {
    let highestBAC = 0;

    drinks.forEach((drink, index) => {
        let totalBAC = calculateBACLevel(weight, gender, drinks);

        if (totalBAC > 0 && totalBAC > highestBAC) {
            highestBAC = totalBAC;
        }

        drinks = drinks.slice(1);
    });

    return highestBAC;
}

export default calculateBACLevelGreedy;
