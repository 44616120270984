const colorManipulator = require('utils/style-utils');
const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid rgba(151, 151, 151, 0.4)',
        borderRadius: 6,
        alignItems: 'center',
        overflow: 'hidden',
    },
    stepTitle: {
        padding: '8px 0px 10px 19px',
        flexGrow: 1,
        cursor: 'pointer',
    },
    title: {
        marginBottom: 3,
    },
    icon: {
        minWidth: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        alignSelf: 'stretch',
    },
    iconStacked: {
        minWidth: 40,
    },

    commentButtonRoot: {
        borderRadius: '0 6px 6px 0',
        minWidth: 35,
        maxWidth: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        alignSelf: 'stretch',
        zIndex: 1,
        marginRight: -5,
        cursor: 'pointer',
        background: '#efefef',
        '&.Mui-disabled': {
            background: '#efefef',
            '& svg': {
                color: '#cccccc',
            },
        },
        '&:hover': {
            background: colorManipulator.darken('rgb(245, 245, 245)', 0.2),
        },
    },

    chatIcon: {
        color: '#535b6f',
    },
});

export default styles;
