import { CLEAR_THEME, SET_THEME } from 'redux/actions/theme';

import defaultTheme from 'theme';

export const INIT = defaultTheme;

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_THEME:
            return payload;
        case CLEAR_THEME:
            return INIT;
        default:
            return state;
    }
}
