import React from 'react';

// Modules
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { awsConfig } from 'aws/aws_resource';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';
import DateInput from 'components/Input/Date';
import RuleWrapper from './RuleWrapper';

// styles
import styles from './styles';

class GuestRsvp extends React.Component {
    INIT = { birthDate: { value: '', valid: true } };
    state = {
        submitting: false,
        rules: [],
        guest: {},
        event: {},
        ...cloneDeep(this.INIT),
    };

    async componentDidMount() {
        this.mounted = true;
        try {
            const response = await axios.get(`${awsConfig.APIV1Endpoint}/guests/${this.props.match.params.token}`);
            this.setState(response.data);
            this.updateField({
                field: 'birthDate',
                value: moment.utc(response.data.guest.User.birthDate).format('yyyy-MM-DD'),
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { setSnackbar } = this.props;
        const { birthDate } = this.state;

        const date = moment(birthDate.value);

        if (!date.isValid()) {
            setSnackbar('Please enter your birthdate');
            this.refs.birthDate.focus();
            return;
        }

        const years = moment().diff(moment(this.state.birthDate.value), 'years');

        if (years < 18) {
            setSnackbar('You must be 18 or older to RSVP');
            return;
        }

        this.setState({ submitting: true });
        try {
            await axios.patch(`${awsConfig.APIV1Endpoint}/guests/${this.props.match.params.token}`, {
                response: 'yes',
                dateOfBirth: date.toISOString(),
            });
            setSnackbar('Your RSVP has been sent');
        } catch (error) {
            setSnackbar(checkError(error, 'error'));
        }
        this.setState({ submitting: false });
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        // if (value !== null && value !== undefined) {
        //     if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
        form[field].value = value;
        //     }
        // }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { rules, submitting } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title="House Rules" />
                <form onSubmit={this.handleSubmit}>
                    <div className={classes.greyArea}>
                        <MaxWidthContainer padding>
                            <Typography variant="h3" align="center" className={classes.title}>
                                Welcome{`${this.state.guest.User?.birthDate ? ' back' : ''},`}{' '}
                                {this.state.guest.User?.firstName}!
                            </Typography>
                            <Typography align="center" className={classes.helperText}>
                                Please enter your date of birth.
                            </Typography>
                            <DateInput
                                ref="birthDate"
                                id="birth-date-picker-dialog"
                                placeholder="Enter your birthdate"
                                format="MM/DD/yyyy"
                                error={!this.state.birthDate.valid}
                                errorMessage="Date of birth required, and you must be older then 18 to RSVP"
                                validator={() => {
                                    console.log('test');
                                }}
                                value={this.state.birthDate.value}
                                onChange={(date) => {
                                    this.updateField({ field: 'birthDate', value: date });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change birth date',
                                }}
                                fullWidth
                                marginBottom={18}
                                InputLabelProps={{
                                    classes: { root: classes.inputLabel },
                                }}
                            />
                        </MaxWidthContainer>
                    </div>
                    <MaxWidthContainer padding>
                        <div className={classes.rules}>
                            {rules.map((rule) => (
                                <RuleWrapper key={rule.id} rule={rule} />
                            ))}
                        </div>
                        <Button
                            variant="contained"
                            variantType="containedOuterSpace"
                            color="primary"
                            type="submit"
                            fullWidth={true}
                            text="Submit & Agree"
                            loading={submitting}
                            onClick={this.handleSubmit}
                        />
                    </MaxWidthContainer>
                </form>
            </div>
        );
    }
}

export default connect(null, { setSnackbar })(withStyles(styles)(GuestRsvp));
