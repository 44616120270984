import { colors } from 'global-styles';

const styles = (theme) => ({
    root: {
        display: 'flex',
        padding: '12px 0px',
        flexDirection: 'row',
        alignItems: 'center',
    },
    border: {
        borderBottom: '1px solid #979797',
    },
    formControl: {
        width: '100%',
        marginLeft: 0,
    },
    label: {
        flexGrow: 1,
        opacity: 0.8,
        color: '#000000',
        fontSize: 14,
        fontWeight: 400,
    },
    checkBox: {
        flexShrink: 1,
    },
    icon: {
        fontSize: 36,
    },
    iconActive: {
        fontSize: 36,
        color: colors.successGreen,
    },
});

export default styles;
