const styles = (theme) => ({
    root: {
        flex: 1,
    },
    greyArea: {
        backgroundColor: '#eeeeee',
        padding: '30px 20px',
        color: '#222222',
    },
    input: {
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.5)',
        backgroundColor: '#ffffff',
        opacity: 0.5,
        color: '#222222',
    },
    inputLabel: {
        textDecoration: 'underline',
    },
    inputRoot: {
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '20px 11px',
        marginLeft: 50,
    },
    inputAdornment: {
        position: 'absolute',
        right: 0,
    },
    title: {
        marginBottom: 5,
    },
    helperText: {
        fontSize: 14,
        marginBottom: 16,
    },
});

export default styles;
