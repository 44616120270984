const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    icon: {
        fontSize: 16,
    },
    title: {
        color: '#4a4a4a',
        fontSize: 16,
    },
});
export default styles;
