import { API } from 'aws-amplify';
import moment from 'moment';

export const updateLastLoginDate = async (id) => {
    try {
        const res = await API.patch('ClutchAPI', `/users/${id}`, {
            body: {
                lastLoginDate: moment().utc().toISOString(),
            },
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};
