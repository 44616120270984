import { markupInputTypes } from 'global-constants';

function stepState(event) {
    if (!event) return;

    const stepState = [];

    event.EventModules.forEach((eModule) => {
        stepState.push({
            ...eModule,
            reviews: {
                final: {
                    sectionsWaitingApproval: 0,
                    sectionsApproved: 0,
                    sectionsUnapproved: 0,
                },
                required: {
                    sectionsWaitingApproval: 0,
                    sectionsApproved: 0,
                    sectionsUnapproved: 0,
                },
            },
            comments: [],
            reviewSctions: {},
        });

        const index = stepState.length - 1;

        const sectionsThatHaveReviewingStuff = [];
        eModule.sections.forEach((section) => {
            // First remove non-reviewed markups inside sections for step
            let hasMarkupInput = false;
            section.markup.forEach((markup) => {
                if (markupInputTypes.includes(markup.type)) hasMarkupInput = true;
            });

            if (hasMarkupInput) sectionsThatHaveReviewingStuff.push(section);
        });

        console.log('sectionsThatHaveReviewStuff', sectionsThatHaveReviewingStuff);

        sectionsThatHaveReviewingStuff.forEach((section) => {
            stepState[index].reviewSctions[section.id] = {
                id: section.id,
                values: section.values || {},
                markup: [],
            };

            if (!section.review) {
                stepState[index].reviews.final.sectionsWaitingApproval += 1;
                stepState[index].reviews.required.sectionsWaitingApproval += 1;
            } else {
                let reviewedByFinalApprover = false;
                let reviewedByRequiredApprover = false;

                // Track review status on all sections in
                // a step for Required Reviewers & Final Reviewers
                Object.values(section.review.approvals).forEach((approval) => {
                    if (approval.isFinalApprover) {
                        // Track & Update Final Reviews
                        reviewedByFinalApprover = true;
                        approval.approved
                            ? (stepState[index].reviews.final.sectionsApproved += 1)
                            : (stepState[index].reviews.final.sectionsUnapproved += 1);
                    } else {
                        // Track & Update Required Reviews

                        reviewedByRequiredApprover = true;
                        approval.approved
                            ? (stepState[index].reviews.required.sectionsApproved += 1)
                            : (stepState[index].reviews.required.sectionsUnapproved += 1);
                    }
                });

                // Update only sectionsWaitingApproval count based on the type of reviewer
                if (!reviewedByFinalApprover) stepState[index].reviews.final.sectionsWaitingApproval += 1;
                if (!reviewedByRequiredApprover) stepState[index].reviews.required.sectionsWaitingApproval += 1;
            }

            // Add all the comments from each section inside the step
            if (section.review) {
                if (section.review.comments.length > 0) {
                    section.review.comments.forEach((comment) => {
                        stepState[index].comments.push(comment);
                    });
                }
            }
        });

        // Only show steps that can be reviewed once submitted
        if (event.reviewStatus === 'submitted') {
            if (Object.keys(stepState[index].reviewSctions).length === 0) {
                stepState.splice(index, 1);
            }
        }
    });
    // console.log("EMod Sections", event.EventModules);
    console.log('STEP STATE', stepState);
    return stepState;
}

export default stepState;
