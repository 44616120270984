import React, { useState } from 'react';
// Modules
import Mixpanel from 'mixpanel-browser';
// Redux
import { API } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';
// Hooks
import useOrganizationIds from 'hooks/useOrganizationIds';
// styles
import styles from './styles';

const CancelEvent = ({ classes, ...props }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [organizationId, parentId] = useOrganizationIds();

    async function handleDeleteEvent() {
        setLoading(true);

        try {
            await API.del(
                'ClutchAPI',
                `/events/${props.match.params.id}?OrganizationId=${organizationId}&ParentId=${parentId}`
            );
            Mixpanel.track('Event canceled');
            dispatch(setSnackbar(`Event successfully deleted.`));
            props.history.replace('/events');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
            setLoading(false);
        }
    }

    return (
        <div className={classes.root}>
            <PageHeader title="Cancel Event" />
            <MaxWidthContainer padding>
                <Typography
                    align="center"
                    gutterBottom
                    classes={{
                        root: classes.text,
                        gutterBottom: classes.gutterBottom,
                    }}>
                    Are you sure you want to cancel your event?
                </Typography>
                <Typography align="center">You will have to restart the process if you begin a new event.</Typography>
                <BottomButtons
                    leftButton={{
                        text: 'No',
                        onClick: props.history.goBack,
                    }}
                    rightButton={{
                        text: 'Yes',
                        loading,
                        onClick: handleDeleteEvent,
                    }}
                    classes={{ root: classes.bottom }}
                />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(CancelEvent);
