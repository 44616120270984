import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// styles
import styles from './styles';

const ErrorMessage = ({ classes, ...props }) => {
    return (
        <div className={classes.root}>
            <div className={classes.errorMessage}> Oops... we don't recognize this component</div>
        </div>
    );
};
export default withStyles(styles)(ErrorMessage);
