import React from 'react';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
// Styles
import styles from './styles';

class Input extends React.Component {
    render() {
        const { classes, valid, errorMessage, validator, label, border = true, marginBottom = 0, ...rest } = this.props;

        const rootClass = classNames({
            [classes.root]: true,
            [classes.border]: border,
        });

        return (
            // set defaults for text fields app-wide
            <div className={rootClass} style={{ marginBottom }}>
                {/* <Typography className={classes.label}>{label}</Typography> */}
                <FormControlLabel
                    classes={{
                        root: classes.formControl,
                        label: classes.label,
                    }}
                    control={
                        <Checkbox
                            color="primary"
                            icon={<RadioButtonUnchecked className={classes.icon} />}
                            checkedIcon={<CheckCircle className={classes.iconActive} />}
                            {...rest}
                        />
                    }
                    label={label}
                    labelPlacement="start"
                />
            </div>
        );
    }
}

export default withStyles(styles)(Input);
