const styles = (theme) => ({
    root: {},
    tooltip: {
        boxShadow: '0 9px 25px rgba(0, 0, 0, 0.13)',
        border: '1px solid rgba(151, 151, 151, 0.33)',
        backgroundColor: '#ffffff',
        color: '#000000',
        padding: '16px 20px',
        borderRadius: 18,
    },
    arrow: {
        color: '#ffffff',
    },
});

export default styles;
