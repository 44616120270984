const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    button: {
        height: 62,
    },
    save: {
        marginRight: 5,
    },
    continue: {
        marginLeft: 5,
    },
    buttonRoot: {
        padding: 500,
    },
});

export default styles;
