const styles = (theme) => ({
    contacts: {
        marginTop: 19,
    },
    firstListItem: {
        paddingTop: '0px !important',
    },
    listItem: {
        padding: '12px 0px',
    },
    listItemAction: {
        right: -16,
    },
    inputLabel: {
        textDecoration: 'underline',
    },
    list: {
        paddingTop: 0,
    },
    searchOpen: {
        transform: 'none',
    },
});

export default styles;
