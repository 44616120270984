import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import { INIT, SET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR } from 'redux/reducers/categories';
import checkError from 'utils/check-error';

export const get = () => async (dispatch, getState) => {
    const { cognitoUser, categories } = cloneDeep(getState());
    dispatch(set({ ...categories, fetching: true }));
    try {
        const response = await API.get('ClutchAPI', `/organizations/${cognitoUser.Organization.ParentId}/categories/`);

        dispatch({
            payload: { ...response, fetching: false },
            type: GET_CATEGORIES_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...categories,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_CATEGORIES_ERROR,
        });
        throw error;
    }
};

export const set = (categories) => {
    if (categories) {
        return {
            type: SET_CATEGORIES,
            payload: categories,
        };
    }
    // Empty type in store if no type is sent
    return {
        type: SET_CATEGORIES,
        payload: cloneDeep(INIT),
    };
};
