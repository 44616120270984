import React, { useState } from 'react';
import { withRouter } from 'react-router';
// Redux
import Mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { set as setCognitoUser, signOut } from 'redux/actions/cognitoUser';
import checkPolicy from 'utils/check-policy';
// Material UI Core
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { ArrowBack, Menu } from '@material-ui/icons';
// Components
import Drawer from './Drawer';
// Images
import Whistle from 'icons/Whistle';
import ClutchLogo from 'assets/ClutchWhite.svg';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const Navigation = (props) => {
    const dispatch = useDispatch();
    const { cognitoUser, theme } = useSelector(({ cognitoUser, theme }) => ({
        cognitoUser,
        theme,
    }));
    const { classes } = props;

    const [drawerOpen, setDrawerOpen] = useState(false);

    async function handleLogout() {
        await dispatch(signOut());
    }

    async function handleChangeOrg(Organization) {
        dispatch(setCognitoUser({ ...cognitoUser, Organization }));
        setDrawerOpen(false);
        props.history.replace('/');
    }

    let logo = theme?.custom?.navigation?.logo ?? ClutchLogo;
    const logoClasses = [classes.logo];
    if (theme?.custom?.navigation?.logoSize) {
        logoClasses.push(classes.large);
    }

    return (
        <div className={classes.root}>
            <Drawer
                onLogout={handleLogout}
                open={drawerOpen}
                user={cognitoUser}
                // policies={cognitoUser.policies}
                // organizations={cognitoUser.Organizations}
                activeOrganization={cognitoUser.Organization}
                onOrganizationClicked={handleChangeOrg}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
            />
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.left}>
                        <IconButton onClick={() => props.history.goBack()}>
                            <ArrowBack className={classes.contrastText} />
                        </IconButton>
                    </div>
                    <div
                        className={logoClasses.join(' ')}
                        onClick={() => {
                            Mixpanel.track('Organization Logo clicked');
                            props.history.push('/');
                        }}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={classes.right}>
                        {checkPolicy({
                            policies: cognitoUser.policies,
                            policy: policyIdentifiers.PERSONAL_SAFETY_ALERTS,
                            ParentId: cognitoUser.Organization.ParentId,
                            OrganizationId: cognitoUser.Organization.id,
                        }) && (
                            <IconButton
                                onClick={() => {
                                    Mixpanel.track('Personal safety Whistle click');
                                    props.history.push('/personal-safety');
                                }}>
                                <Whistle className={classes.contrastText} />
                            </IconButton>
                        )}
                        <IconButton onClick={() => setDrawerOpen(true)}>
                            <Menu className={classes.contrastText} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>{props.children}</main>
        </div>
    );
};

export default withRouter(withStyles(styles)(Navigation));
