import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Modules
import checkError from 'utils/check-error';
import getOrgSettings from 'utils/get-org-settings';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import Navigation from 'components/Navigation';

// Pages
import Dashboard from './Dashboard';
import Events from './Events';
import Event from './Event';
import CancelRsvp from './CancelRsvp';
import InviteGuest from './InviteGuests';
import ManageInvitedGuests from './ManageInvitedGuests';
import CancelEvent from './CancelEvent';
import PersonalSafety from './PersonalSafety';
import SafetyContacts from './SafetyContacts';
import CreateSafetyAlert from './CreateSafetyAlert';
import SafetyAlerts from './SafetyAlerts';
import EducationList from './EducationList';
import Education from './Education';
import Rules from './Rules';
import Rule from './Rule';
import AlertResponse from './AlertResponse';
import EventView from './EventView';
import ReportIncident from './ReportIncident';
import EventCodes from './EventCodes';
import CheckInGuest from './CheckInGuest';
import GuestList from './GuestList';
import BeverageEducation from './BeverageEducation';
import SendNotification from './SendNotification';
import EditEvent from './EditEvent';
import ChangeEventInfo from './EditEvent/ChangeEventInfo';
import ChangeEventDate from './EditEvent/ChangeEventDate';
import EnterBirthday from './EnterBirthday';
import AgreeToRules from './AgreeToRules/AgreeToRules';
import ManageVolunteers from './ManageVolunteers';

import SendInvites from './SendInvites';

// styles
import styles from './styles';

const Secure = (props) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));

    useEffect(() => {
        async function _getOrgSettings() {
            try {
                await getOrgSettings();
            } catch (err) {
                console.log(err);
                dispatch(setSnackbar(checkError(err)));
            }
        }
        _getOrgSettings();
    }, []);

    return (
        <Navigation>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/events" component={Events} />
                <Route path="/event" component={Event} />
                <Route path="/volunteers" component={ManageVolunteers} />

                <Route path="/personal-safety" component={PersonalSafety} />
                <Route path="/safety-contacts" component={SafetyContacts} />
                <Route exact path="/rules" component={Rules} />
                <Route exact path="/rules/:order" component={Rule} />
                <Route exact path="/education" component={EducationList} />
                <Route exact path="/education/:id" component={Education} />
                <Route exact path="/safety-alerts" component={SafetyAlerts} />
                <Route exact path="/safety-alerts/create" component={CreateSafetyAlert} />
                <Route path="/safety-alerts/:id" component={AlertResponse} />
                <Route path="/beverage-education" component={BeverageEducation} />

                {/* Event view Routes */}
                <Route exact path="/event-view/:id" component={EventView} />
                <Route exact path="/event-view/:id/cancel" component={CancelEvent} />
                <Route exact path="/event-view/:id/invite-guests" component={InviteGuest} />
                <Route exact path="/event-view/:id/manage-invited-guests" component={ManageInvitedGuests} />
                <Route exact path="/event-view/:id/report" component={ReportIncident} />
                <Route exact path="/event-view/:id/codes" component={EventCodes} />
                <Route exact path="/event-view/:id/check-in-guests" component={CheckInGuest} />
                <Route exact path="/event-view/:id/guest-list" component={GuestList} />
                <Route exact path="/event-view/:id/send-invites" component={SendInvites} />
                <Route exact path="/event-view/:id/edit" component={EditEvent} />
                <Route exact path="/event-view/:id/edit/date" component={ChangeEventDate} />
                <Route exact path="/event-view/:id/edit/info" component={ChangeEventInfo} />

                <Route exact path="/event-view/:id/send-notification" component={SendNotification} />
                <Route exact path="/event-view/:id/birthday" component={EnterBirthday} />
                <Route exact path="/event-view/:id/house-rules" component={AgreeToRules} />

                <Route exact path="/event-view/:id/cancel-rsvp" component={CancelRsvp} />
                {/* End Routes */}

                <Route path="/*" render={() => <Redirect to="/" />} />
                <Route path="/" render={() => <Redirect to="/" />} />
            </Switch>
        </Navigation>
    );
};

export default withStyles(styles)(Secure);
