import React, { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { get as getUpcomingEvents } from 'redux/actions/upcomingEvents';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
import checkPolicy from 'utils/check-policy';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import EventSquare from 'components/EventSquare';
import MenuSquare from 'components/MenuSquare';
import Skeleton from 'components/Skeleton';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
// Icons
import AlertsSvg from 'assets/icons/Alerts.svg';
import CalendarSvg from 'assets/icons/Calendar.svg';
import ResourcesSvg from 'assets/icons/Resources.svg';
import RulesSvg from 'assets/icons/Rules.svg';
// Constants
import { policyIdentifiers, eventStatus } from 'global-constants';
// styles
import styles from './styles';

const Dashboard = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser, upcomingEvents } = useSelector(({ cognitoUser, upcomingEvents }) => ({
        cognitoUser,
        upcomingEvents,
    }));

    useEffect(() => {
        async function _getEvents() {
            try {
                await dispatch(getUpcomingEvents({ reviewStatus: eventStatus.Approved }));
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }
        _getEvents();
    }, [dispatch, cognitoUser]);
    return (
        <div className={classes.root}>
            <PageHeader classes={{ header: classes.topbar }} />
            <div className={classes.recentEventsContainer}>
                <div className={classes.eventsHeader}>Upcoming Events</div>
                <div className={classes.eventsRow}>
                    {(upcomingEvents.fetching ? Array.from(new Array(6)) : upcomingEvents.data).map((event, index) => {
                        return !event ? (
                            <Skeleton key={`skele-${index}`} type="eventSquare" />
                        ) : (
                            <EventSquare key={`event-square-${index}`} event={event} />
                        );
                    })}
                    {!upcomingEvents.fetching && upcomingEvents.data.length === 0 && <EventSquare empty />}
                </div>
            </div>
            <MaxWidthContainer>
                <div className={classes.menuHeader}>Menu</div>
                <div className={classes.menuRow}>
                    <MenuSquare
                        icon={CalendarSvg}
                        text="Events"
                        onClick={() => {
                            Mixpanel.track('Dashboard events clicked');
                            props.history.push('/events');
                        }}
                    />

                    {checkPolicy({
                        policies: cognitoUser.policies,
                        policy: policyIdentifiers.CHAPTER_SAFETY_ALERTS,
                        ParentId: cognitoUser.Organization.ParentId,
                        OrganizationId: cognitoUser.Organization.id,
                    }) && (
                        <MenuSquare
                            icon={AlertsSvg}
                            text="Safety Alerts"
                            onClick={() => {
                                Mixpanel.track('Dashboard safety alerts clicked');
                                props.history.push('/safety-alerts');
                            }}
                        />
                    )}

                    {checkPolicy({
                        policies: cognitoUser.policies,
                        policy: policyIdentifiers.EDUCATION_RESOURCES,
                        ParentId: cognitoUser.Organization.ParentId,
                        OrganizationId: cognitoUser.Organization.id,
                    }) && (
                        <MenuSquare
                            icon={ResourcesSvg}
                            text="Education"
                            onClick={() => {
                                Mixpanel.track('Dashboard education clicked');
                                props.history.push('/education');
                            }}
                        />
                    )}

                    {checkPolicy({
                        policies: cognitoUser.policies,
                        policy: policyIdentifiers.HOUSE_RULES,
                        ParentId: cognitoUser.Organization.ParentId,
                        OrganizationId: cognitoUser.Organization.id,
                    }) && (
                        <MenuSquare
                            icon={RulesSvg}
                            text={'Rules'}
                            onClick={() => {
                                Mixpanel.track('Dashboard Rules clicked');
                                props.history.push('/rules');
                            }}
                        />
                    )}
                </div>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(Dashboard);
