import axios from 'axios';
import { awsConfig } from 'aws/aws_resource';
import { Auth } from 'aws-amplify';

class Upload {
    static post = async (path, form) => {
        return Upload.request(path, form, 'post');
    };

    static patch = async (path, form) => {
        return Upload.request(path, form, 'patch');
    };

    static request = async (path, form, method) => {
        const user = await Auth.currentSession();
        return axios[method](`${awsConfig.APIV1Endpoint}${path}`, form, {
            headers: {
                Authorization: user.idToken.jwtToken,
                'content-type': 'multipart/form-data',
            },
        });
    };
}

export default Upload;
