const styles = (theme) => ({
    container: {
        maxWidth: 600,
        width: '100%',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
    },
    root: {
        background: '#454c5d',
    },
    appBar: {
        position: 'relative',
        borderBottom: '1px solid #303542',
    },

    toolbar: {
        minHeight: 18,
        justifyContent: 'space-between',
    },
    title: {
        textAlign: 'center',
        flex: 1,
        fontWeight: 600,
    },
    closeButton: {
        height: 35,
        width: 75,
        background: '#303542',
    },
    buttonLabel: {
        fontSize: 14,
    },
    comments: {
        paddingTop: 40,
        [theme.breakpoints.down('xs')]: {
            padding: 18,
            paddingTop: 40,
        },
    },
    group: {
        marginBottom: 80,
    },
    heading: {
        fontSize: 15,
        color: 'white',
    },
    timelineRoot: {
        padding: 0,
        marginTop: 15,
        marginBottom: 0,
        '& > li > div': {
            padding: 0,
        },
    },
});
export default styles;
