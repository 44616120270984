import React from 'react';
// Modules
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import Button from 'components/Button';
// styles
import styles from './styles';

const BottomButtons = ({ classes, leftButton, rightButton }) => {
    return (
        <div className={classes.root}>
            <Button
                variant={'outlined'}
                classes={{ root: classNames(classes.button, classes.save) }}
                fullWidth={true}
                variantType="outlinedOuterSpace"
                text="Finish Later"
                {...leftButton}
            />
            <Button
                variant={'contained'}
                variantType="containedOuterSpace"
                classes={{ root: classNames(classes.button, classes.continue) }}
                color={'primary'}
                fullWidth={true}
                text="Continue"
                {...rightButton}
            />
        </div>
    );
};

export default withStyles(styles)(BottomButtons);
