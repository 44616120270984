import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { get as getCategories } from 'redux/actions/categories';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import checkError from 'utils/check-error';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, ArrowForwardIos } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// styles
import styles from './styles';

const EducationList = ({ classes, ...props }) => {
    const [opened, setOpened] = useState({});
    const dispatch = useDispatch();
    const { categories } = useSelector(({ categories }) => ({ categories }));

    useEffect(() => {
        async function _getCategories() {
            try {
                await dispatch(getCategories());
            } catch (error) {
                setSnackbar(checkError(error));
            }
        }
        _getCategories();
    }, [dispatch]);

    function handleCategoryClicked(index) {
        const clonedOpen = cloneDeep(opened);
        if (clonedOpen[index]) delete clonedOpen[index];
        else clonedOpen[index] = true;
        setOpened(clonedOpen);
    }

    return (
        <div className={classes.root}>
            <PageHeader title={'Education'} />
            <MaxWidthContainer>
                <List classes={{ root: classes.list }} dense>
                    <ListItem
                        classes={{
                            root: classNames(classes.title, classes.listItem),
                        }}>
                        <ListItemText primary="Categories" />
                    </ListItem>
                    {categories.data.map((category, index) => {
                        return (
                            <React.Fragment key={`education-${index}`}>
                                <ListItem
                                    classes={{
                                        root: classNames(classes.category, classes.listItem),
                                    }}
                                    onClick={() => handleCategoryClicked(index)}>
                                    <ListItemText primary={category.name} />
                                    {opened[index] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                {category.Education && category.Education.length > 0 && (
                                    <Collapse in={opened[index]} timeout="auto" unmountOnExit>
                                        <List
                                            classes={{
                                                root: classNames(classes.education, classes.listItem),
                                            }}
                                            component="div"
                                            disablePadding>
                                            {category.Education.map((education) => {
                                                return (
                                                    <ListItem
                                                        key={`education-${education.id}`}
                                                        button
                                                        onClick={() => props.history.push(`/education/${education.id}`)}
                                                        className={classes.nested}>
                                                        <ListItemText primary={education.title} />
                                                        <ArrowForwardIos className={classes.forwardIcon} />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(EducationList);
