import { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';

const useOrganizationIds = () => {
    const [organizationId, setOrganizationId] = useState('');
    const [parentId, setParentId] = useState('');
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({
        cognitoUser,
    }));
    useEffect(() => {
        if (cognitoUser) {
            setOrganizationId(cognitoUser.Organization.id);
            setParentId(cognitoUser.Organization.ParentId);
        }
    }, [cognitoUser]);

    return [organizationId, parentId];
};

export default useOrganizationIds;
