const styles = (theme) => ({
    root: { flex: 1 },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 76,
        fontSize: 20,
        fontWeight: 400,
        letterSpacing: '0.29px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    container: {
        padding: 20,
        maxWidth: 600,
        margin: 'auto',
    },
    inputLabel: {
        textDecoration: 'underline',
    },
    bottom: {
        marginTop: 18,
    },
});

export default styles;
