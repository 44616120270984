import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Utils
import { parseHtmlJson } from 'utils/string-utils';
// styles
import styles from './styles';

const BodyText = ({ classes, ...props }) => {
    return <div className={classes.root} dangerouslySetInnerHTML={parseHtmlJson(props.body)} />;
};
export default withStyles(styles)(BodyText);
