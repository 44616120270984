import React from 'react';

// Modules
import dayjs from 'dayjs';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { Message } from '@material-ui/icons';

// styles
import styles from './styles';

const Alert = ({ classes, ...props }) => {
    const {
        Creator: { firstName, lastName },
        information,
        createdAt,
    } = props.alert;

    return (
        <div className={classes.root}>
            <div className={classes.square}>
                <Typography className={classes.text}>{information}</Typography>
                {props.onClick && (
                    <IconButton className={classes.messageButton} onClick={props.onClick}>
                        <Message />
                    </IconButton>
                )}
            </div>
            <Typography className={classes.timeStamp}>
                {`From ${firstName} ${lastName} on ${dayjs(createdAt).format('M/D/YYYY - h:mma')}`}
            </Typography>
        </div>
    );
};

export default withStyles(styles)(Alert);
