import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { cloneDeep } from 'lodash';
// Modules
import checkPolicy from 'utils/check-policy';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { set as setCognitoUser } from 'redux/actions/cognitoUser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Avatar, List, ListItem, ListItemText, ListItemAvatar, Paper, Typography } from '@material-ui/core';
// Constants
import { policyIdentifiers } from 'global-constants';
// Styles
import styles from './styles';

const SelectOrganization = ({ classes, cognitoUser, setCognitoUser, staticContext, ...props }) => (
    <div className={classes.selectOrgRoot}>
        <Typography variant="body1">Please select which Organization you would like to Access.</Typography>
        <List className={classes.selectOrgRoot} {...props}>
            {cognitoUser.Organizations.map((organization, index) =>
                checkPolicy({
                    policies: cognitoUser.policies,
                    policy: policyIdentifiers.MEMBER_APP_LOGIN,
                    ParentId: organization.ParentId,
                    OrganizationId: organization.id,
                }) ? (
                    <ListItem
                        key={organization.id}
                        button
                        onClick={(_) => {
                            const cloned = cloneDeep(cognitoUser);
                            cloned.Organization = cloned.Organizations[index];
                            setCognitoUser(cloned);
                            props.history.replace('/');
                        }}>
                        <ListItemAvatar>
                            <Avatar>A</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={organization.name} />
                    </ListItem>
                ) : null
            )}
        </List>
    </div>
);

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

const ConnectedSelectOrganization = connect(mapStateToProps, {
    setCognitoUser,
})(withStyles(styles)(SelectOrganization));

const SelectOrganizationStack = (props) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={5}>
                <Switch>
                    <Route exact path="/" render={(props) => <ConnectedSelectOrganization {...props} />} />
                    <Route path="/*" render={() => <Redirect to="/" />} />
                </Switch>
            </Paper>
        </div>
    );
};

const actions = { setSnackbar };

export default connect(null, actions)(withStyles(styles)(SelectOrganizationStack));
