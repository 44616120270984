const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        padding: 0,
    },
});

export default styles;
