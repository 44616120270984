import React from 'react';

// Modules
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import style from './styles';

const SkeletonEventView = ({ classes, loading, onClick, empty, ...props }) => {
    const rootClasses = classNames({
        [classes.root]: true,
    });

    return (
        <div className={rootClasses} onClick={onClick}>
            <div className={classes.body}>
                <div className={classes.image}>
                    <Skeleton variant="rect" animation="wave" height={170} />
                </div>
                <div className={classes.date}>
                    <Skeleton variant="rect" animation="wave" height={20} />
                </div>
                <div className={classes.title}>
                    <Skeleton variant="rect" animation="wave" height={20} />
                </div>
                <div className={classes.rsvp}>
                    <Skeleton variant="rect" animation="wave" height={57} />
                </div>
                <div className={classes.menu}>
                    <div className={classes.menuSquare1}>
                        <Skeleton variant="rect" animation="wave" height={150} />
                    </div>
                    <div className={classes.menuSquare2}>
                        <Skeleton variant="rect" animation="wave" height={150} />
                    </div>
                    <div className={classes.menuSquare1}>
                        <Skeleton variant="rect" animation="wave" height={150} />
                    </div>
                    <div className={classes.menuSquare2}>
                        <Skeleton variant="rect" animation="wave" height={150} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(style)(SkeletonEventView);
