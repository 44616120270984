const styles = (theme) => ({
    root: {
        flex: 1,
    },
    skeleton: {
        marginTop: 20,
    },
    alert: {
        textDecoration: 'underline',
        color: '#d35656',
    },
    inputLabel: {
        textDecoration: 'underline',
        opacity: 1.0,
        color: '#000',
    },
    form: {
        marginTop: 18,
    },
});

export default styles;
