// React
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI
import { withStyles } from '@material-ui/core/styles';
// Components
import SkeletonEventSquare from './SkeletonEventSquare';
import SkeletonEventItem from './SkeletonEventItem';
import SkeletonListItem from './SkeletonListItem';
import SkeletonInfoPage from './SkeletonInfoPage';
import SkeletonList from './SkeletonList';
import SkeletonEventStep from './SkeletonEventStep';
import SkeletonQrPage from './SkeletonQrPage';
import SkeletonEventView from './SkeletonEventView';
import FullscreenLoader from 'components/FullscreenLoader';
// Styles
import styles from './styles';

const Skeleton = ({ type = null, classes, ...props }) => {
    return type !== null ? (
        <>
            {type === 'eventItem' && <SkeletonEventItem {...props} />}
            {type === 'eventSquare' && <SkeletonEventSquare {...props} />}
            {type === 'listItem' && <SkeletonListItem {...props} />}
            {type === 'list' && <SkeletonList {...props} />}
            {type === 'infoPage' && <SkeletonInfoPage {...props} />}
            {type === 'qrPage' && <SkeletonQrPage {...props} />}
            {type === 'eventView' && <SkeletonEventView {...props} />}
            {type === 'eventStep' && <SkeletonEventStep {...props} />}
        </>
    ) : (
        <FullscreenLoader classes={classes.root} />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
};

export default withStyles(styles)(Skeleton);
