// React
import React from 'react';
// Modules
import dayjs from 'dayjs';
// Mui-Core
import { withStyles } from '@material-ui/core/styles';
import {
    Timeline as MuiTimeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@material-ui/lab';
import { Message } from '@material-ui/icons';
// Components
import styles from './styles';

const Timeline = ({ classes, comments, ...props }) => {
    return (
        <div className={classes.root}>
            <MuiTimeline classes={{ root: classes.timelineRoot }} align={'left'}>
                {comments &&
                    comments.length > 0 &&
                    comments.map((comment, index) => {
                        return (
                            <TimelineItem key={`comment-${index}`}>
                                {
                                    // <TimelineSeparator>
                                    //     <TimelineDot classes={{ defaultGrey: classes.timelineDot }}>
                                    //     <Message />
                                    //     </TimelineDot>
                                    // {index !== comments.length - 1 ? <TimelineConnector /> : null}
                                    // </TimelineSeparator>
                                }
                                <TimelineContent classes={{ root: classes.timelineContentRoot }}>
                                    <div className={classes.commentBubble}>
                                        <div className={classes.commentName}>{comment.name}</div>
                                        <div className={classes.commentMessage}>{comment.comment}</div>
                                    </div>
                                    <div className={classes.commentTimestamp}>{`${dayjs(comment.date).format(
                                        'MMMM D,'
                                    )} at ${dayjs(comment.date).format(' h:mm A')}`}</div>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
            </MuiTimeline>
        </div>
    );
};

export default withStyles(styles)(Timeline);
