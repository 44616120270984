const styles = (theme) => ({
    root: {
        flex: 1,
    },
    inputLabel: {
        textDecoration: 'underline',
    },
    manage: {
        marginBottom: 20,
        opacity: 0.5,
    },
    button: {
        marginTop: 80,
    },
    title: {
        marginBottom: 20,
        fontSize: 16,
        fontWeight: 400,
        color: '#222222',
    },
});

export default styles;
