const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    exportButtonRoot: {
        minHeight: 62,
        marginBottom: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputLabel: {
        textDecoration: 'underline',
        color: '#4a4a4a',
    },
    mb15: {
        marginBottom: 15,
    },
    mb20: {
        marginBottom: 20,
    },
    menuSquare: {
        minHeight: 121,
    },
    list: {
        marginBottom: 24,
    },
    notRsvpd: {
        opacity: 0.6,
    },
});

export default styles;
