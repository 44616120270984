import React, { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { get as getEventTypes } from 'redux/actions/eventTypes';
import { set as setEvent } from 'redux/actions/event';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import Mixpanel from 'mixpanel-browser';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
// Components
import Skeleton from 'components/Skeleton';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
// styles
import styles from './styles';

const EventType = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { eventTypes } = useSelector(({ eventTypes }) => ({ eventTypes }));

    useEffect(() => {
        async function _getEventTypes() {
            try {
                await dispatch(getEventTypes());
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }
        _getEventTypes();
    }, [dispatch]);

    async function handleTypeClicked(type) {
        dispatch(setEvent({ EventType: type }));
        Mixpanel.track('Event creation process started');
        props.history.push('/event/create');
    }

    return (
        <div className={classes.root}>
            <PageHeader title={'Choose a social event'} />
            <MaxWidthContainer>
                <List dense className={classes.list}>
                    {(eventTypes.fetching ? Array.from(new Array(3)) : eventTypes.data).map((type, index) => {
                        return !type ? (
                            <Skeleton key={`skele-${index}`} type="listItem" bordered />
                        ) : (
                            <ListItem
                                button
                                key={`list-${type.name}`}
                                className={classes.listItem}
                                onClick={() => handleTypeClicked(type)}>
                                <ListItemText primary={type.name} classes={{ primary: classes.title }} />
                                <ListItemSecondaryAction>
                                    <ArrowForwardIos className={classes.icon} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(EventType);
