import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import { INIT, SET_EVENT, GET_EVENT_SUCCESS, GET_EVENT_ERROR } from 'redux/reducers/event';

export const get = (id) => async (dispatch, getState) => {
    const user = cloneDeep(getState().cognitoUser);
    try {
        const response = await API.get(
            'ClutchAPI',
            `/events/${id}?OrganizationId=${user.Organization.id}&ParentId=${user.Organization.ParentId}`
        );

        console.log(response);
        dispatch({
            type: GET_EVENT_SUCCESS,
            payload: response,
        });

        return response;
    } catch (error) {
        dispatch({
            type: GET_EVENT_ERROR,
        });
        throw error;
    }
};

export const set = (payload) => {
    if (payload) {
        return {
            type: SET_EVENT,
            payload: payload,
        };
    }
    // Empty payload in store if no payload is sent
    return {
        type: SET_EVENT,
        payload: cloneDeep(INIT),
    };
};
