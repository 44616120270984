import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
// styles
import styles from './styles';

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const EventInformation = ({ classes, match, event, ...props }) => {
    return (
        <div className={classes.section}>
            <div className={classes.information}>
                <Typography variant="h4">{event.information.title}</Typography>
                <Typography variant="subtitle1">{event.information.description}</Typography>
            </div>
        </div>
    );
};

export default withStyles(styles)(EventInformation);
