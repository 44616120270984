const styles = (theme) => ({
    root: {
        margin: '44px auto',
        position: 'relative',
    },
    rail: {
        height: 30,
        backgroundColor: '#e8e8e8',
        borderRadius: 15,
    },
    track: {
        height: 30,
        backgroundColor: '#d35656',
        borderRadius: '15px 0 0 15px',
    },
    thumb: {
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.33)',
        width: 42,
        height: 42,
        backgroundColor: '#ffffff',
        transform: 'translateX(-10px)',
        '&:focus, &:hover': {
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.33)',
        },
    },
    value: {
        margin: 'auto',
        width: 110,
        height: 50,
        borderRadius: 12,
        border: '1px solid rgba(151, 151, 151, 0.33)',
        '& > p': {
            fontWeight: 700,
            marginTop: 15,
        },
    },
    light: {
        color: '#363636',
        opacity: 0.5,
    },
    low: {
        position: 'absolute',
        left: 0,
        top: 16,
    },
    high: {
        position: 'absolute',
        right: 0,
        top: 16,
    },
    title: {
        color: '#222222',
        marginTop: 25,
        fontSize: 14,
    },
});

export default styles;
