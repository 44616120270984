import { grey, colors } from "./global-styles";
var colorManipulator = require("./utils/style-utils");

export default {
  mixins: {
    toolbar: {
      minHeight: 111,
    },
  },
  palette: {
    primary: {
      main: "#454c5d",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e5035b",
      contrastText: "#fff",
    },
    error: {
      main: "#e50000",
      contrastText: "#fff",
    },
    success: {
      main: "#82C958",
      contrastText: "#fff",
    },
  },
  typography: {
    useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
    h3: {
      fontSize: 20,
      fontWeight: 400,
      color: "#4a4a4a",
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      color: "#222222",
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      color: "#222222",
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      opacity: 0.5,
      lineHeight: "19px",
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 600,
      opacity: 0.5,
      lineHeight: "19px",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 62,
        textTransform: "unset",
        borderRadius: 6,
      },
      outlined: {
        borderWidth: 2,
        boxShadow: "none",
        fontWeight: 400,
      },
      outlinedPrimary: {
        borderColor: grey,
        borderWidth: 2,
        color: grey,
        "&:hover": {
          borderColor: grey,
          borderWidth: 2,
          color: grey,
        },
      },
      label: {
        fontSize: 16,
      },
      sizeLarge: {
        height: 60,
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: "0 7px 25px 0 rgba(0,0,0,0.12)",
      },
      rounded: {
        borderRadius: 16,
      },
    },
    MuiInput: {
      root: {
        paddingBottom: 10,
        paddingTop: 10,
      },
      underline: {
        // paddingBottom: 10,
      },
    },
    MuiInputBase: {
      inputAdornedEnd: {
        // padding: 0,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(0,0,0,0.42)",
      },
    },
  },
  buttons: {
    outlined: {
      outerSpace: {
        padding: "9px 30px",
        color: colors.brandColors.outerSpace,
        border: `1px solid ${colors.brandColors.outerSpace}`,
        "&:hover": {
          border: `3px solid ${colors.brandColors.outerSpace}`,
          filter: "saturate(150%)",
          backgroundColor: colorManipulator.changeColorOpacity(
            colors.brandColors.outerSpace,
            0.2
          ),
          background: colorManipulator.changeColorOpacity(
            colors.brandColors.outerSpace,
            0.2
          ),
          "& svg.MuiCircularProgress-svg": {
            color: colors.brandColors.outerSpace,
          },
        },
      },
      white: {
        padding: "9px 30px",
        color: "rgba(255,255,255,1.0)",
        border: `1px solid ${"rgba(255,255,255,1.0)"}`,
        "&:hover": {
          border: `3px solid ${"rgba(255,255,255,1.0)"}`,
          filter: "saturate(150%)",
          backgroundColor: colorManipulator.changeColorOpacity(
            "rgba(255,255,255,1.0)",
            0.2
          ),
          background: colorManipulator.changeColorOpacity(
            "rgba(255,255,255,1.0)",
            0.2
          ),
          "& svg.MuiCircularProgress-svg": {
            color: "rgba(255,255,255,1.0)",
          },
        },
      },
      grey: {
        padding: "9px 30px",
        color: colors.brandColors.grey,
        border: `1px solid ${colors.brandColors.grey}`,
        "&:hover": {
          border: `3px solid ${colors.brandColors.grey}`,
          filter: "saturate(150%)",
          backgroundColor: colorManipulator.changeColorOpacity(
            colors.brandColors.grey,
            0.2
          ),
          background: colorManipulator.changeColorOpacity(
            colors.brandColors.grey,
            0.2
          ),
          "& svg.MuiCircularProgress-svg": {
            color: colors.brandColors.grey,
          },
        },
      },
    },
    contained: {
      outerSpace: {
        color: "#fff",
        backgroundColor: colors.brandColors.outerSpace,

        "&:hover": {
          backgroundColor: (0, colorManipulator.darken)(
            colors.brandColors.outerSpace,
            0.3
          ),
        },
      },
      warning: {
        color: "#fff",
        backgroundColor: "#D35656",

        "&:hover": {
          backgroundColor: (0, colorManipulator.darken)("#D35656", 0.3),
        },
      },
    },
  },
};
