import Auth from '@aws-amplify/auth';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { awsConfig } from 'aws/aws_resource';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

// Styles
import styles from './styles';

const SsoLauncher = ({ classes }) => {
    const { integrationId } = useParams();

    async function handleLaunch() {
        const response = await axios.get(`${awsConfig.APIV1Endpoint}/sso`);

        const ssoOrgs = response.data;

        const ssoOrg = ssoOrgs.find((x) => x.integrationId.toLowerCase() === integrationId.toLowerCase());

        if (ssoOrg) {
            await Auth.federatedSignIn({
                provider: ssoOrg.provider,
            });
        }
    }

    useEffect(() => {
        handleLaunch();
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.grid}>
                <span className={classes.item}>Signing In</span>
                <CircularProgress className={classes.item} />
            </div>
        </div>
    );
};

export default withStyles(styles)(SsoLauncher);
