const styles = (theme) => ({
    root: {
        padding: '30px 0px 30px 0px',
        maxWidth: 'none',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        margin: 0,
    },
    qr: {
        padding: '30px 0px',
        margin: 'auto',
        height: 173,
        width: 173,
    },
    title: {
        color: 'white',
        padding: '0px 30px',
    },
    white: {
        color: 'white',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '10%',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    skeleton: {
        padding: 30,
    },
    checkSelf: {
        paddingTop: 22,
        paddingBottom: 22,
        marginTop: 30,
        width: 251,
    },
    checkSelfText: {
        position: 'absolute',
        left: 20,
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: 27,
    },
    endIcon: {
        fontSize: '26px !important',
        position: 'absolute',
        right: 20,
        top: 0,
        bottom: 0,
        margin: 'auto',
    },
});

export default styles;
