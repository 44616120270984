import React, { Component } from 'react';

// Modules
import { Hub } from 'aws-amplify';
import checkError from 'utils/check-error';
// import { diff } from "deep-object-diff";

// Redux
import { connect } from 'react-redux';
import { get as getCognitoUser, set as setCognitoUser, signOut } from './redux/actions/cognitoUser';
import { setSnackbar } from 'redux/actions/snackbar';

// Components
import FullscreenLoader from './components/FullscreenLoader';

// Containers
import SecureStack from './containers/Secure/Secure';
import AuthStack from './containers/Auth/Auth';
import SelectOrganizationStack from './containers/SelectOrganization/SelectOrganization';

/**
 * A HOC that routes a user to the appropriate navigation stack based on the authorizing function.
 * Must have access to the history.
 * @param {*} props
 */

class Authorizer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authorizing: props.location.pathname === '/authorizing',
        };
    }

    async componentDidMount() {
        Hub.listen('auth', this.handleAuth);

        // Kick off getUser before any Hub events have come in
        await this.getUser();

        this.setState({ authorizing: false });
    }

    componentDidUpdate(prevProps, prevState) {
        // For debugging unecessary re-renders, don't delete just comment out please
        // console.log("props", diff(prevProps, this.props));
        // console.log("state", diff(prevState, this.state));
    }

    componentWillUnmount() {
        Hub.remove('auth', this.handleAuth);
    }

    handleAuth = async ({ payload: { event, data } }) => {
        if (event === 'signIn') {
            // Don't think this is necessary but we'll leave it here in case we start seeing SSO issues
            //   await this.getUser();
        } else {
            await signOut();
        }
    };

    getUser = async () => {
        const { getCognitoUser, setSnackbar } = this.props;

        try {
            await getCognitoUser();
        } catch (error) {
            setSnackbar(checkError(error));
        }
    };

    render() {
        const { cognitoUser, ...props } = this.props;
        const { authorizing } = this.state;

        if (authorizing) return <FullscreenLoader />;
        if (!cognitoUser) return <AuthStack {...props} />;
        if (!cognitoUser.Organization) return <SelectOrganizationStack {...props} />;
        if (cognitoUser && cognitoUser.sub) return <SecureStack {...props} />;

        return <FullscreenLoader />;
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, {
    getCognitoUser,
    setCognitoUser,
    setSnackbar,
    signOut,
})(Authorizer);
