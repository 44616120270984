import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// components
import SetInfo from './SetInfo';
import PreAcceptedHome from './PreAcceptedHome';
import PostAcceptedHome from './PostAcceptedHome';
import PreviewDrink from './PreviewDrink';
import AddDrink from './AddDrink';
import DrinkType from './DrinkType';

// styles
import styles from './styles';

const BeverageEducation = ({ classes, ...props }) => {
    const accepted = useSelector((state) => state.beverageEducation.accepted);

    return (
        <div className={classes.root}>
            {/* Steps will go here. */}
            <Switch>
                <Route exact path="/beverage-education/set-info" component={SetInfo} />
                <Route exact path="/beverage-education/confirm" component={PreviewDrink} />
                <Route exact path="/beverage-education/add-drink" component={AddDrink} />
                <Route exact path="/beverage-education/drink-type" component={DrinkType} />
                <Route exact path="/beverage-education" component={accepted ? PostAcceptedHome : PreAcceptedHome} />
                <Route path="/beverage-education" render={() => <Redirect to="/beverage-education" />} />
            </Switch>
        </div>
    );
};

export default withStyles(styles)(BeverageEducation);
