import ErrorMessage from './components/ErrorMessage';
import Selects from './components/Selects';
import BodyText from './components/BodyText';
import Checkbox from './components/Checkbox';
import FileDownload from './components/FileDownload';
import FileUpload from './components/FileUpload';
import Heading from './components/Heading';
import HorizontalRuler from './components/HorizontalRuler';
import ShortTextInput from './components/ShortTextInput';
import DateTimeInput from './components/DateTimeInput';
import TimezoneInput from './components/TimezoneInput';
import TextAreaInput from './components/TextAreaInput';
import Title from './components/Title';
import Whitespace from './components/Whitespace';
import Volunteer from './components/Volunteer';

// Instead of a giant switch statement
export const SECTION_MARKUP_MAP = {
    default: ErrorMessage,
    horizontalRuler: HorizontalRuler,
    bodyText: BodyText,
    checkBox: Checkbox,
    fileDownload: FileDownload,
    fileUpload: FileUpload,
    heading: Heading,
    shortTextInput: ShortTextInput,
    textAreaInput: TextAreaInput,
    dateTimeInput: DateTimeInput,
    timezoneInput: TimezoneInput,
    title: Title,
    whiteSpace: Whitespace,
    volunteer: Volunteer,
    selects: Selects,
};
