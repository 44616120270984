import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';

import {
    // INIT,
    // GET_ATTENDEES,
    GET_ATTENDEES_SUCCESS,
    GET_ATTENDEES_ERROR,
} from 'redux/reducers/attendees';

export const get = (eventId) => async (dispatch, getState) => {
    const user = cloneDeep(getState().cognitoUser);

    try {
        const response = await API.get('ClutchAPI', `/events/${eventId}/attendees`, {
            queryStringParameters: {
                OrganizationId: user.Organization.id,
                ParentId: user.Organization.ParentId,
            },
        });

        dispatch({
            type: GET_ATTENDEES_SUCCESS,
            payload: response,
        });

        return response;
    } catch (error) {
        dispatch({
            type: GET_ATTENDEES_ERROR,
        });
        throw error;
    }
};
