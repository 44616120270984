import React from 'react';

// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import FormValidator from 'utils/FormValidator';
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import SafeOrNotSafe from './SafeOrNotSafe/SafeOrNotSafe';

// INIT
import FORM_INIT from './init';

// styles
import styles from './styles';

class AlertResponse extends React.Component {
    INIT = FORM_INIT;
    CHARACTER_LIMIT = 120;
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            ...cloneDeep(this.INIT),
        };
        this._getAlert();
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getAlert() {
        if (!this.props.location.state) return this.props.history.goBack();

        const { setSnackbar, cognitoUser } = this.props;
        const { id } = this.props.match.params;

        try {
            const response = await API.get('ClutchAPI', `/users/${cognitoUser.id}/alert-responses/${id}`);
            Mixpanel.track('Responded to Safety Alert');
            this.setState({
                status: { value: response.status, valid: true },
                note: { value: response.note || '', valid: true },
                location: { value: response.location || '', valid: true },
            });
        } catch (error) {
            this.props.history.goBack();
            setSnackbar(checkError(error, 'error'));
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { setSnackbar, cognitoUser } = this.props;
        const { id } = this.props.match.params;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            await API.patch('ClutchAPI', `/users/${cognitoUser.id}/alert-responses/${id}`, {
                body: {
                    location: this.state.location.value,
                    note: this.state.note.value,
                    status: this.state.status.value,
                },
            });

            setSnackbar('Your response to this alert has been sent');
        } catch (error) {
            setSnackbar(checkError(error, 'error'));
        }
        this.mounted && this.setState({ submitting: false });
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const {
            classes,
            location: { state: alert },
        } = this.props;
        const { status, location, note } = this.state;
        const sharedInputProps = {
            variant: 'standard',
            InputLabelProps: {
                shrink: true,
                classes: { root: classes.inputLabel },
            },
            FormHelperTextProps: {
                classes: { root: classes.formHelperText },
            },
            fullWidth: true,
        };

        return (
            <div className={classes.root}>
                <PageHeader title="My Safety Alert" />
                <MaxWidthContainer padding>
                    <Alert alert={alert} classes={{ root: classes.alertRoot }} />
                    <form onSubmit={this.handleSubmit} className={classes.formContainer}>
                        <SafeOrNotSafe
                            isSafe={status.value === 'Safe'}
                            onChange={(value) => this.updateField({ field: 'status', value })}
                        />
                        <div>
                            <Input
                                ref="location"
                                label="My Location"
                                value={location.value}
                                marginBottom={20}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'location',
                                        value: e.target.value,
                                    })
                                }
                                {...sharedInputProps}
                            />
                            <Input
                                ref="note"
                                label="Notes"
                                value={note.value}
                                marginBottom={20}
                                multiline
                                inputProps={{
                                    maxLength: this.CHARACTER_LIMIT,
                                }}
                                helperText={`${note.value.length}/${this.CHARACTER_LIMIT} characters left`}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'note',
                                        value: e.target.value,
                                    })
                                }
                                {...sharedInputProps}
                            />
                            <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                        </div>
                        <Button
                            onClick={this.handleSubmit}
                            classes={{ root: classes.submitButton }}
                            loading={this.state.submitting}
                            variant="contained"
                            variantType="containedOuterSpace"
                            type="submit"
                            color="primary"
                            text="Submit"
                            fullWidth
                        />
                    </form>
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(AlertResponse));
