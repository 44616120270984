import cloneDeep from 'lodash/cloneDeep';
import { INIT, SET_BEVERAGE_EDUCATION } from 'redux/reducers/beverageEducation';

export function set(payload) {
    if (payload) {
        return {
            type: SET_BEVERAGE_EDUCATION,
            payload,
        };
    }
    // Empty type in store if no type is sent
    return {
        type: SET_BEVERAGE_EDUCATION,
        payload: cloneDeep(INIT),
    };
}
