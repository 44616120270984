import React from 'react';

import { bytesToSize } from 'utils/file-types';
import clsx from 'classnames';
import ReactPlayer from 'react-player';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// styles
import styles from './styles';

const RuleWrapper = ({ classes, rule }) => {
    return (
        <div className={classes.rule}>
            <Typography className={classes.title}>{rule.title}</Typography>
            {rule.media && (
                <img
                    alt={'rule'}
                    src={rule.media.url}
                    className={clsx(classes.img, {
                        [classes.imageCover]: rule.media.height > 300 && rule.media.width > 600,
                        [classes.imageContain]: rule.media.height < 299 && rule.media.width < 599,
                    })}
                />
            )}
            {rule.videoUrl && (
                <div className={classes.video}>
                    <ReactPlayer url={rule.videoUrl} width="100%" />
                </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: rule.information }} />
            {rule.file && (
                <div className={classes.file}>
                    <a href={rule.file.url}>
                        {rule.file.name} ({bytesToSize(rule.file.size)})
                    </a>
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(RuleWrapper);
