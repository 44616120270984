const styles = (theme) => ({
    timelineRoot: {
        '& :before': {
            flex: 'unset',
            padding: 0,
        },
    },
    timelineDot: {
        backgroundColor: '#8f8f8f',
    },
    timelineContentRoot: {
        // flex: "unset",
    },
    timeLineSectionTitle: {
        color: '#fff',
    },

    timelineSeperator: {
        padding: '0 11px',
    },

    commentBubble: {
        padding: '8px 12px',
        background: '#f0f2f5',
        borderRadius: 18,
    },
    commentName: {
        fontSize: '.8125rem;',
        fontWeight: 600,
    },
    commentMessage: {
        fontSize: '.8125rem;',
    },
    commentTimestamp: {
        fontSize: 12,
        color: 'rgba(255,255,255,0.7)',
        marginLeft: 8,
        marginTop: 5,
        marginBottom: 25,
    },
});
export default styles;
