import React from 'react';
// Modules
import moment from 'moment';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import Input from 'components/Input';
// Styles
import styles from './styles';

class ShortTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.date = moment(props.value).format('yyyy-MM-DD');
        this.time = moment(props.value).format('HH:mm:ss');
    }

    onChangeDate(date) {
        this.date = date;
        const value = moment.utc(new Date(`${this.date}T${this.time}`)).toISOString();
        if (typeof this.props.onChange === 'function') this.props.onChange(value);
    }

    onChangeTime(time) {
        this.time = time;
        const value = moment.utc(new Date(`${this.date}T${this.time}`)).toISOString();
        if (typeof this.props.onChange === 'function') this.props.onChange(value);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Input
                    id={this.props.id}
                    label={this.props.dateTitle}
                    placeholder={this.props.placeholder}
                    defaultValue={this.date}
                    required={this.props.required}
                    type={'date'}
                    variant="standard"
                    fullWidth
                    marginBottom={30}
                    onChange={(e) => this.onChangeDate(e.target.value)}
                    InputProps={{
                        classes: { root: classes.input },
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    }}
                />
                <Input
                    id={this.props.id}
                    label={this.props.timeTitle}
                    placeholder={this.props.placeholder}
                    defaultValue={this.time}
                    required={this.props.required}
                    type={'time'}
                    variant="standard"
                    fullWidth
                    onChange={(e) => this.onChangeTime(e.target.value)}
                    InputProps={{
                        classes: { root: classes.input },
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ShortTextInput);
