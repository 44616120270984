import React from 'react';
import { withRouter } from 'react-router';
// Modules
import { API } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
// Redux
import { get as getAttendees } from 'redux/actions/attendees';
import { connect } from 'react-redux';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, List } from '@material-ui/core';
import { Search } from '@material-ui/icons';
// Components
import Checkbox from 'components/Input/Checkbox';
import Input from 'components/Input';
// Utils
import { parseHtmlJson } from 'utils/string-utils';
// styles
import styles from './styles';

class Volunteer extends React.Component {
    state = {
        members: [],
        selected: {},
        search: '',
        organization: this.props.module.OrganizationId || '',
    };

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            selected: {},
            search: '',
        };

        this._getVolunteers();
    }

    async _getVolunteers() {
        const Organization = this.props.cognitoUser.Organization;
        try {
            const members = await API.get(
                'ClutchAPI',
                `/events/${this.props.match.params.id}/modules/${this.props.module.id}/possible-volunteers?OrganizationId=${Organization.id}&ParentId=${Organization.ParentId}`
            );
            const selected = cloneDeep(this.state.selected);
            members.forEach((member) => {
                if (member.selected) selected[member.id] = `${member.firstName} ${member.lastName}`;
            });

            this.setState({ members, selected });
        } catch (error) {
            console.log(error);
        }
    }

    onInput = (e) => {
        this.setState({ search: e.target.value });
    };

    onChange = (guestId) => {
        const guest = this.state.members.find((user) => user.id === guestId);
        const selectedClone = cloneDeep(this.state.selected);

        if (selectedClone[guest.id]) delete selectedClone[guest.id];
        else selectedClone[guest.id] = `${guest.firstName} ${guest.lastName}`;
        this.setState({
            selected: selectedClone,
        });

        if (typeof this.props.onChange === 'function') this.props.onChange(selectedClone);
    };

    render() {
        const { classes, title, body, heading } = this.props;
        const { members, search, selected } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.body} dangerouslySetInnerHTML={parseHtmlJson(body)} />
                <Typography className={classes.heading}>{heading}</Typography>
                <div className={classes.membersSearch}>
                    <Input
                        label={`${Object.keys(selected).length} members selected`}
                        placeholder="Search Members"
                        variant="standard"
                        fullWidth
                        onChange={this.onInput}
                        InputProps={{
                            classes: { root: classes.input },
                            endAdornment: <Search />,
                        }}
                    />
                    <List className={classes.list}>
                        {members
                            .filter((member) => {
                                return `${member.firstName} ${member.lastName}`.includes(search);
                            })
                            .map((user, index) => (
                                <Checkbox
                                    key={user.id}
                                    label={`${user.lastName}, ${user.firstName}`}
                                    classes={{ label: classes.label }}
                                    checked={!!selected[user.id]}
                                    onChange={(e) => this.onChange(user.id)}
                                />
                            ))}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
const mapDispatchToProps = { getAttendees };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Volunteer)));
