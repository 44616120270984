export const SET_EVENT = 'SET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const INIT = {};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_EVENT:
        case GET_EVENT_SUCCESS:
            return payload;
        default:
            return state;
    }
}
