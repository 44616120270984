import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import VolunteerSection from './VolunteerSection';
import VolunteerSections from './VolunteerSections';

const ManageVolunteers = (props) => {
    useEffect(() => {}, []); // update only on page change

    return (
        <Switch>
            <Route exact path="/volunteers/edit/:id" component={VolunteerSections} />
            <Route exact path="/volunteers/edit/:id/step/:stepId" component={VolunteerSection} />
        </Switch>
    );
};

export default ManageVolunteers;
