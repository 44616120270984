const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    btns: {
        display: 'flex',
        opacity: 0.5,
        '& button:first-child': {
            marginRight: 12,
        },
        marginBottom: 20,
    },
    intro: {
        fontSize: 14,
        opacity: 0.5,
        marginBottom: 20,
    },
    selected: {
        marginBottom: 20,
    },
    membersSearch: {
        marginBottom: 25,
    },
    list: {
        flexGrow: 1,
        overflowY: 'auto',
    },
    sendButton: {
        marginBottom: 20,
    },
});

export default styles;
