const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 150,
        margin: '0px 2% 24px',
        padding: 24,
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0 7px 25px rgba(0, 0, 0, 0.12)',
    },
    date: {
        // textAlign: 'left',
        fontSize: 16,
        paddingBottom: 30,
    },
    title: {
        fontSize: 16,
        overflowY: 'hidden',
    },
});

export default styles;
