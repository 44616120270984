import React from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import AddContacts from './AddContacts';
import Contacts from './Contacts';

// styles
import styles from './styles';

const SafetyContact = ({ classes }) => {
    const dispatch = useDispatch();
    function handleError(e) {
        dispatch(setSnackbar(checkError(e)));
    }
    function handleSuccess(message) {
        dispatch(setSnackbar(message));
    }

    const functions = {
        onError: handleError,
        onSuccess: handleSuccess,
    };

    return (
        <div className={classes.root}>
            <PageHeader title={'Safety Contacts'} />
            <MaxWidthContainer padding>
                <AddContacts {...functions} />
                <Contacts {...functions} />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(SafetyContact);
