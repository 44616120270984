import React from 'react';

// Modules
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, Slider } from '@material-ui/core';

// styles
import styles from './styles';

const MaterialSlider = ({ classes, value, leftLabel, rightLabel, title, unit, ...props }) => {
    return (
        <div className={classes.root}>
            <Typography className={classNames(classes.low, classes.light)}>{leftLabel}</Typography>
            <Typography className={classNames(classes.high, classes.light)}>{rightLabel}</Typography>
            <div className={classes.value}>
                <Typography align="center">{`${unit === '%' ? value : value.toFixed(1)} ${unit}`}</Typography>
            </div>
            <Slider
                classes={{
                    rail: classes.rail,
                    track: classes.track,
                    thumb: classes.thumb,
                }}
                onChange={props.onChange}
                min={props.min}
                max={props.max}
                value={value}
            />
            <Typography align="center" className={classes.title}>
                {title}
            </Typography>
        </div>
    );
};

export default withStyles(styles)(MaterialSlider);
