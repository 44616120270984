const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    labelNode: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: 8,
            fontSize: 26,
            opacity: 0.7,
        },
    },
    input: {
        marginTop: 19,
    },
    inputLabel: {
        textDecoration: 'underline',
        color: '#4a4a4a',
    },
});

export default styles;
