// React
import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// Material UI
import { SvgIcon } from '@material-ui/core';

const Whistle = (props) => {
    const { theme } = useSelector(({ theme }) => ({ theme }));

    return (
        <SvgIcon {...props}>
            <g id="Mobile-App---Chair" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Send-Invites---Individual-Members"
                    transform="translate(-296.000000, -26.000000)"
                    fill={theme.palette.primary.contrastText}
                    fillRule="nonzero">
                    <g id="noun_Whistle_1975581" transform="translate(296.000000, 26.000000)">
                        <g id="Group">
                            <path
                                d="M7.09090909,5.704 L4.5,4.554 C4.27272727,4.462 4.18181818,4.186 4.27272727,3.956 C4.36363636,3.68 4.63636364,3.588 4.86363636,3.68 L7.45454545,4.83 C7.68181818,4.922 7.77272727,5.198 7.68181818,5.428 C7.59090909,5.704 7.31818182,5.796 7.09090909,5.704 Z"
                                id="Shape"></path>
                            <path
                                d="M7.5,3.864 L5.54545455,1.748 C5.36363636,1.564 5.36363636,1.288 5.59090909,1.104 C5.77272727,0.92 6.04545455,0.92 6.22727273,1.15 L8.18181818,3.266 C8.36363636,3.45 8.36363636,3.726 8.13636364,3.91 C7.95454545,4.094 7.68181818,4.048 7.5,3.864 Z"
                                id="Shape"></path>
                            <path
                                d="M9.31818182,3.726 L8.27272727,1.058 C8.18181818,0.828 8.31818182,0.552 8.54545455,0.46 C8.77272727,0.368 9.04545455,0.506 9.13636364,0.736 L10.1818182,3.404 C10.2727273,3.634 10.1363636,3.91 9.90909091,4.002 C9.68181818,4.094 9.40909091,3.956 9.31818182,3.726 Z"
                                id="Shape"></path>
                            <path
                                d="M19.5,4.002 L17.3181818,0.69 C17.1818182,0.46 16.9090909,0.414 16.6818182,0.552 L10.7727273,4.554 C10.5454545,4.692 10.5,4.968 10.6363636,5.198 L11.2727273,6.164 L9.90909091,7.084 L8.40909091,6.44 C8.27272727,6.394 8.09090909,6.394 7.95454545,6.486 L5.04545455,8.464 C5,8.464 5,8.51 4.95454545,8.51 C2,10.764 1.09090909,14.72 2.59090909,17.986 C2.27272727,17.986 1.95454545,17.986 1.68181818,18.124 C0.363636364,18.722 -4.4408921e-16,20.424 1.04545455,21.482 C1.63636364,22.08 2.5,22.264 3.27272727,21.942 C3.86363636,21.666 4.31818182,21.16 4.45454545,20.562 C6.95454545,22.862 10.8181818,23.322 13.7727273,21.344 C16.5454545,19.55 18.1818182,15.87 16.8181818,12.006 C16.3636364,10.81 15.6818182,8.97 17.3636364,7.084 L19.5,4.6 C19.5909091,4.416 19.6363636,4.186 19.5,4.002 Z M2.86363636,21.114 C2.45454545,21.298 1.95454545,21.16 1.63636364,20.884 C1.04545455,20.286 1.22727273,19.366 2,19.044 C2.40909091,18.86 2.90909091,18.952 3.22727273,19.274 C3.81818182,19.826 3.59090909,20.792 2.86363636,21.114 Z M13.5454545,18.17 C11.6818182,20.378 8.40909091,20.654 6.22727273,18.768 C4.04545455,16.928 3.72727273,13.57 5.59090909,11.362 C7.45454545,9.154 10.7272727,8.878 12.9090909,10.718 C15.1363636,12.65 15.4090909,15.962 13.5454545,18.17 Z M12.3636364,11.454 C10.5454545,9.936 7.86363636,10.12 6.31818182,11.96 C4.81818182,13.8 5,16.514 6.81818182,18.078 C8.63636364,19.642 11.3636364,19.366 12.8636364,17.572 C14.4090909,15.732 14.1363636,13.018 12.3636364,11.454 Z M11.5909091,13.064 C11.4545455,13.248 11.1363636,13.294 10.9545455,13.11 C10.3636364,12.604 9.5,12.466 8.77272727,12.742 C8.54545455,12.834 8.27272727,12.742 8.18181818,12.466 C8.09090909,12.236 8.18181818,11.96 8.45454545,11.868 C9.5,11.454 10.6818182,11.638 11.5454545,12.374 C11.7272727,12.604 11.7727273,12.88 11.5909091,13.064 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default Whistle;
