import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Utils
import { AllFileTypes } from 'utils/file-types';
// Components
import FileInput from 'components/Input/FileInput';
import BodyText from '../BodyText';
// Styles
import styles from './styles';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.value,
        };
        this.file = this.state.file;
    }
    onChange(file) {
        if (typeof this.props.onChange === 'function') this.props.onChange(file);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.props.title && <Typography className={classes.title}>{this.props.title}</Typography>}
                <BodyText body={this.props.body} />
                <FileInput
                    accept={[...AllFileTypes]}
                    classes={{ uploadRoot: classes.toolbarItem }}
                    file={this.state.file}
                    required={this.props.required}
                    onChange={(file) => {
                        this.setState({ file });
                        this.onChange(file);
                    }}
                />
                {this.props.helperText && (
                    <Typography className={classes.helperText}>{this.props.helperText}</Typography>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(FileUpload);
