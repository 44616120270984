import React from 'react';
// Redux
import { connect } from 'react-redux';
import { add as addContact } from 'redux/actions/contacts';
// Modules
import phone from 'phone';
import Mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash/cloneDeep';
import FormValidator from 'utils/FormValidator';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import Input from 'components/Input';
import Button from 'components/Button';
// styles
import styles from './styles';

// TODO: Change form to Shlorm
class AddContacts extends React.Component {
    INIT = {
        name: { value: '', valid: true },
        phone: { value: '', valid: true },
    };

    constructor(props) {
        super(props);
        this.state = {
            ...cloneDeep(this.INIT),
            submitting: false,
        };
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { onError, onSuccess, addContact } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));
        if (invalidFields.length > 0) {
            return onError(new Error(invalidFields[0].message));
        }

        this.setState({ submitting: true });
        try {
            await addContact({
                name: this.state.name.value,
                phone: phone(this.state.phone.value)[0],
            });
            onSuccess('Contact added');
            this.setState({ ...cloneDeep(this.INIT) });
            Mixpanel.track('Contact added');
        } catch (error) {
            onError(error);
        }
        this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Typography className={classes.text}>
                    Please add the contacts who you would like to be texted when you send a safety alert.
                </Typography>
                <Input
                    ref="name"
                    label="Contact Name"
                    variant="standard"
                    value={this.state.name.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'name',
                            value: e.target.value,
                        })
                    }
                    valid={this.state.name.valid}
                    errorMessage="A name is required"
                    validator={() => this.state.name.value.length > 0}
                    marginBottom={18}
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    }}
                />
                <Input
                    ref="phone"
                    label="Contact Mobile Phone Number"
                    variant="standard"
                    value={this.state.phone.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'phone',
                            value: e.target.value,
                        })
                    }
                    valid={this.state.phone.valid}
                    errorMessage="A valid phone # is required"
                    validator={() => phone(this.state.phone.value).length > 0}
                    marginBottom={18}
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    }}
                />

                <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                <Button
                    variant={'contained'}
                    variantType="containedOuterSpace"
                    color={'primary'}
                    fullWidth={true}
                    loading={this.state.submitting}
                    onClick={this.handleSubmit.bind(this)}
                    text="Add Contact"
                />
            </form>
        );
    }
}

export default connect(null, { addContact })(withStyles(styles)(AddContacts));
