const styles = (theme) => ({
    root: {
        margin: '20px auto',
    },
    square: {
        position: 'relative',
        borderRadius: 6,
        backgroundImage: 'linear-gradient(180deg, #f8f8f8 0%, #efefef 100%)',
        padding: '13px 28px 13px 15px',
    },
    text: {
        opacity: 0.8,
        fontSize: 14,
        fontWeight: 400,
    },
    messageButton: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4a4a4a',
        borderRadius: '50%',
        color: '#ffffff',
        transform: 'translate(23%, 16px)',
        '&:hover': {
            backgroundColor: '#4a4a4a',
        },
    },
    timeStamp: {
        opacity: 0.5,
        color: '#000000',
        fontSize: 14,
        marginTop: 10,
    },
});

export default styles;
