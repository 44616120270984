// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { get as getEventInfo } from 'redux/actions/eventInfo';

// Modules
import moment from 'moment';
import { API } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash/cloneDeep';
import checkError from 'utils/check-error';
import Upload from 'utils/Upload';
import FormValidator from 'utils/FormValidator';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
import DateInput from 'components/Input/Date';
import TimeInput from 'components/Input/Time';
import Button from 'components/Button';
import FullscreenLoader from 'components/FullscreenLoader';

// Styles
import styles from './styles';
import FORM_INIT from './init';

class ChangeEventDate extends React.Component {
    INIT = FORM_INIT;

    constructor(props) {
        super(props);

        const state = cloneDeep(this.INIT);

        // Create mindate at beginning of day because we have to compare date and time field separately
        this.minDate = moment().add(1, 'day').hour(0).minute(0).second(0).millisecond(0);
        if (!props.eventInfo.event.EventType?.bypassApproval) {
            this.minDate.add(props.organization.daysBeforeEventCreate, 'day');
        }

        state.start.value = this.minDate;
        state.end.value = this.minDate;

        // Parameters
        this.eventId = props.match.params.id;
        this.organization = props.cognitoUser.Organization.id;
        this.parent = props.cognitoUser.Organization.ParentId;

        // State
        this.state = {
            ...this.INIT,
            loading: true,
            submitting: false,
            module: {},
            section: {},
            disabled: false,
            eventType: null,
        };
    }
    componentDidMount() {
        this.mounted = true;
        this._getEventInfo();
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async _getEventInfo() {
        this.setState({ loading: true });

        try {
            const { information, EventType } = await API.get(
                'ClutchAPI',
                `/events/${this.eventId}?OrganizationId=${this.organization}&ParentId=${this.parent}`
            );

            const { endDate, startDate, sections } = information;

            const section = sections.filter((section) => section.id === 'dates').pop();

            const eventChangeLimitDate = moment(startDate).subtract(
                this.props.organization.eventDateChangeTimeLimit,
                'day'
            );
            let disabled = false;

            if (moment() > eventChangeLimitDate && !EventType.bypassApproval) {
                disabled = true;
            }

            this.setState({
                loading: false,
                module: information,
                eventType: EventType,
                section,
                start: { value: moment(startDate), valid: true },
                end: { value: moment(endDate), valid: true },
                disabled,
            });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    async handleSubmit(e) {
        const { setSnackbar } = this.props;
        const { start, end } = this.state;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            return setSnackbar(invalidFields[0].message);
        }

        if (end.value.isSameOrBefore(start.value)) {
            this.updateField({ field: 'end', valid: false });
            return setSnackbar('End date/time can not be before start date/time');
        }

        const values = {
            [this.state.section.id]: {
                startDate: moment.utc(start.value.toISOString()),
                endDate: moment.utc(end.value.toISOString()),
            },
        };

        const formData = new FormData();
        formData.append('sections', JSON.stringify(values));

        this.mounted && this.setState({ submitting: true });
        try {
            await Upload.patch(
                `/events/${this.eventId}/modules/${this.state.module.id}?ParentId=${this.parent}&OrganizationId=${this.organization} `,
                formData
            );
            Mixpanel.track('Event date updated, post approval', {
                EventId: this.eventId,
                startDate: start.value.format('YYYY-MM-DD'),
                endDate: end.value.format('YYYY-MM-DD'),
                oldStartTime: this.state.module.startDate,
                oldEndDate: this.state.module.endDate,
            });
            setSnackbar('Event Date Successfully Updated');
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    render() {
        const { classes, organization } = this.props;
        const { disabled, loading, start, end, submitting, eventType } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title={'Change Event Date'} />

                {loading ? (
                    <FullscreenLoader />
                ) : (
                    <MaxWidthContainer padding classes={{ padding: classes.container }}>
                        {disabled ? (
                            <Typography>
                                The ability to change this event's date has expired. You can no longer change the date
                                of this event
                            </Typography>
                        ) : (
                            <Typography>
                                You can change the date of your event without having to get the even re-approved.
                                However, the new event date may occur no earlier than&nbsp;
                                {this.minDate.format('M/D/YYYY')}
                            </Typography>
                        )}
                        <DateInput
                            ref="startDate"
                            disabled={disabled}
                            margin="normal"
                            id="start-date-picker-dialog"
                            label="Start Date*"
                            format="MM/DD/yyyy"
                            minDate={this.minDate}
                            error={!start.valid}
                            errorMessage={
                                start.value < this.minDate
                                    ? `Start date must be at least ${organization.daysBeforeEventCreate} days after today's date`
                                    : 'Start date is required'
                            }
                            validator={() => start.value.isSameOrAfter(this.minDate)}
                            value={start.value}
                            onChange={(date) => {
                                this.updateField({ field: 'start', value: date });
                                // Automatically update the end date if it's not set or has been set to before the start date
                                if (!end.value || end.value < date) {
                                    this.updateField({ field: 'end', value: date });
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change start date',
                            }}
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <TimeInput
                            ref="startTime"
                            disabled={disabled}
                            margin="normal"
                            id="start-time-picker"
                            label="Start Time*"
                            value={start.value}
                            onChange={(date) => {
                                this.updateField({
                                    field: 'start',
                                    value: date,
                                });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change start time',
                            }}
                            error={!start.valid}
                            fullWidth
                            validator={() => {
                                if (!start.value.diff(this.minDate) && !eventType?.bypassApproval) {
                                    const now = moment();
                                    if (now > start.value) return false;
                                }
                                return true;
                            }}
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <DateInput
                            ref="endDate"
                            disabled={disabled}
                            margin="normal"
                            id="end-date-picker-dialog"
                            label="End Date*"
                            format="MM/DD/yyyy"
                            minDate={start.value}
                            error={!end.valid}
                            errorMessage="End date is required"
                            validator={() => !!end.value}
                            value={end.value}
                            onChange={(date) => this.updateField({ field: 'end', value: date })}
                            KeyboardButtonProps={{
                                'aria-label': 'change start date',
                            }}
                            fullWidth
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <TimeInput
                            ref="endTime"
                            disabled={disabled}
                            margin="normal"
                            id="end-time-picker"
                            label="End Time*"
                            value={end.value}
                            onChange={(date) => {
                                this.updateField({
                                    field: 'end',
                                    value: date,
                                    // make sure time is after start time if it's the same day
                                    valid: start.value.isSameOrBefore(end.value, 'date'),
                                });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change end time',
                            }}
                            error={!end.valid}
                            fullWidth
                            validator={() => !!end.value}
                            marginBottom={18}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Button
                            disabled={disabled}
                            fullWidth
                            text={'Save Changes'}
                            variant="contained"
                            loading={submitting}
                            variantType={'containedOuterSpace'}
                            onClick={(e) => this.handleSubmit()}
                        />
                    </MaxWidthContainer>
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ eventInfo, cognitoUser, organization }) => ({
    eventInfo,
    cognitoUser,
    organization,
});
export default connect(mapStateToProps, { getEventInfo, setSnackbar })(withStyles(styles)(ChangeEventDate));
