/** Colors */
export const grey = "#B5BFC9";
export const colors = {
  warningRed: "rgba(235, 104, 104, 1)", // hex #eb6868
  successGreen: "rgba(130, 201, 88, 1.0)", // hex #77b337
  red: "", // hex #eb6868
  error: "rgba(234, 81, 81, 1)", // hex #ea5151
  warning: "rgba(255, 151, 0, 1)", // hex #ff9700
  info: "#2196f3",
  gray: "rgba(226, 226, 226, 1)", // hex #e2e2e2
  white: "#ffffff",
  black: "#000000",
  lightGray: "#f44336",
  mediumGray: "#9da3ac",

  brandColors: {
    outerSpace: "rgba(74,74,74,1.0)", // hex #434f61
    grey: "rgba(151, 151, 151, 1)",
  },
  event: {
    darkGray: "#4a4a4a",
    lightGray: "#979797",
    success: "#7ed321",
    successText: "#64a81a",
    error: "#d35656",
    warning: "#f4ac3f",
  },
};

/** Heights */
export const AppBarHeight = 80;
