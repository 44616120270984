export const SET_EVENT_TYPES = 'SET_EVENT_TYPES';
export const GET_EVENT_TYPES_SUCCESS = 'GET_EVENT_TYPES_SUCCESS';
export const GET_EVENT_TYPES_ERROR = 'GET_EVENT_TYPES_ERROR';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_EVENT_TYPES_SUCCESS:
        case SET_EVENT_TYPES:
            return payload;
        default:
            return state;
    }
}
