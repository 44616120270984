export const SET_TOAST = 'SET_TOAST';
export const INIT = { message: '', severity: 'success' };

export default function (state = INIT, { type, payload }) {
    let newState = Object.assign({}, state);

    switch (type) {
        case SET_TOAST:
            newState = { ...newState, ...payload };
            return newState;
        default:
            return state;
    }
}
