import React, { useState, useEffect } from 'react';
// Modules
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, List } from '@material-ui/core';
import { Search } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Input/Checkbox';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const SendInvites = ({ history, classes, match, ...props }) => {
    const [users, setUsers] = useState({ count: 0, data: [] });
    const [search, setSearch] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selected, setSelected] = useState(new Set());
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector((state) => state);

    useEffect(() => {
        _getUsers();
    }, []);

    const _getUsers = async () => {
        try {
            const users = await API.get(
                'ClutchAPI',
                `/organizations/${cognitoUser.Organization.ParentId}/users?OrganizationId=${cognitoUser.Organization.id}&policies=${policyIdentifiers.MEMBER_APP_LOGIN}`
            );

            setUsers(users);
        } catch (error) {
            dispatch(setSnackbar(checkError(error, 'error')));
        }
    };

    const onChange = (user) => {
        const selectedClone = new Set(selected);
        if (selectedClone.has(user.id)) selectedClone.delete(user.id);
        else selectedClone.add(user.id);

        setSelected(selectedClone);
    };

    const onSelectAll = () => {
        const selectedClone = new Set(selected);
        users.data.forEach((user) => selectedClone.add(user.id));
        setSelected(selectedClone);
    };

    const onSelectNone = () => {
        setSelected(new Set());
    };

    const onInvite = async () => {
        setSubmitting(true);
        try {
            await API.post(
                'ClutchAPI',
                `/events/${match.params.id}/attendees?OrganizationId=${cognitoUser.Organization.id}`,
                {
                    body: {
                        users: Array.from(selected),
                    },
                }
            );
            Mixpanel.track('Event invites sent', { EventId: match.params.id });
            dispatch(setSnackbar('Invites successfully sent'));
        } catch (error) {
            dispatch(setSnackbar(checkError(error), 'error'));
        }
        setSelected(new Set());
        setSubmitting(false);
    };

    return (
        <div className={classes.root}>
            <PageHeader title="Send Invites" />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.btns}>
                    <Button text="Select None" onClick={onSelectNone} fullWidth variantType="outlinedOuterSpace" />
                    <Button text="Select All" onClick={onSelectAll} fullWidth variantType="outlinedOuterSpace" />
                </div>
                <Typography className={classes.intro}>
                    Social events are just one of the membership perks! In order to participate, members must be in good
                    standing. Please do not invite members who are not currently in good standing and/or have had their
                    social privileges revoked.
                </Typography>
                <Button
                    fullWidth
                    onClick={onInvite}
                    classes={{ root: classes.sendButton }}
                    text={`Send Invites to (${selected.size}) Members`}
                    loading={submitting}
                    variantType="containedOuterSpace"
                />
                <Typography className={classes.selected}>{`${selected.size} member(s) selected`}</Typography>
                <div className={classes.membersSearch}>
                    <Input
                        label={`${users.count} total members`}
                        placeholder="Search Members"
                        variant="standard"
                        fullWidth
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: <Search />,
                        }}
                    />
                    <List className={classes.list}>
                        {users.data
                            .filter((user) =>
                                `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase())
                            )
                            .map((user) => (
                                <Checkbox
                                    key={user.id}
                                    label={`${user.lastName}, ${user.firstName}`}
                                    checked={selected.has(user.id)}
                                    onChange={() => onChange(user)}
                                />
                            ))}
                    </List>
                </div>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(SendInvites);
