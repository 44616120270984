// React
import React, { useState } from 'react';
// Modules
import { API } from 'aws-amplify';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setToast } from 'redux/actions/toast';
// Mui-Core
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, AppBar, Toolbar, IconButton, Slide } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
// Components
import Timeline from 'components/Timeline';
import MaxWidthContainer from 'components/MaxWidthContainer';
import EventCommentInput from 'components/EventCommentInput';
// Utils
import checkError from 'utils/check-error';

import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CommentGroup = ({ classes, dispatch, section, cognitoUser, ...props }) => {
    const [comments, setComments] = useState(section.review.comments);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const titleMarkup = section.markup.find((markup) => markup.required) ?? section.markup[0] ?? {};

    async function onSubmit() {
        setLoading(true);
        try {
            await API.patch(
                'ClutchAPI',
                `/events/${props.eventId}/modules/${props.eventModuleId}/section-comment/${section.id}?OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`,
                {
                    body: {
                        comment: input,
                    },
                }
            );
            setComments([
                ...comments,
                { date: new Date(), comment: input, name: cognitoUser.firstName + cognitoUser.lastName },
            ]);
            setInput('');
        } catch (err) {
            dispatch(
                setToast({
                    message: checkError(err),
                })
            );
        }
        setLoading(false);
    }

    return (
        <div className={classes.group}>
            <Typography className={classes.heading}>{titleMarkup.title ?? titleMarkup.dateTitle}</Typography>
            <Timeline classes={{ timelineRoot: classes.timelineRoot }} comments={comments} />
            <EventCommentInput value={input} loading={loading} onChange={setInput} onSubmit={onSubmit} />
        </div>
    );
};

const CommentDialog = ({ classes, open, step, name, setOpen, ...props }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Dialog
                PaperProps={{ classes: { root: classes.root } }}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar elevation={0} className={classes.appBar}>
                    <Toolbar classes={{ regular: classes.toolbar }}>
                        <div className={classes.container}>
                            <IconButton
                                className={classes.backButton}
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close">
                                <ArrowBackIos />
                            </IconButton>
                            <div className={classes.title}>{name}</div>
                            <Button
                                classes={{ label: classes.buttonLabel }}
                                className={classes.closeButton}
                                autoFocus
                                color="inherit"
                                onClick={handleClose}>
                                close
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
                <MaxWidthContainer className={classes.comments}>
                    {step.sections
                        .filter((section) => section.review?.comments.length)
                        .map((section, idx) => (
                            <CommentGroup
                                key={idx}
                                classes={classes}
                                section={section}
                                eventId={step.EventId}
                                eventModuleId={step.id}
                                cognitoUser={cognitoUser}
                                dispatch={dispatch}
                            />
                        ))}
                </MaxWidthContainer>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(CommentDialog);
