import { combineReducers } from 'redux';

// Import reducers here
import snackbar from './snackbar';
import toast from './toast';
import cognitoUser from './cognitoUser';
import theme from './theme';
import organization from './organization';
import upcomingEvents from './upcomingEvents';
import pastEvents from './pastEvents';
import eventTypes from './eventTypes';
import event from './event';
import contacts from './contacts';
import categories from './categories';
import rules from './rules';
import safetyAlerts from './safetyAlerts';
import beverageEducation from './beverageEducation';
import attendees from './attendees';
import eventInfo from './eventInfo';

const rootReducer = combineReducers({
    snackbar,
    toast,
    cognitoUser,
    theme,
    organization,
    upcomingEvents,
    pastEvents,
    eventTypes,
    event,
    contacts,
    categories,
    safetyAlerts,
    rules,
    beverageEducation,
    attendees,
    eventInfo,
});

export default rootReducer;
