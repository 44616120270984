import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { set } from 'redux/actions/beverageEducation';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, MenuItem } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

// components
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Input/Select';
import Tooltip from 'components/Tooltip';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// styles
import styles from './styles';

const SetInfo = ({ classes, ...props }) => {
    const [weight, setWeight] = useState(110.0);
    const [gender, setGender] = useState('female');
    const dispatch = useDispatch();

    async function onNext() {
        dispatch(set({ weight, gender }));
        props.history.push('/beverage-education');
    }

    return (
        <>
            <PageHeader title="Beverage Education" />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.inputs}>
                    <Typography>
                        To track your drinking, first we'll need your weight and gender. This goes toward determining
                        how your body processes alcohol.
                    </Typography>
                    <Select
                        id="demo-simple-select"
                        className={classes.input}
                        marginBottom={19}
                        fullWidth
                        label="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        InputLabelProps={{
                            classes: { root: classes.inputLabel },
                        }}>
                        <MenuItem value={'female'}>Female</MenuItem>
                        <MenuItem value={'male'}>Male</MenuItem>
                    </Select>
                    <Tooltip
                        title={
                            'Please select your biological birth sex to improve accuracy in determining estimates of your Blood Alcohol Content'
                        }
                        open={true}>
                        <Input
                            label={
                                <div className={classes.labelNode}>
                                    <Typography>Weight (lbs)</Typography>
                                    <InfoOutlined />
                                </div>
                            }
                            type="number"
                            variant="standard"
                            placeholder="Enter weight in lbs"
                            fullWidth={true}
                            InputLabelProps={{
                                classes: { root: classes.inputLabel },
                            }}
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                        />
                    </Tooltip>
                </div>
                <Button
                    variant="contained"
                    variantType="containedOuterSpace"
                    color={'primary'}
                    fullWidth={true}
                    text="Next"
                    onClick={onNext}
                />
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(SetInfo);
