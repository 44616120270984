import { normalize, schema } from 'normalizr';
export const SET_UPCOMING_EVENTS = 'SET_UPCOMING_EVENTS';
export const GET_UPCOMING_EVENTS_ERROR = 'GET_UPCOMING_EVENTS_ERROR';
export const GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_UPCOMING_EVENTS_SUCCESS:
            return payload;
        case SET_UPCOMING_EVENTS:
            return payload;
        default:
            return state;
    }
}

const owner = new schema.Entity('owner');
const event = new schema.Entity('event', {
    Owner: owner,
});
const eventSchema = { data: [event] };
export const normalizedEvents = (data) => normalize(data, eventSchema);
