import React from 'react';

// Modules
import { API } from 'aws-amplify';
import FormValidator from 'utils/FormValidator';
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';
import phoneValidator from 'phone';
import Mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { get as getEventInfo } from 'redux/actions/eventInfo';
import { set as setEventInfo } from 'redux/actions/eventInfo';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';
import Input from 'components/Input';

// INIT
import FORM_INIT from './init';

// styles
import styles from './styles';

class InviteGuest extends React.Component {
    INIT = FORM_INIT;
    state = {
        submitting: false,
        disabled: true,
        ...cloneDeep(this.INIT),
    };

    async componentDidMount() {
        this.mounted = true;
        const { organization, match, history, setSnackbar, getEventInfo, eventInfo } = this.props;

        if (!eventInfo.event.id) {
            try {
                eventInfo = await getEventInfo(match.params.id);
            } catch (error) {
                setSnackbar(checkError(error));
                history.goBack();
            }
        }

        if (eventInfo.invites.length < organization.maxGuestAllowed) this.setState({ disabled: false });
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { id } = this.props.match.params;
        const { organization, setSnackbar, cognitoUser, eventInfo, setEventInfo } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            const body = {
                phone: phoneValidator(this.state.phone.value)[0],
                firstName: this.state.firstName.value,
                lastName: this.state.lastName.value,
            };
            const attendee = await API.post(
                'ClutchAPI',
                `/events/${id}/attendees/invite?OrganizationId=${cognitoUser.Organization.id}`,
                { body }
            );
            Mixpanel.track('Guest invited', { ...body, EventId: id });

            this.setState({ ...cloneDeep(this.INIT) });
            const eventInfoClone = cloneDeep(eventInfo);
            eventInfoClone.invites.push(attendee);
            if (eventInfoClone.invites.length >= organization.maxGuestAllowed) this.setState({ disabled: true });
            setEventInfo(eventInfoClone);
            setSnackbar('Your invite has been sent');
        } catch (error) {
            setSnackbar(checkError(error, 'error'));
        }
        this.mounted && this.setState({ submitting: false });
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes, history, match } = this.props;
        const { firstName, lastName, phone, disabled } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title="Invite Guest" />
                <MaxWidthContainer padding>
                    <Button
                        fullWidth
                        classes={{ root: classes.manage }}
                        variantType="outlinedOuterSpace"
                        text="Manage Invited Guests"
                        onClick={() => history.push(`/event-view/${match.params.id}/manage-invited-guests`)}
                    />
                    {disabled ? (
                        <Typography variant="h6" className={classes.title}>
                            You have already invited the maximum amount of guests to this event.
                        </Typography>
                    ) : (
                        <Typography variant="h6" className={classes.title}>
                            Choose a friend from your contact list or directly enter their information.
                        </Typography>
                    )}
                    <form onSubmit={this.handleSubmit}>
                        <Input
                            ref="firstName"
                            label="Guest First Name"
                            disabled={disabled}
                            variant="standard"
                            value={firstName.value}
                            validator={() => !!firstName.value}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'firstName',
                                    value: e.target.value,
                                })
                            }
                            errorMessage="Please enter your first name"
                            valid={firstName.valid}
                            error={!firstName.valid}
                            marginBottom={18}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Input
                            ref="lastName"
                            label="Guest Last Name"
                            disabled={disabled}
                            variant="standard"
                            value={lastName.value}
                            validator={() => !!lastName.value}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'lastName',
                                    value: e.target.value,
                                })
                            }
                            errorMessage="Please enter your last name"
                            valid={lastName.valid}
                            error={!lastName.valid}
                            marginBottom={18}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <Input
                            ref="phone"
                            label="Guest Mobile Number"
                            disabled={disabled}
                            type="tel"
                            variant="standard"
                            value={phone.value}
                            validator={() => phoneValidator(phone.value).length > 0}
                            onChange={(e) =>
                                this.updateField({
                                    field: 'phone',
                                    value: e.target.value,
                                })
                            }
                            errorMessage="Please enter your phone number"
                            valid={phone.valid}
                            error={!phone.valid}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.inputLabel },
                            }}
                        />
                        <input ref="formSubmit" type="submit" style={{ display: 'none' }} />
                        <Button
                            classes={{ root: classes.button }}
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth={true}
                            text="Send Invite"
                            loading={this.state.submitting}
                            onClick={this.handleSubmit.bind(this)}
                        />
                    </form>
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser, organization, eventInfo }) => ({ cognitoUser, organization, eventInfo });

export default connect(mapStateToProps, { setSnackbar, setEventInfo, getEventInfo })(withStyles(styles)(InviteGuest));
