import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Modules
import dayjs from 'dayjs';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from 'components/Button';

// Styles
import styles from './styles';

// Assets
import imagePlaceholder from 'assets/eventPlaceholder.png';
import { TIMEZONE_MAP } from 'global-constants';

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const EventHeader = ({ classes, event, rsvp, roles = [], onRsvpClicked, ...props }) => {
    const { theme } = useSelector(({ theme }) => ({ theme }));

    const [isPlaceholder, setIsPlaceholder] = useState(false);

    const roleTitles = roles.map((role) => role.EventModule.name).join(', ');

    function getSource() {
        if (event.information.photo) return event.information.photo.url;
        if (theme.custom.event && theme.custom.event.photo) return theme.custom.event.photo;

        if (!isPlaceholder) setIsPlaceholder(true);
        return imagePlaceholder;
    }

    const imgClasses = [classes.photo];
    if (isPlaceholder) imgClasses.push(classes.placeholder);

    return (
        <div className={classes.header}>
            <img src={getSource()} alt="event-header" className={imgClasses.join(' ')} />
            <div className={classes.titles}>
                <div className={classes.titlesLeft}>
                    <Typography variant="h3">{event.information.title}</Typography>
                    {event.information.timezone ? (
                        <Typography variant="subtitle2">
                            {dayjs(event.information.startDate).format('h:mm a')}
                            {' - '}
                            {dayjs(event.information.endDate).format('h:mm a')} (
                            {TIMEZONE_MAP[event.information.timezone].abbv})
                        </Typography>
                    ) : null}
                    <Typography variant="subtitle1">{event.information.venue}</Typography>
                    <Typography variant="subtitle1">{event.information.venuePhone}</Typography>
                    <Typography variant="subtitle1">{event.information.location}</Typography>
                    {event.information.url && (
                        <a className={classes.link} target="_blank" href={event.information.url} variant="subtitle1">
                            Event URL: {event.information.url}
                        </a>
                    )}
                </div>
                <div className={classes.titlesRight}>
                    <Typography className={classes.date}>
                        {dayjs(event.information.startDate).format('MMMM').toUpperCase()}
                    </Typography>
                    <Typography className={classes.day}>{dayjs(event.information.startDate).format('DD')}</Typography>
                </div>
            </div>
            <div className={classes.roles}>
                <Typography className={classes.rolesText}>My Volunteer Roles: {roleTitles}</Typography>
            </div>
            <Button
                fullWidth
                text={rsvp === 'yes' ? 'Cancel RSVP' : 'Start RSVP'}
                variant="contained"
                variantType={rsvp === 'yes' ? 'containedWarning' : 'containedOuterSpace'}
                onClick={onRsvpClicked}
                loading={props.btnLoading}
            />
        </div>
    );
};

export default withStyles(styles)(EventHeader);
