import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import EventStepper from 'components/EventStepper';
// styles
import styles from './styles';

const SkeletonEventStep = ({ classes, items = 4, ...props }) => {
    return (
        <div className={classes.root}>
            <EventStepper progress={0} leftButton={false} rightButton={false} />
            <div className={classes.container}>
                <List>
                    {Array.from(new Array(items)).map((item, index) => {
                        return (
                            <ListItem classes={{ root: classes.root }} key={`skele-list-${index}`}>
                                <ListItemText
                                    primary={<Skeleton variant="rect" />}
                                    secondary={<Skeleton variant="rect" />}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </div>
    );
};

export default withStyles(styles)(SkeletonEventStep);
