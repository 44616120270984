import React, { useState, useRef, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
// Modules
import dayjs from 'dayjs';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Styles
import style from './styles';

const EventSquare = ({ event, classes, empty, ...props }) => {
    const [showEllipsis, setShowEllipsis] = useState(false);
    const titleRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (titleRef.current.clientHeight > 38) {
            titleRef.current.style.maxHeight = '38px';
            setShowEllipsis(true);
        }
    }, []);

    function onClick() {
        if (event && event.reviewStatus.includes('approved')) history.push(`/event-view/${event.id}`);
        else event && history.push(`/event/edit/${event.id}`);
    }

    const ellipsis = <div>...</div>;

    return (
        <Paper className={classes.root} onClick={onClick} {...props}>
            <div className={classes.body}>
                <div className={classes.date}>
                    {empty ? 'No Upcoming Events' : dayjs(event.information.startDate).format('MMMM, DD')}
                </div>
                <div className={classes.title} ref={titleRef}>
                    {empty ? 'Please check back later' : event.information.title}
                </div>
                {showEllipsis && ellipsis}
            </div>
        </Paper>
    );
};

export default withStyles(style)(EventSquare);
