const styles = (theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },

    container: {
        padding: 20,
        margin: 'auto',
        maxWidth: 600,
        position: 'relative',
        boxSizing: 'border-box',
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    heading: {
        fontSize: 18,
        marginTop: 10,
    },
    body: {
        opacity: 0.5,
        color: '#000000',
        fontSize: 14,
    },
});

export default styles;
