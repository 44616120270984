const styles = (theme) => ({
    root: {
        flex: 1,
    },
    text: {
        margin: '20px 0px',
        color: '#222222',
        fontSize: 16,
        fontWeight: 400,
    },
    subtext: {
        marginTop: 12,
        marginBottom: 24,
        color: '#4a4a4a',
        fontSize: 11,
        fontWeight: 400,
    },
    inputLabel: {
        textDecoration: 'underline',
    },
    locationAdornment: {
        marginRight: 15,
    },
});

export default styles;
