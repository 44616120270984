import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import {
    INIT,
    SET_CONTACTS,
    GET_CONTACTS_ERROR,
    GET_CONTACTS_SUCCESS,
    ADD_CONTACTS_ERROR,
    ADD_CONTACTS_SUCCESS,
    REMOVE_CONTACTS_SUCCESS,
    REMOVE_CONTACTS_ERROR,
} from 'redux/reducers/contacts';
import checkError from 'utils/check-error';

export const remove = (contact) => async (dispatch, getState) => {
    const { cognitoUser, contacts } = cloneDeep(getState());
    try {
        await API.del('ClutchAPI', `/personal/contacts/${contact.id}?UserId=${cognitoUser.id}`);
        contacts.count--;
        contacts.data = contacts.data.filter((c) => c.id !== contact.id);

        dispatch({
            payload: contacts,
            type: REMOVE_CONTACTS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REMOVE_CONTACTS_ERROR,
        });
        throw error;
    }
};

export const add = (contact) => async (dispatch, getState) => {
    const { cognitoUser, contacts } = cloneDeep(getState());
    try {
        const response = await API.post('ClutchAPI', `/personal/contacts?UserId=${cognitoUser.id}`, {
            body: contact,
        });
        contacts.count++;
        contacts.data.push(response);

        dispatch({
            payload: contacts,
            type: ADD_CONTACTS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ADD_CONTACTS_ERROR,
        });
        throw error;
    }
};

export const get = () => async (dispatch, getState) => {
    const { cognitoUser, contacts } = cloneDeep(getState());
    dispatch(set({ ...contacts, fetching: true }));
    try {
        const response = await API.get('ClutchAPI', `/personal/contacts?UserId=${cognitoUser.id}`);
        // const data = normalizedEvents(response);

        dispatch({
            payload: { ...response, fetching: false },
            type: GET_CONTACTS_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...contacts,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_CONTACTS_ERROR,
        });
        throw error;
    }
};

export const set = (contacts) => {
    if (contacts) {
        return {
            type: SET_CONTACTS,
            payload: contacts,
        };
    }
    // Empty contacts in store if no contacts is sent
    return {
        type: SET_CONTACTS,
        payload: cloneDeep(INIT),
    };
};
