const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 150,
        margin: '0px 2% 24px',
        padding: 24,
        textAlign: 'center',
        cursor: 'pointer',
        // boxShadow: "0 7px 25px rgba(0, 0, 0, 0.12)",
        flex: 1,
    },
    image: {
        width: '100%',
        borderRadius: 4,
        paddingBottom: 20,
    },
    date: {
        width: '70%',
        height: 18,
        fontSize: 16,
        paddingBottom: 30,
    },
    title: {
        width: '100%',
        fontSize: 16,
        height: 18,
    },
    rsvp: {
        marginBottom: 20,
        marginTop: 10,
    },
    menu: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    menuSquare1: {
        margin: '0px 0px 15px 0px',
        flexBasis: '49%',
    },
    menuSquare2: {
        margin: '0px 0px 15px 0px',
        flexBasis: '49%',
    },
});

export default styles;
