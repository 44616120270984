const styles = (theme) => ({
    container: {
        padding: 0,
    },
    listItem: {
        padding: '20px 28px',
        '& img': {
            margin: 'auto',
        },
        '& *': {
            pointerEvents: 'none',
        },
    },
    title: {
        marginLeft: 30,
    },
    chevron: {
        opacity: 0.5,
    },
});

export default styles;
