const DRINK_TYPES = {
    beer: {
        name: 'Beer',
        abv: {
            start: 5,
            min: {
                value: 3,
                label: 'Light Beer',
            },
            max: {
                value: 10,
                label: 'Heavy IPA',
            },
        },
        volume: {
            start: 12,
            min: {
                value: 12,
                label: 'Can, Bottle',
            },
            max: {
                value: 32,
                label: 'Das Boot',
            },
        },
    },
    wine: {
        name: 'Wine',
        abv: {
            start: 12,
            min: {
                value: 6,
                label: 'Wine Cooler',
            },
            max: {
                value: 17,
                label: 'Sherry',
            },
        },
        volume: {
            start: 5,
            min: {
                value: 4,
                label: 'Standard',
            },
            max: {
                value: 25,
                label: 'Bottle',
            },
        },
    },
    liquor: {
        name: 'Liquor',
        abv: {
            start: 30,
            min: {
                value: 20,
                label: 'Liqueur',
            },
            max: {
                value: 40,
                label: 'Liquor',
            },
        },
        volume: {
            start: 2,
            min: {
                value: 1.5,
                label: 'Standard',
            },
            max: {
                value: 3,
                label: 'Double',
            },
        },
    },
};

export default DRINK_TYPES;
