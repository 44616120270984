const styles = (theme) => ({
    root: {
        display: 'flex',
        margin: '60px 0 20px 0',
    },
    safeButton: {
        marginRight: 11,
    },
});

export default styles;
