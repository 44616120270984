import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import {
    INIT,
    SET_UPCOMING_EVENTS,
    GET_UPCOMING_EVENTS_ERROR,
    GET_UPCOMING_EVENTS_SUCCESS,
} from 'redux/reducers/upcomingEvents';
import queryString from 'query-string';
import checkError from 'utils/check-error';

export const get = (options = {}) => async (dispatch, getState) => {
    const { cognitoUser, upcomingEvents } = cloneDeep(getState());
    dispatch(set({ ...upcomingEvents, fetching: true }));

    options.ParentId = cognitoUser.Organization.ParentId;
    options.OrganizationId = cognitoUser.Organization.id;
    const query = queryString.stringify(options);

    const path = `/users/current/upcoming-events?${query}`;
    try {
        const response = await API.get('ClutchAPI', path);
        // const data = normalizedEvents(response);

        dispatch({
            payload: { ...response, fetching: false },
            type: GET_UPCOMING_EVENTS_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...upcomingEvents,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_UPCOMING_EVENTS_ERROR,
        });
        throw error;
    }
};

export const set = (upcomingEvents) => {
    if (upcomingEvents) {
        return {
            type: SET_UPCOMING_EVENTS,
            payload: upcomingEvents,
        };
    }
    // Empty upcomingEvents in store if no upcomingEvents is sent
    return {
        type: SET_UPCOMING_EVENTS,
        payload: cloneDeep(INIT),
    };
};
