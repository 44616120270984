const styles = (theme) => ({
    root: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    pageHeader: {
        paddingBottom: 135,
    },
    progressBar: {
        marginTop: -125,
    },
    title: {
        color: '#252625',
        margin: '25px 0 15px 0',
        fontWeight: 700,
    },
    disclaimer: {
        opacity: 0.7,
        color: '#5e5e5e',
        fontSize: 12,
    },
});

export default styles;
