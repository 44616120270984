const styles = (theme) => ({
    root: {
        flex: 1,
    },
    listItem: {
        // color: "white",
        minHeight: 55,
        // backgroundColor
        borderBottom: '1px solid #5c5c5c',
    },
    list: {
        padding: 0,
    },
    forwardIcon: {
        marginRight: 4,
        fontSize: 14,
    },
});

export default styles;
