import React from 'react';
// Modules
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

const MaxWidthContainer = ({ classes, className, padding, children }) => {
    const rootClass = classNames({
        [classes.root]: true,
        [className]: true,
        [classes.padding]: padding,
    });

    return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(MaxWidthContainer);
