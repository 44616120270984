import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// API
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
// Mui-Icons
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
// Components
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import Skeleton from 'components/Skeleton';
// Styles
import styles from './styles';

class Selects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: {},
            selects: this.props.selections || [],
            selected: this.props.value || '',
            loading: true,
        };
        this.orgParentId = this.props.cognitoUser.Organization.ParentId || '';
        this.versionIds = this.state.selects.map((select) => select.moduleVersionId) || [];
    }

    componentDidMount() {
        this._getTemplateNames();
    }

    async _getTemplateNames() {
        const { setSnackbar } = this.props;
        try {
            const response = await API.get(
                'ClutchAPI',
                `/organizations/${this.orgParentId}/event-module-templates?versionIds=${this.versionIds}&isLatestVersion=1`
            );
            const templates = {};
            response.data.forEach((template) => (templates[template.versionId] = template.name));

            this.setState({
                loading: false,
                templates,
            });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    handleTypeClicked(moduleVersionId) {
        this.setState({ selected: moduleVersionId });
        this.props.onChange(moduleVersionId);
    }
    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        return (
            <div className={classes.root}>
                {!!loading ? (
                    <Skeleton type="list" items={3} />
                ) : (
                    <List dense className={classes.list}>
                        {this.state.selects.map((section, index) => {
                            return section.active ? (
                                <ListItem
                                    key={`option-${index}`}
                                    button
                                    className={classes.listItem}
                                    onClick={() => this.handleTypeClicked(section.moduleVersionId)}>
                                    <ListItemText
                                        primary={this.state.templates[section.moduleVersionId]}
                                        classes={{ primary: classes.title }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.handleTypeClicked(section.moduleVersionId)}>
                                            {this.state.selected === section.moduleVersionId ? (
                                                <CheckCircle className={classes.listIconActive} />
                                            ) : (
                                                <RadioButtonUnchecked className={classes.listIcon} />
                                            )}
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ) : null;
                        })}
                    </List>
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(Selects));
