import cloneDeep from 'lodash/cloneDeep';
import { INIT, SET_ORGANIZATION } from 'redux/reducers/organization';

export const set = (organization) => {
    if (organization) {
        return {
            type: SET_ORGANIZATION,
            payload: organization,
        };
    }
    // Empty user in store if no user is sent
    return {
        type: SET_ORGANIZATION,
        payload: cloneDeep(INIT),
    };
};
