const init = {
    date: {
        value: '',
        valid: true,
    },
    location: {
        value: '',
        valid: true,
    },
    information: {
        value: '',
        valid: true,
    },
    witnesses: {
        value: '',
        valid: true,
    },
    witnessed: {
        value: false,
        valid: true,
    },
    file: {
        value: '',
        valid: true,
    },
};

export default init;
