import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import Upload from 'utils/Upload';
import checkError from 'utils/check-error';
import cloneDeep from 'lodash/cloneDeep';
import Mixpanel from 'mixpanel-browser';

// Mui-Core
import { withStyles } from '@material-ui/core/styles';
// Mui-Icons
// Custom Components
import EventStepViewer from 'components/EventStepViewer';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';

// Styles
import styles from './styles';

class VolunteerSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            step: { sections: [] },
            sections: {},
        };
        this.componentValues = {};
        this.eventId = props.match.params.id;
        this.stepId = props.match.params.stepId;
    }
    componentDidMount() {
        this._getEventInfo();
    }
    async _getEventInfo() {
        const { OrganizationId, ParentId } = this.props;
        this.setState({ loading: true });
        try {
            const response = await API.get(
                'ClutchAPI',
                `/events/${this.eventId}/modules/?markupType=volunteer&OrganizationId=${OrganizationId}&ParentId=${ParentId}`
            );
            response.forEach((volunteerModule) => {
                if (parseInt(volunteerModule.id) === parseInt(this.stepId)) {
                    this.setState({
                        loading: false,
                        step: volunteerModule,
                    });
                }
            });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    handleChangeItem = (section, markup, updatedValue) => {
        if (section.id) {
            this.componentValues[section.id] = {
                ...this.componentValues[section.id],
                [markup.id]: updatedValue,
            };
        } else {
            this.componentValues[section.id] = {
                ...this.componentValues[section.id],
                [markup.id]: updatedValue,
            };
        }
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const sectionsClone = cloneDeep(this.state.sections);
            Object.keys(this.componentValues).forEach((sectionId) => {
                if (sectionsClone[sectionId]) sectionsClone[sectionId].values = this.componentValues[sectionId];
                else {
                    sectionsClone[sectionId] = {
                        ...this.section,
                        values: this.componentValues[sectionId],
                    };
                }
            });
            this.setState({ sections: sectionsClone });
        }, 1000);
    };

    async submitChanges() {
        const { OrganizationId, ParentId } = this.props;
        const formData = new FormData();
        this.state.step.sections.forEach((section) => {
            if (!this.componentValues[section.id]) return; // No values for this section
            section.markup.forEach((markup) => {
                if (!this.componentValues[section.id][markup.id]) return; // No values for this markup

                // If fileupload, append the file to formData
                if (markup.type === 'fileUpload') {
                    formData.append(`${markup.id}`, this.componentValues[section.id][markup.id]);
                }
            });
        });
        console.log(this.componentValues);
        formData.append('sections', JSON.stringify(this.componentValues));

        this.setState({ submitting: true });
        try {
            const response = await Upload.patch(
                `/events/${this.eventId}/modules/${this.stepId}?ParentId=${ParentId}&OrganizationId=${OrganizationId}`,
                formData
            );
            this.props.setSnackbar('successfully saved');
            Mixpanel.track('Volunteers Managed');
        } catch (error) {
            this.props.setSnackbar(checkError(error));
        }
        this.setState({ submitting: false });
    }

    render() {
        console.log(this.state);
        const { classes } = this.props;
        const { step } = this.state;

        return (
            !this.state.loading && (
                <div className={classes.root}>
                    <PageHeader title={this.state.step.name} />

                    <MaxWidthContainer padding classes={{ padding: classes.container }}>
                        {step.sections.map((section, index) => {
                            return (
                                <div key={`section-${index}`} className={classes.section}>
                                    <EventStepViewer
                                        module={this.state.step}
                                        onChangeItem={(markup, values) =>
                                            this.handleChangeItem(section, markup, values)
                                        }
                                        sections={section}
                                    />
                                </div>
                            );
                        })}
                        <Button
                            fullWidth
                            text={'Save Changes'}
                            variant="contained"
                            variantType={'containedOuterSpace'}
                            onClick={this.submitChanges.bind(this)}
                        />
                    </MaxWidthContainer>
                </div>
            )
        );
    }
}
const mapStateToProps = ({ cognitoUser }) => ({
    cognitoUser,
    OrganizationId: cognitoUser.Organization.id,
    ParentId: cognitoUser.Organization.ParentId,
});
export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(VolunteerSection));
