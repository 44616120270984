import React, { useEffect, useState } from 'react';
// Modules
import classNames from 'classnames';
import { API } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { get as getEventInfo, set as setEventInfo } from 'redux/actions/eventInfo';
import BasicDialog from 'components/Dialogs/BasicDialog/BasicDialog';
// Utilities
import { filterMenuTiles } from 'utils/render-utils';
import { mapUserEligibility } from 'utils/eligibility-utils';
import { MENU_SQUARE_MAP } from './menus';
// Modules
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// components
import Skeleton from 'components/Skeleton';
import MenuSquare from 'components/MenuSquare';
import MaxWidthContainer from 'components/MaxWidthContainer';
import EventHeader from './EventHeader';
import EventInformation from './EventInformation';

// styles
import styles from './styles';

const EventView = ({ classes, match, ...props }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [checkout, setCheckout] = useState(false);

    const { cognitoUser, eventInfo } = useSelector(({ cognitoUser, eventInfo }) => ({
        cognitoUser,
        eventInfo,
    }));
    const { event, attendee, roles } = eventInfo;
    const mounted = React.useRef(false);

    const userEligibility = mapUserEligibility(cognitoUser, eventInfo); // Combine Cognito User data & Event Data to determins users current state
    const menuItemsToShow = filterMenuTiles(userEligibility); // Filters Menu Items a user is eligible for.

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getEvent() {
            mounted && setLoading(true);
            try {
                await dispatch(getEventInfo(match.params.id));
                mounted && setLoading(false);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.push('/');
            }
        }
        _getEvent();
    }, [dispatch, match.params.id, props.history]);

    async function handleCheckout() {
        Mixpanel.track('User checked out of event', { id: event.id, event: event.information.title });
        try {
            await API.patch('ClutchAPI', `/events/${match.params.id}/attendees/${attendee.id}/check-out`);
            dispatch(setSnackbar('You have been checked out from the event', event.information.title));
            props.history.push('/');
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
    }

    const onRsvpClicked = async () => {
        if (attendee.rsvp === 'yes') {
            Mixpanel.track('Cancel RSVP Clicked', {
                id: event.id,
                event: event.information.title,
                date: new Date(),
            });
            props.history.push({
                pathname: `${props.location.pathname}/cancel-rsvp`,
                state: {
                    attendee,
                },
            });
        } else {
            Mixpanel.track('RSVP Clicked', {
                id: event.id,
                event: event.information.title,
                date: new Date(),
            });
            if (event?.EventType?.bypassApproval) {
                mounted.current && setBtnLoading(true);
                try {
                    const newAttendee = await API.patch(
                        'ClutchAPI',
                        `/events/${match.params.id}/attendees/${attendee.id}/rsvp`,
                        {
                            body: {
                                response: 'yes',
                            },
                        }
                    );
                    Mixpanel.track('RSVP finished');
                    dispatch(setSnackbar('RSVP successfully sent'));
                    dispatch(setEventInfo({ ...eventInfo, attendee: newAttendee }));
                } catch (error) {
                    dispatch(setSnackbar(checkError(error)));
                }
                return mounted.current && setBtnLoading(false);
            }

            if (cognitoUser.birthDate)
                props.history.push({
                    pathname: `${props.location.pathname}/house-rules`,
                    state: {
                        attendee,
                    },
                });
            else
                props.history.push({
                    pathname: `${props.location.pathname}/birthday`,
                    state: {
                        attendee,
                    },
                });
        }
    };

    return loading ? (
        <MaxWidthContainer padding classes={{ padding: classes.container }}>
            <Skeleton type="eventView" classes={{ root: classes.skeleton }} />
        </MaxWidthContainer>
    ) : (
        <MaxWidthContainer padding classes={{ padding: classes.container }}>
            <BasicDialog
                open={checkout}
                title="Check Out"
                subtitle="Are you sure you would like to checkout?"
                onClose={() => setCheckout(false)}
                onSubmit={handleCheckout}
                rightButton={{ text: 'Yes' }}
            />
            <div className={classes.root}>
                <EventHeader
                    event={event}
                    rsvp={attendee.rsvp}
                    roles={roles}
                    onRsvpClicked={onRsvpClicked}
                    btnLoading={btnLoading}
                />
                <div className={classes.menu}>
                    {menuItemsToShow.map((menuSquare, index) => {
                        const { basepath, path, params, action } = MENU_SQUARE_MAP[menuSquare];
                        const rootClass = classNames({
                            [classes.menuSquare1]: true,
                            [classes.menuSquare2]: (index + 1) % 2 === 0,
                        });

                        return (
                            <React.Fragment key={`menuitem-${index}`}>
                                {userEligibility.auth.isRsvp || userEligibility.auth.isCreator ? (
                                    <MenuSquare
                                        classes={{ root: rootClass }}
                                        onClick={() => {
                                            Mixpanel.track(`Event Menu clicked ${MENU_SQUARE_MAP[menuSquare].text}`, {
                                                event: event.information.title,
                                                id: event.id,
                                            });
                                            if (action === 'checkout') {
                                                setCheckout(true);
                                            } else if (basepath === 'true')
                                                props.history.push(
                                                    `${path}/${params === 'true' ? match.params.id : ''}`
                                                );
                                            else props.history.push(`${props.location.pathname}${path || ''}`);
                                        }}
                                        {...MENU_SQUARE_MAP[menuSquare]}
                                    />
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </div>
                <EventInformation event={event} />
            </div>
        </MaxWidthContainer>
    );
};

export default withStyles(styles)(EventView);
