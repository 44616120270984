import React from 'react';
// Redux
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Modules
import Mixpanel from 'mixpanel-browser';
import FormValidator from 'utils/FormValidator';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';
import Input from 'components/Input';
// styles
import styles from './styles';

class SendNotification extends React.Component {
    CHARACTER_LIMIT = 120;
    state = {
        loading: false,
        information: {
            value: '',
            valid: true,
        },
    };

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    handleSubmit = async (checkedIn, e) => {
        e.preventDefault();
        const { setSnackbar, cognitoUser } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ loading: true });
        try {
            await API.post(
                'ClutchAPI',
                `/events/${this.props.match.params.id}/notifications?OrganizationId=${cognitoUser.Organization.id}`,
                {
                    body: {
                        information: this.state.information.value,
                        checkedIn,
                    },
                }
            );
            Mixpanel.track('Event notification sent', { EventId: this.props.match.params.id });
            setSnackbar('Message sent successfully');
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.mounted && this.setState({ loading: false });
    };

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.state[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title="Send Event Notification" />
                <MaxWidthContainer padding>
                    <form className={classes.form}>
                        <div>
                            <Typography className={classes.instruction}>
                                You can send a notification to selected persons associated with this event. Just enter
                                your message below and keep it under 120 characters.
                            </Typography>
                            <Input
                                ref="information"
                                label="Enter the event notification"
                                variant="standard"
                                inputProps={{
                                    maxLength: this.CHARACTER_LIMIT,
                                }}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'information',
                                        value: e.target.value,
                                    })
                                }
                                fullWidth
                            />
                        </div>
                        <BottomButtons
                            leftButton={{
                                text: 'Message Checked In',
                                loading,
                                onClick: this.handleSubmit.bind(this, true),
                            }}
                            rightButton={{
                                text: 'Message Everyone',
                                loading,
                                type: 'submit',
                                onClick: this.handleSubmit.bind(this, false),
                            }}
                        />
                    </form>
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
export default connect(mapStateToProps, {
    setSnackbar,
})(withStyles(styles)(SendNotification));
