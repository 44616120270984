const styles = (theme) => ({
    root: {
        flex: 1,
        overflow: 'scroll',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    img: {
        borderRadius: 6,
        height: 215,
        display: 'block',
        maxWidth: '100%',
        margin: '0 auto',
    },
    imageContain: {
        objectFit: 'contain',
    },
    imageCover: {
        width: '100%',
        objectFit: 'cover',
    },
    title: {
        fontSize: 18,
        color: '#222222',
        fontWeight: 600,
        padding: '10px 0px',
    },
    video: {
        paddingBottom: 10,
    },
    btm: {
        paddingTop: 10,
    },
    file: {
        padding: '10px 0px',
    },
});

export default styles;
