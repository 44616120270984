export const SET_CONTACTS = 'SET_CONTACTS';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const ADD_CONTACTS_SUCCESS = 'ADD_CONTACTS_SUCCESS';
export const ADD_CONTACTS_ERROR = 'ADD_CONTACTS_ERROR';
export const REMOVE_CONTACTS_SUCCESS = 'REMOVE_CONTACTS_SUCCESS';
export const REMOVE_CONTACTS_ERROR = 'REMOVE_CONTACTS_ERROR';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case REMOVE_CONTACTS_SUCCESS:
        case ADD_CONTACTS_SUCCESS:
        case GET_CONTACTS_SUCCESS:
            return payload;
        case SET_CONTACTS:
            return payload;
        default:
            return state;
    }
}
