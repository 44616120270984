export const GET_ATTENDEES = 'GET_ATTENDEES';
export const GET_ATTENDEES_SUCCESS = 'GET_ATTENDEES_SUCCESS';
export const GET_ATTENDEES_ERROR = 'GET_ATTENDEES_ERROR';
export const INIT = {};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_ATTENDEES:
        case GET_ATTENDEES_SUCCESS:
            return payload;
        default:
            return state;
    }
}
