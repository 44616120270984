// React
import React from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setToast } from 'redux/actions/toast';

// Modules
import classNames from 'classnames';

// Utils
import { getAge } from 'utils/string-utils';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import {
    List as MuiList,
    ListItem,
    ListItemIcon,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Link,
} from '@material-ui/core';
import { DoneAll, Done, Clear, DeleteForever } from '@material-ui/icons';

// Dependencies
import styles from './styles';

const List = ({ className, classes, listItemData, listItemCount, listTitle, muiListItemProps, ...props }) => {
    const listClasses = classNames({
        [classes.list]: true,
        [className]: className !== undefined,
    });
    const dispatch = useDispatch();

    const renderIconStatus = (rsvp, checkedIn) => {
        if (checkedIn) return <DoneAll />;
        if (rsvp === 'yes') return <Done />;
        if (rsvp === 'no') return <Clear />;
        if (rsvp === 'invited') return '?';
    };

    const formatListItemText = (item) => {
        if (item.User.birthDate !== null) {
            let age = getAge(item.User.birthDate);
            return item.name + ', ' + age;
        } else {
            return item.name;
        }
    };

    const formatRsvp = (rsvp) => {
        if (rsvp === 'checkedIn') return 'Checked-in';
        if (rsvp === 'yes') return 'RSVP';
        if (rsvp === 'no') return 'Declined';
        if (rsvp === 'invited') return 'Not RSVP';
    };

    async function copy(guest) {
        const url = `${window.location.origin}/public/guest-rsvp/${guest.guestToken}`;
        try {
            await navigator.clipboard.writeText(url);
            dispatch(
                setToast({
                    message: 'Link copied to clipboard',
                })
            );
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className={listClasses}>
            <MuiList>
                {listTitle && (
                    <div className={classes.listTitle}>
                        {listTitle} {listItemCount && `(${listItemCount} guests)`}
                    </div>
                )}
                {listItemData.map((item, index) => {
                    return (
                        <ListItem {...muiListItemProps} key={`listitem-${index}`}>
                            {props.onDelete && (
                                <ListItemIcon>
                                    <IconButton onClick={() => props.onDelete(item)}>
                                        <DeleteForever style={{ color: '#d06767' }} />
                                    </IconButton>
                                </ListItemIcon>
                            )}
                            <ListItemText primary={formatListItemText(item)} secondary={formatRsvp(item.rsvp)} />
                            <Link className={classes.link} onClick={() => copy(item)}>
                                COPY INVITE LINK
                            </Link>
                            <ListItemSecondaryAction
                                classes={{
                                    root: classes.secondaryAction,
                                }}>
                                <ListItemAvatar>
                                    <Avatar
                                        className={classNames({
                                            [classes.red]: item.rsvp === 'no',
                                            [classes.green]: item.rsvp.checkedIn || item.rsvp === 'yes',
                                        })}>
                                        {renderIconStatus(item.rsvp, item.checkedIn)}
                                    </Avatar>
                                </ListItemAvatar>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </MuiList>
        </div>
    );
};

export default withStyles(styles)(List);
