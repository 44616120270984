export const SET_EVENT_INFO = 'SET_EVENT_INFO';
export const GET_EVENT_INFO_SUCCESS = 'GET_EVENT_INFO_SUCCESS';
export const GET_EVENT_INFO_ERROR = 'GET_EVENT_INFO_ERROR';
export const INIT = {
    event: {},
    attendee: {},
    invites: [],
    roles: [],
    isCheckInStaff: false,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_EVENT_INFO:
        case GET_EVENT_INFO_SUCCESS:
            return payload;
        default:
            return state;
    }
}
