const styles = (theme) => ({
    root: {},
    text: {
        marginBottom: 20,
        color: '#222222',
        fontSize: 16,
        fontWeight: 400,
    },
    inputLabel: {
        textDecoration: 'underline',
    },
});

export default styles;
