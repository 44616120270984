const styles = (theme) => ({
    drawer: {
        width: 291,
    },
    orgActive: {
        opacity: 0.7,
        textDecoration: 'underline',
        fontWeight: 700,
    },
    orgName: {
        color: '#222222',
        fontSize: 14,
    },
    dropdownListItem: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    heading: {
        padding: 20,
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.primary.contrastText} !important`,
    },
    headingTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: 22,
    },
    headingSubtitle: {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
    },
    list: {
        padding: '0px 10px',
    },
    listItem: {
        paddingLeft: 0,
    },
    listIcon: {
        minWidth: 45,
    },
    divider: {
        backgroundColor: 'rgba(151,151,151,0.23)',
    },
});

export default styles;
