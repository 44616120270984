const styles = (theme) => ({
    root: {
        display: 'flex',
        height: 50,
        color: 'rgba(151, 151, 151, 1)',
        border: '1px dashed rgba(151, 151, 151, 1)',
        padding: ' 9px 30px',
        borderRadius: '10px',
    },
    errorMessage: {
        width: '100%',
        display: 'inherit',
        fontSize: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#d35656',
    },
});
export default styles;
