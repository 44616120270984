import merge from 'deepmerge';

export const CLEAR_THEME = 'CLEAR_THEME';
export const SET_THEME = 'SET_THEME';

export const setTheme = (options) => (dispatch, getState) => {
    const { theme } = getState();

    dispatch({
        type: SET_THEME,
        payload: merge(theme, options),
    });
};

export const clearTheme = () => ({
    type: CLEAR_THEME,
});
