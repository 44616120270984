const styles = (theme) => ({
    header: {
        paddingBottom: 20,
        borderBottom: '1px solid #e7e7e7',
        margin: '20px 0px',
    },
    link: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '19px',
    },
    photo: {
        width: '100%',
        maxHeight: 170,
        objectFit: 'cover',
        borderRadius: 4,
        marginBottom: 10,
    },
    placeholder: {
        maxHeight: 'unset',
    },
    titles: {
        display: 'flex',
        marginBottom: 10,
    },
    titlesLeft: {
        flexGrow: 1,
        '&> h3': {
            marginBottom: 15,
        },
        '&> h6:first-of-type': {
            marginBottom: 9,
        },
    },
    titlesRight: {
        paddingLeft: 35,
        flexShrink: 1,
        textAlign: 'center',
    },

    day: {
        height: 50,
        width: 70,
        backgroundColor: '#4a4a4a',
        fontSize: 30,
        fontWeight: 700,
        color: 'white',
        borderRadius: 16,
        textAlign: 'center',
    },
    date: {
        fontSize: 12,
        fontWeight: 400,
        opacity: 0.5,
        lineHeight: '19px',
    },
    roles: {
        marginBottom: 10,
    },
    rolesText: {
        color: '#4a4a4a',
        fontSize: 16,
        fontWeight: 700,
        opacity: 0.8,
    },
});

export default styles;
