import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

// Modules
import queryString from 'query-string';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// components
import BottomButtons from 'components/BottomButtons';
import Slider from 'components/Slider';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// Constants
import DRINK_TYPES from './drinkTypes';

// styles
import styles from './styles';

const AddDrink = ({ classes, ...props }) => {
    const search = useLocation().search;
    const { type } = queryString.parse(search);
    const [abv, setAbv] = useState(DRINK_TYPES[type].abv.start);
    const [volume, setVolume] = useState(DRINK_TYPES[type].volume.start);

    function onPreview() {
        props.history.push(`/beverage-education/confirm?abv=${abv}&vol=${volume}&type=${type}`);
    }

    return (
        <>
            <PageHeader
                title="Beverage Education"
                classes={{ header: classes.pageHeader }}
                subTitle="Use sliders to match your drink"
            />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.sliders}>
                    <Slider
                        leftLabel={DRINK_TYPES[type].abv.min.label}
                        rightLabel={DRINK_TYPES[type].abv.max.label}
                        value={abv}
                        min={DRINK_TYPES[type].abv.min.value}
                        max={DRINK_TYPES[type].abv.max.value}
                        onChange={(_, value) => setAbv(value)}
                        title="Drink ABV (Alcohol by Volume)"
                        unit="%"
                    />
                    <Slider
                        leftLabel={DRINK_TYPES[type].volume.min.label}
                        rightLabel={DRINK_TYPES[type].volume.max.label}
                        value={volume}
                        min={DRINK_TYPES[type].volume.min.value}
                        max={DRINK_TYPES[type].volume.max.value}
                        onChange={(_, value) => setVolume(value)}
                        title="Drink Volume (in oz.)"
                        unit="oz"
                    />
                </div>
                <BottomButtons
                    leftButton={{
                        text: 'Cancel Drink',
                        onClick: () => props.history.goBack(),
                    }}
                    rightButton={{
                        text: 'Preview Drink',
                        onClick: onPreview,
                    }}
                />
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(AddDrink);
