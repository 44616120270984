const styles = (theme) => ({
    root: {
        marginTop: 30,
        marginBottom: 30,
    },
    inputLabel: {
        textDecoration: 'underline',
        opacity: 1.0,
        color: '#000',
    },
});

export default styles;
