import React, { useEffect, useState } from 'react';
// Redux
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import checkError from 'utils/check-error';
import { bytesToSize } from 'utils/file-types';
import clsx from 'clsx';
import Mixpanel from 'mixpanel-browser';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Skeleton from 'components/Skeleton';

// styles
import styles from './styles';

const Education = ({ classes, match }) => {
    const dispatch = useDispatch();
    const { cognitoUser } = useSelector(({ cognitoUser }) => ({ cognitoUser }));
    const [education, setEducation] = useState({});
    const [loading, setLoading] = useState(true);

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getEducation() {
            mounted && setLoading(true);
            try {
                // TODO: Change 1 to an actual value of the users organizationid
                const response = await API.get(
                    'ClutchAPI',
                    `/organizations/${cognitoUser.Organization.ParentId}/education/${match.params.id}`
                );
                mounted && setEducation(response);
                Mixpanel.track('Education viewed');
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
            mounted && setLoading(false);
        }

        _getEducation();
    }, [dispatch, match.params, cognitoUser.Organization.id]);

    return (
        <div className={classes.root}>
            <PageHeader title={education.title} loading={loading} />
            <MaxWidthContainer padding classes={{ padding: classes.container }}>
                {loading ? (
                    <Skeleton type="infoPage" />
                ) : (
                    <>
                        {education.media && (
                            <img
                                alt={'education'}
                                src={education.media.url}
                                className={clsx(classes.img, {
                                    [classes.imageCover]: education.media.height > 300 && education.media.width > 600,
                                    [classes.imageContain]: education.media.height < 299 && education.media.width < 599,
                                })}
                            />
                        )}
                        {education.videoUrl && (
                            <div className={classes.video}>
                                <ReactPlayer controls url={education.videoUrl} width="100%" />
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: education.information }} />
                        {education.file && (
                            <div className={classes.file}>
                                <a href={education.file.url}>
                                    {education.file.name} ({bytesToSize(education.file.size)})
                                </a>
                            </div>
                        )}
                    </>
                )}
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(Education);
