import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { get as getRules } from 'redux/actions/rules';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import checkError from 'utils/check-error';
import classNames from 'classnames';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// styles
import styles from './styles';

const Categories = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { rules } = useSelector(({ rules }) => ({ rules }));

    useEffect(() => {
        async function _getRules() {
            try {
                await dispatch(getRules());
            } catch (error) {
                setSnackbar(checkError(error));
            }
        }
        _getRules();
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <PageHeader title={'Rules'} />
            <MaxWidthContainer>
                <List classes={{ root: classes.list }} dense>
                    {rules.data.map((rule, index) => {
                        return (
                            <ListItem
                                key={`rule-${index}`}
                                button
                                onClick={() => props.history.push(`/rules/${rule.order}`)}
                                classes={{
                                    root: classNames(classes.listItem),
                                }}>
                                <ListItemText primary={rule.title} />
                                <ArrowForwardIos className={classes.forwardIcon} />
                            </ListItem>
                        );
                    })}
                </List>
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(Categories);
