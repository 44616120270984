import React from 'react';

// Modules
import { API } from 'aws-amplify';
import FormValidator from 'utils/FormValidator';
import checkError from 'utils/check-error';
import { cloneDeep } from 'lodash';
import Mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Input from 'components/Input';

// styles
import styles from './styles';

class CreateSafetyAlert extends React.Component {
    INIT = {
        alertText: {
            value: '',
            valid: true,
        },
    };
    state = {
        submitting: false,
        ...cloneDeep(this.INIT),
    };
    CHARACTER_LIMIT = 120;

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { setSnackbar } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField);
        if (invalidFields.length > 0) {
            let _invalidField = invalidFields.shift();
            return setSnackbar(_invalidField.message, 'error');
        }

        this.mounted && this.setState({ submitting: true });
        try {
            await API.post(
                'ClutchAPI',
                `/organizations/${this.props.cognitoUser.Organization.id}/safety-alerts?ParentId=${this.props.cognitoUser.Organization.ParentId}`,
                {
                    body: {
                        information: this.state.alertText.value,
                    },
                }
            );
            Mixpanel.track('Organization safety alert created');
            setSnackbar('Safety Alert Sent Successfully!', 'success');
            this.props.history.push('/secure/dashboard/safety-alerts');
        } catch (error) {
            setSnackbar(checkError(error, 'error'));
        }
        this.mounted && this.setState({ submitting: false });
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { alertText, submitting } = this.state;

        return (
            <div className={classes.root}>
                <PageHeader title="Send Safety Alert" />
                <MaxWidthContainer padding>
                    <form onSubmit={this.handleSubmit.bind(this)} className={classes.form}>
                        <div className={classes.main}>
                            <Input
                                label="Enter the safety alert notification"
                                placeholder="Here is a notification that can be sent out but must be under 120 characters."
                                variant="standard"
                                validator={(_) => alertText.value.length > 0 && alertText.value.length <= 120}
                                value={alertText.value}
                                InputProps={{
                                    classes: { root: classes.input },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: { root: classes.inputLabel },
                                }}
                                FormHelperTextProps={{
                                    classes: { root: classes.formHelperText },
                                }}
                                maxLength={this.CHARACTER_LIMIT}
                                onChange={(e) =>
                                    this.updateField({
                                        field: 'alertText',
                                        value: e.target.value,
                                    })
                                }
                                helperText={`${alertText.value.length}/${this.CHARACTER_LIMIT} characters left`}
                                fullWidth={true}
                                ref="alertText"
                                errorMessage="Please enter a Safety Alert"
                                error={!alertText.valid}
                                multiline
                            />
                            <Typography className={classes.note} variant="subtitle1">
                                Note: If sending a test notification please type "TEST" at the start of your message.
                            </Typography>
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            variantType="containedOuterSpace"
                            color={'primary'}
                            fullWidth={true}
                            text="Send Alert"
                            loading={submitting}
                        />
                    </form>
                </MaxWidthContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(CreateSafetyAlert));
