export const SET_CATEGORIES = 'SET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_CATEGORIES_SUCCESS:
        case SET_CATEGORIES:
            return payload;
        default:
            return state;
    }
}
