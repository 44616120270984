export default {
    location: {
        value: '',
        valid: true,
    },
    note: {
        value: '',
        valid: true,
    },
    status: {
        value: 'Not Safe',
        valid: true,
    },
};
