const styles = (theme) => ({
    root: {
        margin: 'auto',
        maxWidth: 600,
        position: 'relative',
        boxSizing: 'border-box',
        flex: 1,
        width: '100%',
    },
    padding: {
        padding: 20,
    },
});

export default styles;
