import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';

// Assets
import AlcoholSvg from 'assets/icons/Alcohol.svg';
import BeerSvg from 'assets/icons/Beer.svg';
import WineSvg from 'assets/icons/Wine.svg';

// styles
import styles from './styles';

const DrinkType = ({ classes, ...props }) => {
    const onClick = (e) => {
        props.history.push({
            pathname: '/beverage-education/add-drink',
            search: `?type=${e.target.id}`,
        });
    };

    const Item = ({ icon, title, id }) => (
        <ListItem button id={id} className={classes.listItem} onClick={onClick}>
            <ListItemIcon>
                <img src={icon} alt="beer" height="75px" />
            </ListItemIcon>
            <ListItemText className={classes.title} primary={title} />
            <ChevronRight className={classes.chevron} />
        </ListItem>
    );

    return (
        <>
            <PageHeader title="Choose Drink Type" />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <List component="nav">
                    <Item icon={BeerSvg} title="Beer, Malt Beverage" id="beer" />
                    <Divider light />
                    <Item icon={WineSvg} title="Wine" id="wine" />
                    <Divider light />
                    <Item icon={AlcoholSvg} title="Liquor, Cocktail" id="liquor" />
                </List>
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(DrinkType);
