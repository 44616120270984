export const SET_RULES = 'SET_RULES';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_ERROR = 'GET_RULES_ERROR';
export const INIT = {
    fetching: true,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_RULES_SUCCESS:
        case SET_RULES:
            return payload;
        default:
            return state;
    }
}
