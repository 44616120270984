import React, { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { get as getUpcomingEvents } from 'redux/actions/upcomingEvents';
import { get as getPastEvents } from 'redux/actions/pastEvents';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import checkError from 'utils/check-error';
import checkPolicy from 'utils/check-policy';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import EventItem from './EventItem';
import Skeleton from 'components/Skeleton';
import EventsHeader from './EventsHeader';
// Constants
import { policyIdentifiers } from 'global-constants';
// styles
import styles from './styles';

const Dashboard = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { upcomingEvents, pastEvents, cognitoUser } = useSelector(({ upcomingEvents, pastEvents, cognitoUser }) => ({
        upcomingEvents,
        pastEvents,
        cognitoUser,
    }));

    useEffect(() => {
        async function _getEvents() {
            try {
                const opt = {};
                if (
                    checkPolicy({
                        policies: cognitoUser.policies,
                        policy: policyIdentifiers.EVENT_CREATOR,
                        ParentId: cognitoUser.Organization.ParentId,
                        OrganizationId: cognitoUser.Organization.id,
                    })
                ) {
                    opt.pastEvents = true;
                }
                await Promise.all([dispatch(getUpcomingEvents(opt))]);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
            }
        }
        _getEvents();
    }, [dispatch]);

    const skeletonCount = 3;
    const isFetching = upcomingEvents.fetching || pastEvents.fetching;
    const set = new Set();
    const events = [...pastEvents.data, ...upcomingEvents.data].filter((event) => {
        if (set.has(event.id)) return false;
        set.add(event.id);
        return true;
    });

    return (
        <div className={classes.root}>
            <EventsHeader
                canCreateEvent={checkPolicy({
                    policies: cognitoUser.policies,
                    policy: policyIdentifiers.EVENT_CREATOR,
                    ParentId: cognitoUser.Organization.ParentId,
                    OrganizationId: cognitoUser.Organization.id,
                })}
                onCreateEvent={() => props.history.push('/event/type')}
            />
            {(isFetching ? Array.from(new Array(skeletonCount)) : events).map((event, index) => {
                return !event ? (
                    <Skeleton key={`skele-${index}`} type="eventItem" divider={index !== skeletonCount - 1} />
                ) : (
                    <EventItem
                        onClick={() => props.history.push(`/event-view/${event.id}`)}
                        key={event.id}
                        event={event}
                        divider={index !== events.length - 1}
                    />
                );
            })}
        </div>
    );
};

export default withStyles(styles)(Dashboard);
