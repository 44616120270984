const styles = (theme) => ({
    root: {
        flex: 1,
    },
    bottom: {
        marginTop: 197,
    },
    lightButton: {
        marginRight: 11,
        opacity: 0.5,
    },
    text: {
        marginTop: 197,
    },
    gutterBottom: {
        marginBottom: 15,
    },
});

export default styles;
