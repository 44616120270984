import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import Button from 'components/Button';

// Styles
import styles from './styles';

const SafeOrNotSafe = ({ classes, isSafe, onChange }) => {
    return (
        <div className={classes.root}>
            <Button
                classes={{ root: classes.safeButton }}
                onClick={() => onChange('Safe')}
                variant={isSafe ? 'contained' : 'outlined'}
                variantType={isSafe ? 'containedOuterSpace' : null}
                style={{
                    opacity: isSafe ? 1 : 0.5,
                }}
                text="I am safe"
                fullWidth
            />
            <Button
                onClick={() => onChange('Not Safe')}
                variant={!isSafe ? 'contained' : 'outlined'}
                variantType={!isSafe ? 'containedOuterSpace' : null}
                style={{
                    opacity: !isSafe ? 1 : 0.5,
                }}
                text="I am not safe"
                fullWidth
            />
        </div>
    );
};

export default withStyles(styles)(SafeOrNotSafe);
