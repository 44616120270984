export const SET_SAFETY_ALERTS = 'SET_SAFETY_ALERTS';
export const GET_SAFETY_ALERTS_SUCCESS = 'GET_SAFETY_ALERTS_SUCCESS';
export const GET_SAFETY_ALERTS_ERROR = 'GET_SAFETY_ALERTS_ERROR';
export const INIT = {
    fetching: false,
    data: [],
    count: 0,
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case GET_SAFETY_ALERTS_SUCCESS:
        case SET_SAFETY_ALERTS:
            return payload;
        default:
            return state;
    }
}
