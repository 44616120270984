const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    // Padding on top
    topbar: {
        height: 140,
        width: '100%',
    },

    // Events container
    recentEventsContainer: {
        alignSelf: 'stretch',
        marginTop: -110,
        position: 'relative',
    },
    eventsHeader: {
        color: theme.palette.primary.contrastText,
        fontSize: 20,
        margin: '0px 4% 32px',
    },
    eventsRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
    },

    // Menu
    menuHeader: {
        color: '#959595',
        fontSize: 20,
        margin: '24px 4%',
        textAlign: 'left',
    },
    menuRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
});

export default styles;
