import React, { useState, useEffect } from 'react';
// Modules
import { bytesToSize } from 'utils/file-types';
import clsx from 'classnames';
// Redux
import Mixpanel from 'mixpanel-browser';
import { API } from 'aws-amplify';
import { get as getRules } from 'redux/actions/rules';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';
import Skeleton from 'components/Skeleton';
import ReactPlayer from 'react-player';
// styles
import styles from './styles';

const HouseRules = ({ history, classes, match, location, ...props }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { rules } = useSelector(({ rules }) => ({ rules }));
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        async function _getRules() {
            try {
                await dispatch(getRules());
            } catch (error) {
                setSnackbar(checkError(error));
            }
        }
        _getRules();
    }, [dispatch]);

    const onAgree = async () => {
        if (currentIndex !== rules.data.length - 1) {
            return setCurrentIndex(currentIndex + 1);
        }

        setLoading(true);

        try {
            await API.patch('ClutchAPI', `/events/${match.params.id}/attendees/${location.state.attendee.id}/rsvp`, {
                body: {
                    response: 'yes',
                },
            });
            Mixpanel.track('RSVP finished');
            dispatch(setSnackbar('RSVP successfully sent'));
            history.push(`/event-view/${match.params.id}`);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        } finally {
            setLoading(false);
        }
    };

    const rule = rules.data[currentIndex];

    return (
        <div className={classes.root}>
            <PageHeader title={rule && rule.title} loading={rules.fetching} />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                {rules.fetching ? (
                    <Skeleton type="infoPage" />
                ) : !rule ? (
                    <Typography>Click I agree to continue</Typography>
                ) : (
                    <div className={classes.rule}>
                        {rule.media && (
                            <img
                                alt={'rule'}
                                src={rule.media.url}
                                className={clsx(classes.img, {
                                    [classes.imageCover]: rule.media.height > 300 && rule.media.width > 600,
                                    [classes.imageContain]: rule.media.height < 299 && rule.media.width < 599,
                                })}
                            />
                        )}
                        {rule.videoUrl && (
                            <div className={classes.video}>
                                <ReactPlayer url={rule.videoUrl} width="100%" />
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: rule.information }} />
                        {rule.file && (
                            <div className={classes.file}>
                                <a href={rule.file.url}>
                                    {rule.file.name} ({bytesToSize(rule.file.size)})
                                </a>
                            </div>
                        )}
                    </div>
                )}
                <BottomButtons
                    leftButton={{
                        text: 'Previous',
                        disabled: currentIndex === 0,
                        onClick: () => setCurrentIndex(currentIndex - 1),
                    }}
                    rightButton={{
                        text: 'I Agree',
                        loading,
                        onClick: onAgree,
                    }}
                />
            </MaxWidthContainer>
        </div>
    );
};

export default withStyles(styles)(HouseRules);
