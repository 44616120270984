const styles = (theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
    title: {
        marginBottom: 15,
        color: '#6e6e6e',
        fontSize: 18,
        fontWeight: 500,
    },
    fileSize: {
        opacity: 0.6,
        color: '#ffffff',
        fontSize: 14,
        textTransform: 'uppercase',
    },
    button: {},
    buttonContent: {
        textAlign: 'left',
        position: 'relative',
        right: 60,
    },
    helperText: {
        marginTop: 8,
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '13px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em',
        marginBottom: 12,
    },
});

export default styles;
