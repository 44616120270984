import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { AddBox } from '@material-ui/icons';
// styles
import styles from './styles';

const EventsHeader = ({ classes, canCreateEvent, ...props }) => {
    return (
        <div className={classes.header}>
            <div className={classes.title}>{'Events'}</div>

            {canCreateEvent && (
                <div className={classes.createEvent} onClick={props.onCreateEvent}>
                    <span>{'Create Event'}</span>

                    <AddBox />
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(EventsHeader);
