const styles = (theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 76,
        fontSize: 20,
        fontWeight: 400,
        letterSpacing: '0.29px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        paddingBottom: 20,
        alignSelf: 'stretch',
    },
    subHeader: {
        textDecoration: 'underline',
        opacity: 0.5,
        color: '#ffffff',
        marginTop: 9,
    },
});

export default styles;
