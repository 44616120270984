import { get } from 'lodash';
/**
 * @name mapUserEligibility - Returns Object
 * @param {Object}   eligibilityData  Data from Redux store
 * @returns {Object}
 */
export const mapUserEligibility = (cognitoResponse, eventResponse) => {
    if (!cognitoResponse || !eventResponse) {
        return {};
    }

    const isCreator = Boolean(get(cognitoResponse, 'id') === get(eventResponse, 'event.OwnerId'));
    const isCheckInStaff = Boolean(get(eventResponse, 'isCheckInStaff'));
    const isCheckinVolunteer = Boolean(!isCreator && isCheckInStaff);

    const isRsvp = Boolean(get(eventResponse, 'attendee.rsvp') === 'yes');
    const isCheckedIn = Boolean(get(eventResponse, 'attendee.checkedIn'));
    const isCheckedOut = Boolean(isCheckedIn === false);
    const isMember = Boolean(!isCheckinVolunteer && !isCreator);

    return {
        auth: {
            isCreator,
            isCheckinVolunteer,
            isCheckInStaff,
            isRsvp,
            isCheckedIn,
            isCheckedOut,
            isMember,
        },
    };
};

export const checkEligibility = (
    eligibilityData,
    requiredEligibility = [],
    excludedEligibility = [],
    overrides = []
) => {
    let arrayToCheck = requiredEligibility.concat(excludedEligibility);
    let eligible = true;

    arrayToCheck.forEach((elig) => {
        if (!get(eligibilityData, `${elig}`) && requiredEligibility.indexOf(elig) > -1) {
            eligible = false;
        } else if (get(eligibilityData, `${elig}`) && excludedEligibility.indexOf(elig) > -1) {
            eligible = false;
        }
    });

    return eligible;
};
