import React, { Component } from 'react';

// Modules
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { awsConfig } from 'aws/aws_resource';
import cloneDeep from 'lodash/cloneDeep';
import FormValidator from 'utils/FormValidator';

// Redux
import { connect } from 'react-redux';
import { get as getCognitoUser, signOut } from 'redux/actions/cognitoUser';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import AuthForm from 'components/AuthForm/AuthForm';

// Utils
import { updateLastLoginDate } from 'utils/updateLastLoginDate';

// Styles
import styles from './styles';

class SsoLogin extends Component {
    INIT = {
        email: { value: '', valid: true },
        password: { value: '', valid: true },
    };
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            rememberMeChecked: true,
            loading: false,
            ssoUsers: [],
        };
    }

    async componentDidMount() {
        this.mounted = true;
        try {
            const response = await axios.get(`${awsConfig.APIV1Endpoint}/sso`);
            this.setState({ ssoUsers: response.data });
        } catch (error) {
            console.log(error);
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleLogin(e) {
        e.preventDefault();

        const { handleError, setChallengeUser, signOut, getCognitoUser } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));
        if (invalidFields.length > 0) {
            return handleError(new Error(invalidFields[0].message));
        }

        this.mounted && this.setState({ loading: true });
        try {
            let cognitoUser = await Auth.signIn(this.state.email.value.toLowerCase(), this.state.password.value);
            if (!this.state.rememberMeChecked) {
                window.addEventListener('beforeunload', () => {
                    signOut();
                });
            }

            if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setChallengeUser(cognitoUser);
                this.props.history.replace('/new-password');
                return;
            }

            const user = await getCognitoUser();
            updateLastLoginDate(user.id);
        } catch (error) {
            if (error.code === 'PasswordResetRequiredException') {
                try {
                    await Auth.forgotPassword(this.email.value.toLowerCase());
                    this.mounted && this.setState({ loading: false });
                    this.props.history.push(`/reset-password?email=${this.email.value.toLowerCase()}`);
                } catch (forgotPasswordError) {
                    this.mounted && this.setState({ loading: false });
                    handleError(forgotPasswordError);
                }
            } else if (error.code === 'UserNotConfirmedException') {
                this.mounted && this.setState({ loading: false });
                this.props.history.push(`/confirm?email=${this.email.value.toLowerCase()}`);
            } else {
                this.mounted && this.setState({ loading: false });
                handleError(error);
            }
        }
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    async handleOauth(provider) {
        await Auth.federatedSignIn({
            provider,
        });
    }

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        // return <a href="https://clutch-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=29ko5d69d907df172jv2ph2b6e&redirect_uri=http://localhost:3000/callback&identity_provider=DeltaZeta"> Login </a>

        return (
            <div className={classes.root}>
                <div className={classes.grid}>
                    {this.state.ssoUsers.map((item, index) =>
                        item.logo ? (
                            <div key={item.name} onClick={() => this.handleOauth(item.provider)}>
                                <div className={classes.boxImage} style={{ backgroundColor: item.backgroundColor }}>
                                    <img src={item.logo} alt="" />
                                </div>
                                <div className={classes.boxFooter}>{item.name}</div>
                            </div>
                        ) : null
                    )}
                </div>

                <div className={classes.menuRow}></div>
                {/* <AuthForm onSubmit={this.handleLogin.bind(this)}></AuthForm> */}
            </div>
        );
    }
}

export default connect(null, { getCognitoUser, signOut })(withStyles(styles)(SsoLogin));
