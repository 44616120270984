import React from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Modules
import Mixpanel from 'mixpanel-browser';
import queryString from 'query-string';

// Redux
import { set } from 'redux/actions/beverageEducation';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import ProgressBar from 'components/ProgressBar';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import BottomButtons from 'components/BottomButtons';

// styles
import styles from './styles';
import calculateBACLevelGreedy from 'utils/calculateBacLevel';

const PreviewDrink = ({ classes, ...props }) => {
    const dispatch = useDispatch();
    const { abv, vol, type } = queryString.parse(useLocation().search);
    const { weight, gender, drinks, totalBac } = useSelector((state) => state.beverageEducation);

    const newDrink = {
        vol,
        abv,
        type,
        timestamp: new Date().valueOf(),
    };

    const newTotalBac = calculateBACLevelGreedy(weight, gender, drinks.concat(newDrink));

    const onYes = () => {
        Mixpanel.track('Drink added to tracker', { BAC: newTotalBac });
        dispatch(
            set({
                totalBac: newTotalBac,
                drinks: drinks.concat(newDrink),
            })
        );
        props.history.push('/beverage-education');
    };

    return (
        <>
            <PageHeader title="Beverage Education" classes={{ header: classes.pageHeader }} />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.content}>
                    <ProgressBar
                        classes={{ root: classes.progressBar }}
                        variant="buffer"
                        value={totalBac}
                        valueBuffer={newTotalBac}
                    />
                    <Typography align="center" className={classes.warning}>
                        Consuming this drink will raise your BAC to {newTotalBac.toFixed(3)}%
                    </Typography>
                    <Typography align="center" className={classes.boldText}>
                        Do you want to proceed?
                    </Typography>
                </div>
                <BottomButtons
                    leftButton={{
                        text: 'No',
                        onClick: () => props.history.goBack(),
                    }}
                    rightButton={{
                        text: 'Yes',
                        onClick: onYes,
                    }}
                />
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(PreviewDrink);
