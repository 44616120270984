import React from 'react';
// Material UI
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Check, Done, DoneAll, Close, PriorityHigh, ArrowForward, Chat } from '@material-ui/icons';
// Modules
import clsx from 'clsx';
// Custom Components
import CommentDialog from '../CommentDialog';
// styles
import styles from './styles';
import { colors } from 'global-styles';

const EventStep = ({ classes, reviews, step, onClick, event, reviewStatus, ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleShowComments = () => {
        setOpen(true);
    };

    let options = {
        secondaryText: '',
        icon: '',
        iconBackgroundColor: '',
        textOpacity: 0.5,
    };

    //  Required Approver Left Comment but did not Approve or Unapprove
    if (
        reviews.required.sectionsApproved === 0 &&
        reviews.required.sectionsUnapproved === 0 &&
        step.comments.length > 0
    ) {
        options.secondaryText = 'Items to Review, Make Changes.';
        options.icon = <PriorityHigh />;
        options.iconBG = colors.event.warning;
        options.textColor = colors.event.warning;
        options.textOpacity = 1.0;
    }
    // All Sections Approved by a Final Approver
    else if (
        reviews.final.sectionsWaitingApproval === 0 &&
        reviews.final.sectionsUnapproved === 0 &&
        (reviewStatus === 'submitted' || reviewStatus === 'update-required')
    ) {
        options.secondaryText = 'All Items Approved.';
        options.icon = <DoneAll />;
        options.iconBG = colors.event.success;
        options.textColor = colors.event.successText;
        options.textOpacity = 1.0;
    }
    // Any Sections are Marked Unapproved or Changes Needed
    else if (
        reviews.required.sectionsUnapproved >= 1 ||
        (reviews.final.sectionsUnapproved >= 1 && (reviewStatus === 'submitted' || reviewStatus === 'update-required'))
    ) {
        options.secondaryText = 'Not Approved.';
        options.icon = <Close />;
        options.iconBG = colors.event.error;
        options.textColor = colors.event.error;
        options.textOpacity = 1.0;
    }

    // All Sections Approved by a Required Approve
    else if (
        reviews.required.sectionsWaitingApproval === 0 &&
        reviews.required.sectionsApproved >= 1 &&
        reviews.required.sectionsUnapproved === 0 &&
        reviews.final.sectionsWaitingApproval !== 0 &&
        (reviewStatus === 'submitted' || reviewStatus === 'update-required')
    ) {
        options.secondaryText = 'Approved by a Required Approver.';
        options.icon = <Done />;
        options.iconBG = colors.event.success;
        options.textColor = colors.event.successText;
        options.textOpacity = 1.0;
    }

    // Section Completely Filled Out by Member
    else if (step.submitted) {
        options.secondaryText = 'Completed, Awaiting Approval.';
        options.icon = <Check />;
        options.iconBG = colors.event.darkGray;
        options.textColor = colors.event.darkGray;
    } else {
        options.secondaryText = 'Fill out the required items.';
        options.icon = <ArrowForward />;
        options.iconBG = colors.event.lightGray;
        options.textColor = colors.event.darkGray;
    }
    return (
        <>
            <div className={classes.root}>
                <div className={classes.stepTitle} onClick={onClick}>
                    <Typography className={classes.title} variant="h5">
                        {step.name}
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: options.textColor, opacity: options.textOpacity }}>
                        {options.secondaryText}
                    </Typography>
                </div>
                {reviewStatus === 'submitted' ||
                reviewStatus === 'update-required' ||
                (reviewStatus === 'editing' && step.comments.length > 0) ? (
                    <Button
                        classes={{ root: classes.commentButtonRoot }}
                        variant="contained"
                        color={'primary'}
                        disabled={step.comments.length === 0}
                        onClick={(e) => handleShowComments()}
                        disableElevation>
                        <Chat classes={{ root: classes.chatIcon }} />
                    </Button>
                ) : null}
                <div
                    className={clsx(classes.icon, {
                        [classes.iconStacked]: reviewStatus === 'submitted' || reviewStatus === 'update-required',
                    })}
                    style={{ backgroundColor: options.iconBG }}>
                    {options.icon}
                </div>
            </div>
            <CommentDialog step={step} comments={step.comments} name={step.name} setOpen={setOpen} open={open} />
        </>
    );
};

export default withStyles(styles)(EventStep);
