import React, { useState, useEffect } from 'react';

// Modules
import checkError from 'utils/check-error';
import Mixpanel from 'mixpanel-browser';
// Redux
import { setSnackbar } from 'redux/actions/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { get as getEventInfo } from 'redux/actions/eventInfo';
// Api
import { API } from 'aws-amplify';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { QRCode } from 'react-qr-svg';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
// Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';
import DotPagination from 'components/DotPagination';
import Skeleton from 'components/Skeleton';
import Button from 'components/Button';
import SwipeableViews from 'react-swipeable-views';
// styles
import styles from './styles';

const Dashboard = ({ classes, match, ...props }) => {
    const { cognitoUser, eventInfo, theme } = useSelector(({ cognitoUser, eventInfo, theme }) => ({
        cognitoUser,
        eventInfo,
        theme,
    }));
    const dispatch = useDispatch();
    const [index, setIndex] = useState(0);
    const [codes, setCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkingIn, setCheckingIn] = useState(false);

    const mounted = React.useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        async function _getCodes() {
            try {
                const response = await Promise.all([
                    dispatch(getEventInfo(match.params.id)),
                    API.get(
                        'ClutchAPI',
                        `/events/${match.params.id}/attendees?InviterId=${cognitoUser.id}&UserId=${cognitoUser.id}&OrganizationId=${cognitoUser.Organization.id}&ParentId=${cognitoUser.Organization.ParentId}`
                    ),
                ]);
                const codeData = response[1];
                mounted && setCodes(codeData.data);
            } catch (error) {
                dispatch(setSnackbar(checkError(error)));
                props.history.goBack();
            }
            mounted && setLoading(false);
        }
        _getCodes();
    }, [dispatch, match.params.id, cognitoUser.id, props.history]);

    async function handleCheckInSelf(code) {
        mounted && setCheckingIn(true);
        try {
            await API.patch('ClutchAPI', `/events/${match.params.id}/attendees/${code.qrCode}/check-in`);
            dispatch(setSnackbar(`You are now checked in`));
            Mixpanel.track('Self checked in', { EventId: match.params.id });
            props.history.push(`/event-view/${match.params.id}`);
        } catch (error) {
            dispatch(setSnackbar(checkError(error)));
        }
        mounted && setCheckingIn(false);
    }

    const attendee = codes[0];
    const rsvpedGuests = attendee?.guests.filter((guest) => guest.rsvp === 'yes');

    return (
        <>
            <PageHeader classes={{ header: classes.topbar }} />
            <MaxWidthContainer padding={false} classes={{ root: classes.root }}>
                <Typography className={classes.title} variant="h5">
                    Use the QR code and/or guest code below to check in for the event
                </Typography>
                {loading ? (
                    <Skeleton type="qrPage" classes={{ root: classes.skeleton }} />
                ) : (
                    <>
                        <SwipeableViews index={index} onChangeIndex={(index) => setIndex(index)}>
                            <div key={`${attendee.qrCode}`} className={classes.view}>
                                <div className={classes.qr}>
                                    <QRCode
                                        // DO NOT FUCKING CHANGE THESE COLORS THE QR SCANNER WILL NOT WORK FUCK OFF
                                        fgColor={'#000'}
                                        bgColor={'#fff'}
                                        level="Q"
                                        value={attendee.qrCode}
                                    />
                                </div>
                                <Typography className={classes.white} variant="h5">
                                    Guest Code: <b>{attendee.qrCode}</b>
                                </Typography>
                                {eventInfo.isCheckInStaff && attendee.UserId === cognitoUser.id ? (
                                    <Button
                                        classes={{
                                            root: classes.checkSelf,
                                            text: classes.checkSelfText,
                                        }}
                                        text={attendee.checkedIn ? 'You are checked in' : 'Check In Self'}
                                        // disabled={attendee.codeUsed}
                                        variant="outlined"
                                        variantType="outlinedWhite"
                                        endIcon={<PlaylistAddCheck className={classes.endIcon} />}
                                        onClick={() => handleCheckInSelf(attendee)}
                                        loading={checkingIn}
                                    />
                                ) : null}
                            </div>
                            {rsvpedGuests.map((guest) => (
                                <div key={`${guest.qrCode}`} className={classes.view}>
                                    <div className={classes.qr}>
                                        <QRCode fgColor={'#000'} bgColor={'#fff'} level="Q" value={guest.qrCode} />
                                    </div>
                                    <Typography className={classes.white} variant="h5">
                                        Guest Code: <b>{guest.qrCode}</b>
                                    </Typography>
                                    {eventInfo.isCheckInStaff && guest.UserId === cognitoUser.id ? (
                                        <Button
                                            classes={{
                                                root: classes.checkSelf,
                                                text: classes.checkSelfText,
                                            }}
                                            text={guest.checkedIn ? 'You are checked in' : 'Check In Self'}
                                            // disabled={guest.codeUsed}
                                            variant="outlined"
                                            variantType="outlinedWhite"
                                            endIcon={<PlaylistAddCheck className={classes.endIcon} />}
                                            onClick={() => handleCheckInSelf(guest)}
                                            loading={checkingIn}
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </SwipeableViews>
                        <div className={classes.pagination}>
                            <DotPagination
                                dots={rsvpedGuests.length + 1}
                                index={index}
                                onChangeIndex={(index) => setIndex(index)}
                            />
                        </div>
                    </>
                )}
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(Dashboard);
