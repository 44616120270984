import React, { Component } from 'react';

// Modules
import { Auth } from 'aws-amplify';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';
import FormValidator from 'utils/FormValidator';
import CustomValidators from 'utils/CustomValidators';

// Redux
import { connect } from 'react-redux';
import { get as getCognitoUser, signOut } from 'redux/actions/cognitoUser';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

// Components
import AuthHeader from 'components/AuthHeader';
import AuthForm from 'components/AuthForm/AuthForm';
import Button from 'components/Button';
import Input from 'components/Input';

// Utils
import { updateLastLoginDate } from 'utils/updateLastLoginDate';

// Styles
import styles from './styles';

class Login extends Component {
    INIT = {
        email: { value: '', valid: true },
        password: { value: '', valid: true },
    };
    constructor(props) {
        super(props);

        this.state = {
            ...cloneDeep(this.INIT),
            rememberMeChecked: true,
            loading: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    async handleLogin(e) {
        e.preventDefault();

        const { handleError, setChallengeUser, signOut, getCognitoUser } = this.props;

        const invalidFields = FormValidator(this.refs, this.updateField.bind(this));
        if (invalidFields.length > 0) {
            return handleError(new Error(invalidFields[0].message));
        }

        this.mounted && this.setState({ loading: true });
        try {
            let cognitoUser = await Auth.signIn(this.state.email.value.toLowerCase(), this.state.password.value);
            if (!this.state.rememberMeChecked) {
                window.addEventListener('beforeunload', () => {
                    signOut();
                });
            }

            if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setChallengeUser(cognitoUser);
                this.props.history.replace('/new-password');
                return;
            }

            const user = await getCognitoUser();
            updateLastLoginDate(user.id);
        } catch (error) {
            if (error.code === 'PasswordResetRequiredException') {
                try {
                    await Auth.forgotPassword(this.email.value.toLowerCase());
                    this.mounted && this.setState({ loading: false });
                    this.props.history.push(`/reset-password?email=${this.email.value.toLowerCase()}`);
                } catch (forgotPasswordError) {
                    this.mounted && this.setState({ loading: false });
                    handleError(forgotPasswordError);
                }
            } else if (error.code === 'UserNotConfirmedException') {
                this.mounted && this.setState({ loading: false });
                this.props.history.push(`/confirm?email=${this.email.value.toLowerCase()}`);
            } else {
                this.mounted && this.setState({ loading: false });
                handleError(error);
            }
        }
    }

    updateField = ({ field, value, valid = true, expectedType }) => {
        const form = this.state;
        // Check and make sure the type of field is the same as the INIT
        if (value !== null && value !== undefined) {
            if (typeof this.INIT[field].value === typeof value || expectedType === typeof value) {
                form[field].value = value;
            }
        }
        form[field].valid = valid;

        this.mounted && this.setState({ [field]: form[field] });
    };

    render() {
        const { classes } = this.props;
        const { loading } = this.state;

        return (
            <AuthForm className={classes.authForm} onSubmit={this.handleLogin.bind(this)}>
                <Input
                    id="email"
                    label="Email Address"
                    ref="email"
                    fullWidth={true}
                    marginBottom={12}
                    value={this.state.email.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'email',
                            value: e.target.value,
                        })
                    }
                    validator={() => validator.isEmail(this.state.email.value)}
                    error={!this.state.email.valid}
                    errorMessage="Please provide a valid email"
                />
                <Input
                    id="password"
                    label="Password"
                    type="password"
                    ref="password"
                    fullWidth={true}
                    value={this.state.password.value}
                    onChange={(e) =>
                        this.updateField({
                            field: 'password',
                            value: e.target.value,
                        })
                    }
                    validator={() => CustomValidators.testPassword(this.state.password.value)}
                    error={!this.state.password.valid}
                    errorMessage="Please provide a valid password"
                />
                <Grid container spacing={0} alignItems="center" justify="space-between">
                    <div className={classes.remember}>
                        <span className="label">Remember me?</span>
                        <Checkbox
                            onChange={(e) =>
                                this.setState({
                                    rememberMeChecked: e.target.checked,
                                })
                            }
                            checked={this.state.rememberMeChecked}
                            value="remember"
                            className="remember-me-checkbox"
                        />
                    </div>
                    <Button
                        classes={{ root: classes.bottomLink }}
                        text="Forgot Password"
                        onClick={() => this.props.history.push('/forgot-password')}
                    />
                </Grid>

                <input ref="formSubmit" type="submit" style={{ display: 'none' }} />

                <Button
                    classes={{ root: classes.loginButton }}
                    text={'Login'}
                    loading={loading}
                    color="primary"
                    size="large"
                    onClick={this.handleLogin.bind(this)}
                    variant="contained"
                    fullWidth
                />
                <Button
                    text={'Claim Account'}
                    disabled={loading}
                    size="large"
                    onClick={() => this.props.history.push('/claim-account')}
                    fullWidth
                />
            </AuthForm>
        );
    }
}

export default connect(null, { getCognitoUser, signOut })(withStyles(styles)(Login));
