import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';

// styles
import styles from './styles';

const MaterialTooltip = ({ classes, title, children, ...props }) => {
    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            arrow
            placement="bottom-start"
            title={<Typography>{title}</Typography>}
            {...props}>
            {children}
        </Tooltip>
    );
};

export default withStyles(styles)(MaterialTooltip);
