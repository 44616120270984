const styles = (theme) => ({
    root: {
        opacity: 0.5,
        color: '#000000',
        fontSize: 14,
        fontWeight: 400,
    },
});

export default styles;
