const styles = (theme) => ({
    root: {
        flex: 1,
    },
    inputRoot: {
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '20px 11px',
        marginLeft: 50,
    },
    input: {
        borderRadius: 6,
        border: '1px solid rgba(151, 151, 151, 0.5)',
        backgroundColor: '#ffffff',
        opacity: 0.5,
        color: '#222222',
    },
    button: {
        marginTop: 82,
    },
});

export default styles;
