import React from 'react';
import { useHistory } from 'react-router-dom';
// redux
// import { useSelector } from "react-redux";
// Modules
import dayjs from 'dayjs';
import { reviewStatusIcons, editingStatuses } from 'global-constants';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Styles
import style from './styles';

const EventItem = ({ event, divider, classes, ...props }) => {
    const history = useHistory();
    // const { theme } = useSelector(({ theme }) => ({ theme }));

    function onClick() {
        if (editingStatuses.includes(event.reviewStatus)) history.push(`/event/edit/${event.id}`);
        else history.push(`/event-view/${event.id}`);
    }

    const partyStart = dayjs(event.information.startDate);
    const partyEnd = dayjs(event.information.endDate);

    // set the event icon to be question mark if the user is not rsvp'ed
    const { Icon, styles = {} } = reviewStatusIcons[event.reviewStatus];
    let StatusIcon = <Icon style={{ fontSize: 32, color: 'white', ...styles }} />;

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                <div className={classes.iconBubble}>{StatusIcon}</div>

                {divider ? <div className={classes.divider} /> : null}
            </div>

            <div className={classes.eventContent} onClick={onClick}>
                <div className={classes.date}>{partyStart.format('MMMM DD')}</div>

                <div className={classes.bubble}>
                    <div className={classes.bubbleContent}>
                        <div className={classes.title}>{event.information.title}</div>

                        <div className={classes.time}>{`${partyStart.format('hh:mmA')} - ${partyEnd.format(
                            'hh:mmA'
                        )}`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(style)(EventItem);
