const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    listItem: {
        color: 'white',
        minHeight: 55,
    },
    title: {
        backgroundColor: '#4a4a4a',
    },
    category: {
        backgroundColor: '#5c5c5c',
        cursor: 'pointer',
    },
    education: {
        backgroundColor: '#797979',
        cursor: 'pointer',
    },
    list: {
        padding: 0,
    },
    forwardIcon: {
        marginRight: 4,
        fontSize: 14,
    },
});

export default styles;
