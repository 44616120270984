const styles = (theme) => ({
    root: {
        padding: 30,
    },
    center: {
        margin: 'auto',
    },
    margin: {
        marginBottom: 20,
    },
    halfWidth: {
        width: '50%',
    },
    threeFourthsWidth: {
        width: '75%',
    },

    pagination: {
        justifyContent: 'center',
        position: 'absolute',
        bottom: '10%',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 100,
    },
});

export default styles;
