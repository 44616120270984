export const SET_COGNITO_USER = 'SET_COGNITO_USER';
export const GET_COGNITO_USER_SUCCESS = 'GET_COGNITO_USER_SUCCESS';
export const GET_COGNITO_USER_ERROR = 'GET_COGNITO_USER_ERROR';
export const COGNITO_USER_SIGN_OUT_SUCCESS = 'COGNITO_USER_SIGN_OUT_SUCCESS';
export const COGNITO_USER_SIGN_OUT_ERROR = 'COGNITO_USER_SIGN_OUT_ERROR';
export const INIT = null;

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_COGNITO_USER:
        case GET_COGNITO_USER_SUCCESS:
        case COGNITO_USER_SIGN_OUT_SUCCESS:
            return payload;
        default:
            return state;
    }
}
