import cloneDeep from 'lodash/cloneDeep';
import { API } from 'aws-amplify';
import { INIT, SET_EVENT_TYPES, GET_EVENT_TYPES_SUCCESS, GET_EVENT_TYPES_ERROR } from 'redux/reducers/eventTypes';
import checkError from 'utils/check-error';

export const get = () => async (dispatch, getState) => {
    const { eventTypes, cognitoUser } = cloneDeep(getState());
    dispatch(set({ ...eventTypes, fetching: true }));
    try {
        const response = await API.get('ClutchAPI', `/organizations/${cognitoUser.Organization.ParentId}/event-types`);

        console.log(response);
        dispatch({
            payload: { ...response, fetching: false },
            type: GET_EVENT_TYPES_SUCCESS,
        });
    } catch (error) {
        dispatch(
            set({
                ...eventTypes,
                fetching: false,
                error: checkError(error),
            })
        );
        dispatch({
            type: GET_EVENT_TYPES_ERROR,
        });
        throw error;
    }
};

export const set = (type) => {
    if (type) {
        return {
            type: SET_EVENT_TYPES,
            payload: type,
        };
    }
    // Empty type in store if no type is sent
    return {
        type: SET_EVENT_TYPES,
        payload: cloneDeep(INIT),
    };
};
