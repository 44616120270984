const styles = (theme) => ({
    root: { flex: 1 },

    container: {
        padding: 20,
        maxWidth: 600,
        margin: 'auto',
    },
});

export default styles;
