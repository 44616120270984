import React from 'react';
// Modules
import checkError from 'utils/check-error';
import StepReviewState from './step-state';

// redux
import { connect } from 'react-redux';
import { get as getEvent } from 'redux/actions/event';
import { setSnackbar } from 'redux/actions/snackbar';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// components
import StepListItem from './StepListItem';
import EventStepper from 'components/EventStepper';
import Skeleton from 'components/Skeleton';
// styles
import styles from './styles';

class EventHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            submitting: false,
            stepReviewState: [],
            event: {
                information: {
                    title: '',
                },
            },
        };
    }

    componentDidMount() {
        this._getEvent();
    }

    async _getEvent() {
        const { setSnackbar, getEvent, match } = this.props;

        // if (parseInt(event.id) === parseInt(match.params.id)) return;

        this.setState({ loading: true });
        try {
            const event = await getEvent(match.params.id);
            const stepReviewState = StepReviewState(event);
            this.setState({ stepReviewState, event });
        } catch (error) {
            setSnackbar(checkError(error));
        }
        this.setState({ loading: false });
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { classes, match } = this.props;
        const { loading, event } = this.state;

        const nextStep = event.EventModules && event.step === event.EventModules.length ? 'submit' : event.step;

        return (
            <div className={classes.root}>
                <EventStepper
                    progress={event.EventModules ? (event.step / event.EventModules.length) * 100 : null}
                    leftButton={false}
                    rightButton={
                        loading
                            ? false
                            : {
                                  text: 'Continue',
                                  onClick: () =>
                                      this.props.history.push(`/event/edit/${match.params.id}/step/${nextStep}`),
                              }
                    }
                />
                <div className={classes.container}>
                    <div className={classes.title}>
                        <Typography variant="h3">{event.information && event.information.title}</Typography>
                    </div>
                    {loading ? (
                        <Skeleton type="list" items={6} />
                    ) : (
                        event.EventModules &&
                        this.state.stepReviewState.map((mod, index) => {
                            return (
                                <StepListItem
                                    event={event}
                                    reviewStatus={event.reviewStatus}
                                    key={`mod-${index}`}
                                    step={mod}
                                    reviews={mod.reviews}
                                    onClick={() =>
                                        this.props.history.push(`/event/edit/${match.params.id}/step/${mod.stepNumber}`)
                                    }
                                    classes={{ root: classes.module }}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ cognitoUser }) => ({ cognitoUser });
export default connect(mapStateToProps, { setSnackbar, getEvent })(withStyles(styles)(EventHome));
