import React from 'react';

// Modules
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './redux/config';
import * as serviceWorker from './serviceWorker';
import MomentUtils from '@date-io/moment';

// Material UI
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Mixpanel
import Mixpanel from 'mixpanel-browser';
import { mixPanelConfig } from './mixpanel/mixpanel_resource';

// AWS
import Amplify from 'aws-amplify';
import { awsConfig } from './aws/aws_resource';

// CSS Imports
import './index.css';

Amplify.configure(awsConfig);
Mixpanel.init(mixPanelConfig.MemberToken);

const mapStateToProps = ({ theme }) => ({ theme });

const ConnectedApp = connect(mapStateToProps)(({ theme, ...props }) => {
    const muiTheme = createTheme(theme);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
});

const App = (props) => (
    <Provider store={store}>
        <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
            <ConnectedApp />
        </PersistGate>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
