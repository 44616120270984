const styles = (theme) => ({
    root: {},
    startIcon: {
        position: 'absolute',
        left: 20,
    },
    outlinedWhite: {
        ...theme.buttons.outlined.white,
    },
    outlinedOuterSpace: {
        ...theme.buttons.outlined.outerSpace,
    },
    outlinedOutlinedGrey: {
        ...theme.buttons.outlined.grey,
    },
    contained: {
        ...theme.buttons.contained,
    },
    containedOuterSpace: {
        ...theme.buttons.contained.outerSpace,
    },
    containedWarning: {
        ...theme.buttons.contained.warning,
    },

    loadingContained: {
        '& svg.MuiCircularProgress-svg': {
            color: '#fff',
        },
    },
    text: {},
});

export default styles;
