const styles = (theme) => ({
    root: {
        opacity: 0.32,
        margin: '20px 0 ',
        height: 1,
        backgroundColor: '#979797',
    },
});

export default styles;
