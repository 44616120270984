import React from 'react';
import { useSelector } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import ProgressBar from 'components/ProgressBar';
import PageHeader from 'components/PageHeader';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Button from 'components/Button';

// Local
import recommendations from './recommendations.json';

// styles
import styles from './styles';

const PostAcceptedHome = ({ classes, ...props }) => {
    const { totalBac } = useSelector((state) => state.beverageEducation);
    let information =
        "At this point you will begin to feel relaxed as your body is starting it's response to the alcohol.";
    let recommendation =
        'Your night is just starting so have fun but keep tracking yourself using this tracker. Please note you can use this to plan your next drink or use it to estimate your level of current consumption.';

    recommendations.forEach((entry) => {
        if (totalBac >= entry.threshold) {
            information = entry.information;
            recommendation = entry.recommendation;
        }
    });

    return (
        <>
            <PageHeader title="Beverage Education" classes={{ header: classes.pageHeader }} />
            <MaxWidthContainer classes={{ root: classes.container }} padding>
                <div className={classes.content}>
                    <ProgressBar classes={{ root: classes.progressBar }} variant="determinate" value={totalBac} />
                    <Typography className={classes.description}>{information}</Typography>
                    <Typography gutterBottom className={classes.boldText}>
                        Recommendations:
                    </Typography>
                    <Typography className={classes.recommendations}>{recommendation}</Typography>
                </div>
                <Button
                    variantType="containedOuterSpace"
                    color="primary"
                    type="submit"
                    fullWidth={true}
                    text="Add New Drink"
                    onClick={() => props.history.push('/beverage-education/drink-type')}
                />
            </MaxWidthContainer>
        </>
    );
};

export default withStyles(styles)(PostAcceptedHome);
