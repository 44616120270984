import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
// Modules
import { API } from 'aws-amplify';
import checkError from 'utils/check-error';
import Upload from 'utils/Upload';
// Mui-Core
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
// Mui-Icons
import { ArrowForwardIos } from '@material-ui/icons';
// Custom Components
import MaxWidthContainer from 'components/MaxWidthContainer';
import PageHeader from 'components/PageHeader';

// Styles
import styles from './styles';

class VolunteerSections extends Component {
    constructor(props) {
        super(props);
        this.eventId = props.match.params.id;
        this.state = {
            loading: false,
            modules: [],
        };
    }

    componentDidMount() {
        this._getEventInfo();
    }
    async _getEventInfo() {
        const { OrganizationId, ParentId } = this.props;
        this.setState({ loading: true });
        try {
            const response = await API.get(
                'ClutchAPI',
                `/events/${this.eventId}/modules/?markupType=volunteer&OrganizationId=${OrganizationId}&ParentId=${ParentId}`
            );

            this.setState({
                loading: false,
                modules: response,
            });
        } catch (error) {
            setSnackbar(checkError(error));
        }
    }

    render() {
        const { classes, history, location } = this.props;
        return (
            !this.state.loading && (
                <div className={classes.root}>
                    <PageHeader title={'Manage Volunteers'} />

                    <MaxWidthContainer>
                        <List dense className={classes.list}>
                            {this.state.modules.map((mod, index) => (
                                <ListItem
                                    key={`module-${index}`}
                                    button
                                    className={classes.listItem}
                                    onClick={(e) => history.push(`${location.pathname}/step/${mod.id}`)}>
                                    <ListItemText primary={mod.name} classes={{ primary: classes.title }} />
                                    <ListItemSecondaryAction>
                                        <ArrowForwardIos className={classes.icon} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </MaxWidthContainer>
                </div>
            )
        );
    }
}
const mapStateToProps = ({ cognitoUser, OrganizationId, ParentId }) => ({
    cognitoUser,
    OrganizationId: cognitoUser.Organization.id,
    ParentId: cognitoUser.Organization.ParentId,
});
export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(VolunteerSections));
