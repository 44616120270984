const styles = (theme) => ({
    root: {},
    inputLabel: {
        textDecoration: 'underline',
        opacity: 1.0,
        color: '#000',
    },
    title: {
        fontSize: 18,
        textAlign: 'center',
    },
});

export default styles;
