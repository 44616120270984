import React from 'react';
import PropTypes from 'prop-types';

// Components
import { withStyles } from '@material-ui/core/styles';
import PaginationDot from './PaginationDot';
import { Typography } from '@material-ui/core';

import styles from './styles';

class Pagination extends React.Component {
    handleClick = (event, index) => {
        this.props.onChangeIndex(index);
    };

    render() {
        const { classes, index, dots } = this.props;

        const children = [];

        for (let i = 0; i < dots; i += 1) {
            children.push(<PaginationDot key={i} index={i} active={i === index} onClick={this.handleClick} />);
        }

        return (
            <div className={classes.root}>
                <Typography variant="h5" className={classes.title}>
                    {index + 1} of {dots}
                </Typography>
                {children}
            </div>
        );
    }
}

Pagination.propTypes = {
    dots: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onChangeIndex: PropTypes.func.isRequired,
};

export default withStyles(styles)(Pagination);
