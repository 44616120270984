import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
// Components
import Input from 'components/Input';
// Styles
import styles from './styles';

class ShortTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.inputType || 'text';
    }

    onChange(value) {
        if (typeof this.props.onChange === 'function') this.props.onChange(value);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Input
                    id={this.props.id}
                    label={this.props.title}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    required={this.props.required}
                    type={this.type}
                    variant="standard"
                    fullWidth
                    onChange={(e) => this.onChange(e.target.value)}
                    InputProps={{
                        classes: { root: classes.input },
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.inputLabel },
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(ShortTextInput);
