const styles = (theme) => ({
    rule: {
        paddingTop: 20,
        paddingBottom: 50,
        borderBottom: '1px solid rgba(151,151,151,0.23)',
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        color: '#4a4a4a',
        marginBottom: 20,
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    img: {
        borderRadius: 6,
        height: 215,
        display: 'block',
        maxWidth: '100%',
        margin: '0 auto',
    },
    imageContain: {
        objectFit: 'contain',
    },
    imageCover: {
        width: '100%',
        objectFit: 'cover',
    },
    video: {
        paddingBottom: 10,
    },
    btm: {
        paddingTop: 10,
    },
    file: {
        padding: '10px 0px',
    },
});

export default styles;
