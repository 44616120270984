const styles = (theme) => ({
    root: { flex: 1 },

    container: {
        padding: 20,
        maxWidth: 600,
        margin: 'auto',
    },
    module: {
        marginBottom: 10,
    },
    title: {
        marginBottom: 12,
        textAlign: 'center',
    },
});

export default styles;
