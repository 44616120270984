import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const SkeletonList = ({ classes, items, ...props }) => {
    return (
        <List>
            {Array.from(new Array(items)).map((item, index) => {
                return (
                    <ListItem classes={{ root: classes.root }} key={`skele-list-${index}`}>
                        <ListItemText primary={<Skeleton variant="rect" />} secondary={<Skeleton variant="rect" />} />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default withStyles(styles)(SkeletonList);
